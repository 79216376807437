import {
  createTheme,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { AUTOMAT_SEARCH_TAB_KEY, useTabs } from "global/hook/tabs/use-tabs";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./automatensuche.scss";
import DepositMachineList from "./components/DepositMachineList/DepositMachineList";
import AccordionTheme from "./components/FilterSystem/theme/FilterTheme";

const Automatensuche: React.FC = () => {
  const [tabs, activeTabKey, tabController] = useTabs();

  return (
    <ThemeProvider theme={(theme) => createTheme(AccordionTheme, theme)}>
      <Stack rowGap={1.5}>
        <Tabs className="suche-tabs" value={activeTabKey} selectionFollowsFocus>
          <Tab
            onClick={() => {
              tabController.switchTo(AUTOMAT_SEARCH_TAB_KEY);
            }}
            value={AUTOMAT_SEARCH_TAB_KEY}
            label={<Typography>Automatensuche</Typography>}
            className="static-tab-element"
          />

          {tabs.map((tabInfo, index) => tabInfo.tab)}
        </Tabs>
        <Routes>
          <Route
            path="*"
            element={
              <DepositMachineList
                onCellClicked={(row) => {
                  tabController.open(row.automatSerienNr, row.automatkey);
                }}
              />
            }
          />
          {tabs.map((tabInfo) => tabInfo.route)}
        </Routes>
      </Stack>
    </ThemeProvider>
  );
};

export default Automatensuche;
