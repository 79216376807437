import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { pageStyles } from "../PdfSteckbriefContent";

const styles = StyleSheet.create({
  column: {
    width: "13%",
    fontSize: 6,
    textAlign: "center",
    paddingTop: 1,
    paddingBottom: 1,
  },
  columnSubtitle: {
    fontSize: 5,
    textAlign: "center",
    paddingTop: 1,
    paddingBottom: 1,
  },
  columnWidth1: {
    width: "34%",
  },
  columnWidth3: {
    width: "66%",
  },
  cellContent: {
    paddingTop: 1,
    paddingBottom: 1,
  },
});

interface props {
  name: string;
  data: Array<string>;
}

const PdfRepairHistorySection = (props: props) => {
  return (
    <View style={[pageStyles.tableCellBorderLeft, styles.column]}>
      <Text style={[pageStyles.itemTextBold]}>{props.name}</Text>
      <View style={[pageStyles.tableRow, pageStyles.tableCellBorderTop]}>
        <Text
          style={[
            pageStyles.tableCellBorderRight,
            styles.columnSubtitle,
            styles.columnWidth1,
          ]}
        >
          Kosten(€)
        </Text>
        <Text style={[styles.columnSubtitle, styles.columnWidth3]}>
          Zeitaufwand(Std.)
        </Text>
      </View>
      <View style={[pageStyles.tableRow, pageStyles.tableCellBorderTop]}>
        <Text
          style={[
            pageStyles.tableCellBorderRight,
            styles.columnWidth1,
            styles.cellContent,
          ]}
        >
          {props.data[0] ?? ""}
        </Text>
        <Text style={[styles.columnWidth3, styles.cellContent]}>
          {props.data[1] ?? ""}
        </Text>
      </View>
      <View style={[pageStyles.tableRow, pageStyles.tableCellBorderTop]}>
        <Text
          style={[
            pageStyles.tableCellBorderRight,
            styles.columnWidth1,
            styles.cellContent,
          ]}
        >
          {props.data[2] ?? ""}
        </Text>
        <Text style={[styles.columnWidth3, styles.cellContent]}>
          {props.data[3] ?? ""}
        </Text>
      </View>
    </View>
  );
};

export default PdfRepairHistorySection;
