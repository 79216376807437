import { Typography } from "@mui/material";

import DataGrid from "global/components/UI/DataGrid/DataGrid";
import JobMonitoringController from "../JobController";
import JobMonitoringColumnDefs from "../grid/grid-column-defs";
import GRID_OPTIONS from "../grid/grid-options";
import { JobMonitoringDetails } from "./JobDetailsDialogContent";

const JobMonitoringDashboard = (props: {
  gridHeight: number;
  rowClickHandler: (entry: JobMonitoringDetails) => void;
}) => {
  return (
    <DataGrid
      gridActions={
        <Typography variant={"h1"}>Job Monitoring - Aktuelle Daten</Typography>
      }
      gridOptions={GRID_OPTIONS}
      height={props.gridHeight}
      columnDefs={JobMonitoringColumnDefs}
      rowsDataDef={{
        uncontrolledDataFetchCall: JobMonitoringController.loadDashboardData,
      }}
      onRowClicked={props.rowClickHandler}
    />
  );
};

export default JobMonitoringDashboard;
