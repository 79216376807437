import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import { EpcomGroup } from "service/data-service/user-controller/interface/EpcomUserGroupAutomat";
import UserService from "service/data-service/user-controller/User.service";
import {
  EnumeratorItemSourceType,
  EnumItemBuilder,
  EnumItemsExtractFn,
} from "../use-enumerator";

const epcomGroupBuilder: EnumItemBuilder = (raw: EnumeratorItemSourceType) => {
  const rawItem = raw as EpcomGroup;
  return {
    id: rawItem.key,
    value: rawItem.nameForGui,
    source: raw,
  } as EnumeratorItem;
};

export const onEpcomGroupsResult: EnumItemsExtractFn = (
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  UserService.getAllVisibleGroups(
    (groups: Array<EpcomGroup>) => onEnumSyncSuccess(groups, epcomGroupBuilder),
    (err: Error) =>
      onEnumSyncError("Syncing Visible and Current Epcom Groups failed", err)
  );
};
