import HttpService from "App/service/http.service";
import Config from "Config";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import ErreichbarkeitDTO from "./dto/ErreichbarkeitDTO";

const ErreichbarkeitService = {
  getReport: (handler: ServiceResponseHandler) => {
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}reporting/erreichbarkeitsreport`)
      .then((response) => {
        handler.callbackOnSuccess(
          ErreichbarkeitDTO.convertErreichbarkeitResponse(response)
        );
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error);
      });
  },
};
export default ErreichbarkeitService;
