import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  header: {
    fontFamily: "Helvetica-Bold",
    margin: "0 0 0 0",
  },
  title: {
    marginBottom: 5,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 900,
  },
  subtitle: {
    paddingTop: 3,
    textAlign: "right",
    fontSize: 8,
    fontWeight: 900,
  },
});

interface props {
  seriennummer: string;
  date: string;
  username: string;
  email: string;
}

const PdfHeaderItem = (props: props) => {
  return (
    <View style={styles.header} fixed>
      <Text style={styles.title}>
        EPCOM Steckbrief für Leergutautomat mit Seriennummer{" "}
        {props.seriennummer}
      </Text>
      <Text style={styles.subtitle}>
        erzeugt durch {props.username}/{props.email}
      </Text>
      <Text style={styles.subtitle}>mit Datenstand von: {props.date}</Text>
    </View>
  );
};

export default PdfHeaderItem;
