import { EpcomResponse } from "App/service/http.service";
import PingResult from "../interface/PingResult";
import TraceRouteResult from "../interface/TraceRouteResult";

const AutomatDTO = {
  convertPingResponse: (response: EpcomResponse): PingResult => {
    if (response.data) {
      return {
        automatKey: response.data.automatKey,
        color: response.data.color,
        rawResult: response.data.rawResult,
        resultText: response.data.resultText,
      };
    }
    return {} as PingResult;
  },
  convertTraceResponse: (response: EpcomResponse): TraceRouteResult => {
    if (response.data) {
      return {
        color: response.data.color,
        rawResult: response.data.rawResult,
        resultText: response.data.resultText,
      };
    }
    return {} as TraceRouteResult;
  },
};
export default AutomatDTO;
