import { ColDef } from "ag-grid-community";

const WGRZuordnungColumnDefs: Array<ColDef> = [
  {
    headerName: "Anzahl",
    field: "anzahlPositionen",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    headerName: "Kurztext",
    field: "kurzText",
    minWidth: 330,
  },
  {
    headerName: "Unter-Warengruppe Name",
    field: "unterWarengruppeName",
    minWidth: 360,
  },
  {
    headerName: "ATH",
    field: "rechnungsstellerKuerzel",
    minWidth: 80,
    maxWidth: 80,
  },
];

export const WGRZuordnungFilterColumnDefs: Array<ColDef> = [
  {
    headerName: "Anzahl",
    field: "anzahlPositionen",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    headerName: "Kurztext",
    field: "kurzText",
    minWidth: 300,
  },
  {
    headerName: "Unter-Warengruppe Name",
    field: "unterWarengruppeName",
    minWidth: 320,
  },
  {
    headerName: "ATH",
    field: "rechnungsstellerKuerzel",
    minWidth: 80,
    maxWidth: 80,
  },
];

export const WGRZuordnungOhneWGRColumnDefs: Array<ColDef> = [
  {
    headerName: "Anzahl",
    field: "anzahlPositionen",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    headerName: "Kurztext",
    field: "kurzText",
    minWidth: 330,
  },
  {
    headerName: "ATH",
    field: "rechnungsstellerKuerzel",
    minWidth: 80,
    maxWidth: 80,
  },
];

export default WGRZuordnungColumnDefs;
