import { EpcomResponse } from "App/service/http.service";
import Anwendernachricht from "../interface/Anwendernachricht";

const AnwendernachrichtDTO = {
  convertAnwendernachrichtSearchResponse: (
    response: EpcomResponse
  ): Array<Anwendernachricht> => {
    if (response.data) {
      return [...response.data];
    }
    return {} as Array<Anwendernachricht>;
  },
  convertAnwendernachrichtResponse: (
    response: EpcomResponse
  ): Anwendernachricht => {
    if (response.data) {
      return { ...response.data };
    }
    return {} as Anwendernachricht;
  },
};
export default AnwendernachrichtDTO;
