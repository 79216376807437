import { EpcomResponse } from "App/service/http.service";
import { Erreichbarkeit } from "../interface/Erreichbarkeit";

const ErreichbarkeitDTO = {
  convertErreichbarkeitResponse: (
    response: EpcomResponse
  ): Array<Erreichbarkeit> => {
    if (response.data) {
      return response.data.Row;
    }
    return [];
  },
};
export default ErreichbarkeitDTO;
