import HttpService, { EpcomResponse } from "App/service/http.service";
import Config from "Config";
import SapDTO from "./dto/SapDTO";
import SapInputParam from "./interface/SapInputParam";

const basePath = "saprd/";

const PositionService = {
  getPositionenForUnterwgrs: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "getPositionenForUnterwgrs",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getPositionenByUser: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "getPositionenByUser",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getPositionenByUserUnallocated: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "getPositionenByUserUnallocated",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getSerienNrForRns: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "getSerienNrForRns",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  savePositionSerienNr: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "savePositionSerienNr",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getRnsDataForGln: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "getrnsdataforgln",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getRnsDataEpcomByPlz: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "getrnsdataepcombyplz",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  savePositionRnsGln: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "savePositionRnsGln",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getPositionByKurzText: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "getPositionByKurzText",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getPositionWithWgrForEdit: (
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURLNoFilter(
      "getPositionWithWgrForEdit",
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getPositionWithoutWgrForEdit: (
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURLNoFilter(
      "getPositionWithoutWgrForEdit",
      callbackOnSuccess,
      callbackOnFail
    );
  },
  getPositionWithoutEpcomRegion: (
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURLNoFilter(
      "getPositionsWithoutEpcomRegion",
      callbackOnSuccess,
      callbackOnFail
    );
  },
  saveRnToRegion: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    GetPositionenByURL(
      "saveRnToRegion",
      filter,
      callbackOnSuccess,
      callbackOnFail
    );
  },
};

const GetPositionenByURL = (
  url: string,
  filter: SapInputParam,
  callbackOnSuccess: Function,
  callbackOnFail?: Function
) => {
  HttpService.api
    .sendJsonDataPost(`${Config.sapServiceUrl}${basePath}${url}`, filter)
    .then((response: EpcomResponse) => {
      callbackOnSuccess(SapDTO.convertSapOutputResponseDTO(response));
    })
    .catch((error) => {
      if (callbackOnFail) callbackOnFail(error);
    });
};

const GetPositionenByURLNoFilter = (
  url: string,
  callbackOnSuccess: Function,
  callbackOnFail?: Function
) => {
  HttpService.api
    .sendGet(`${Config.sapServiceUrl}${basePath}${url}`)
    .then((response: EpcomResponse) => {
      callbackOnSuccess(SapDTO.convertSapOutputResponseDTO(response));
    })
    .catch((error) => {
      if (callbackOnFail) callbackOnFail(error);
    });
};

export default PositionService;
