import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";

const REGION_HR: EnumeratorItem = {
  id: "HR",
  source: { email: "hr-leergut@edeka.de" },
  value: "HR [hr-leergut@edeka.de]",
};
const REGION_MH: EnumeratorItem = {
  id: "MH",
  source: { email: "leergut@minden.edeka.de" },
  value: "MH [leergut@minden.edeka.de]",
};
const REGION_NB: EnumeratorItem = {
  id: "NB",
  source: { email: "wue-leergut@edeka.de" },
  value: "NB [wue-leergut@edeka.de]",
};
const REGION_NO: EnumeratorItem = {
  id: "NO",
  source: { email: "NMS_MAIL_Automatenstammdaten@edeka.de" },
  value: "NO [NMS_MAIL_Automatenstammdaten@edeka.de]",
};
const REGION_RR: EnumeratorItem = {
  id: "RR",
  source: { email: "rr-leergut@edeka.de" },
  value: "RR [rr-leergut@edeka.de]",
};
const REGION_RRST: EnumeratorItem = {
  id: "RRST",
  source: { email: "leergut-gh@stroetmann.de" },
  value: "RRST [leergut-gh@stroetmann.de]",
};
const REGION_SB: EnumeratorItem = {
  id: "SB",
  source: { email: "sb-leergut@edeka.de" },
  value: "SB [sb-leergut@edeka.de]",
};
const REGION_SW: EnumeratorItem = {
  id: "SW",
  source: { email: "sw-leergut@edeka.de" },
  value: "SW [sw-leergut@edeka.de]",
};

const RechnungnehmerRegionEnumerator = {
  items: [
    REGION_HR,
    REGION_MH,
    REGION_NB,
    REGION_NO,
    REGION_RR,
    REGION_RRST,
    REGION_SB,
    REGION_SW,
  ],
} as Enumerator;

export default RechnungnehmerRegionEnumerator;
