import { Stack } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import {
  enumeratorItemForId,
  enumeratorItemForValue,
} from "global/components/EnumeratedValues/Enumerator";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UICheckbox from "global/components/UI/UICheckbox/UICheckbox";
import {
  TRACK_CHANGE,
  directPathMerger,
} from "global/hook/datastore/automat/change-tracker-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID,
  AUTOMATENBLATT_STANDORT_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import DateUtils from "global/util/DateUtils";
import { useContext } from "react";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import MaterialDistributionTable from "../../MaterialDistribution/MaterialDistributionTable";
import "../panels.scss";

interface DetailsPanelProps extends PanelBaseProps {
  automatKey: string;
}

const DetailsLeftItem = (props: DetailsPanelProps) => {
  const [automatData, dispatchAutomatData, dataWithChanges] = useAutomatData(
    props.automatKey
  );

  const accessContext = useContext(AccessContext);
  const access = accessContext.getAccessContainer();

  const standortEnumerator = useEnumerator(AUTOMATENBLATT_STANDORT_ENUM_ID);
  const sortierKonfigEnumerator = useEnumerator(
    AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID
  );

  const standortSelection = () => {
    if (
      automatData.automatenblattInformation.standort ===
      dataWithChanges.standort
    ) {
      return enumeratorItemForValue(
        standortEnumerator,
        dataWithChanges.standort
      );
    }
    return enumeratorItemForId(standortEnumerator, dataWithChanges.standort);
  };

  const sammlungAufTischValue = () => {
    if (typeof dataWithChanges.sammlungAufTisch === "string") {
      return dataWithChanges.sammlungAufTisch === "true";
    }
    return dataWithChanges.sammlungAufTisch;
  };

  const garantieEndeDateForDisplay = () => {
    const aDate = dataWithChanges.garantieEnde;

    if (!aDate) return aDate;

    if (typeof aDate === "string") {
      return DateUtils.arrayToDate(DateUtils.apiStringToNumberArray(aDate));
    }
    return aDate;
  };

  const registerUserChange = (path: string, value: string) => {
    let refValue: string = "";
    switch (path) {
      case "standort":
        refValue = enumeratorItemForValue(
          standortEnumerator,
          automatData.automatenblattInformation.standort
        )?.id as string;
        break;
      case "standortHinweis":
        refValue = automatData.automatenblattInformation.standortHinweis ?? "";
        break;
      case "sammlungAufTisch":
        refValue = `${automatData.automatenblattInformation.sammlungAufTisch}`;
        break;
      case "sortierKonf":
        refValue = automatData.automatenblattInformation.sortierKonf;
        break;
      case "garantieEnde":
        refValue =
          DateUtils.formatDateToAPIDateString(
            automatData.automatenblattInformation.garantieEnde
          ) ?? "";
        break;
      default:
        return;
    }
    dispatchAutomatData(TRACK_CHANGE, {
      change: {
        path: path,
        refValue: refValue,
        value: value,
        merger: directPathMerger,
      },
    } as AutomatActionPayload);
  };

  return (
    <Stack spacing={0.5}>
      <SelectBox
        label="Standort:"
        enumerator={standortEnumerator}
        selection={standortSelection()}
        id="standort"
        getSelectedValue={(selection) =>
          registerUserChange("standort", selection?.id as string)
        }
        reset={props.resetValues}
      />
      <TextValue
        label="Hinweis zum Standort:"
        value={dataWithChanges.standortHinweis ?? ""}
        id="standort-hinweis"
        onChange={(ev) =>
          registerUserChange("standortHinweis", ev.target.value ?? "")
        }
      />
      <TextValue
        label="Art der Zählstelle:"
        value={dataWithChanges.zaehlstelle}
        id="zaehlstelle"
        readOnly
      />
      <TextValue
        label="Nutzungsart:"
        value={dataWithChanges.nutzungsart}
        id="nutzungsart"
        readOnly
      />
      <UICheckbox
        label="Sammlung auf Tisch:"
        value={sammlungAufTischValue()}
        id="sammlung-auf-tisch"
        onChange={(event) =>
          registerUserChange("sammlungAufTisch", `${event.target.checked}`)
        }
      />
      <SelectBox
        label="Sortier Konfiguration:"
        enumerator={sortierKonfigEnumerator}
        selection={enumeratorItemForId(
          sortierKonfigEnumerator,
          dataWithChanges.sortierKonf
        )}
        id="sortier-konfiguration"
        getSelectedValue={(value) =>
          registerUserChange("sortierKonf", value?.id as string)
        }
        reset={props.resetValues}
      />
      <TextValue
        label="Bulk:"
        value={dataWithChanges.modellMerkmale.bulkTypeBeschreibung}
        id="modell-merkmal-bulk-beschreibung"
        readOnly
      />
      <TextValue
        label="Erkennung:"
        value={dataWithChanges.modellMerkmale.erkennungTechnikTypeBeschreibung}
        id="modell-merkmal-erkennung-technik-beschreibung"
        readOnly
      />
      <TextValue
        label="Komp.im Automatenkopf:"
        value={
          dataWithChanges.modellMerkmale.kompaktorImAutomatkopfTypeBeschreibung
        }
        id="modell-merkmal-kompaktor-im-automatkopf-beschreibung"
        readOnly
      />
      <TextValue
        label="Kistenannahme:"
        value={dataWithChanges.modellMerkmale.kistenannahmeTypeBeschreibung}
        id="modell-merkmal-kistenannahme-type-beschreibung"
        readOnly
      />
      <TextValue
        label="Sammlung auf Tisch:"
        value={dataWithChanges.modellMerkmale.sammlungAufTischTypeBeschreibung}
        id="modell-merkmal-sammlung-auf-tisch-beschreibung"
        readOnly
      />
      <TextValue
        label="Mehrweg:"
        value={dataWithChanges.modellMerkmale.mehrwegTypeBeschreibung}
        id="modell-merkmal-mehrweg-beschreibung"
        readOnly
      />
      <TextValue
        label="Einwurf mehrere Gebinde gleichzeitig:"
        value={dataWithChanges.modellMerkmale.throwInSeveralTypeBeschreibung}
        id="modell-merkmal-throw-in-several-beschreibung"
        readOnly
      />
      <TextValue
        label="Altersklasse:"
        value={dataWithChanges.altersklasse}
        id="altersklasse"
        readOnly
      />
      <DatePickerElement
        label="Ende Garantie:"
        defaultValue={garantieEndeDateForDisplay()}
        getSelectedValue={(value) =>
          registerUserChange(
            "garantieEnde",
            DateUtils.formatDateToAPIDateString(value) ?? ""
          )
        }
        id="ende-garantie"
        resetToDefault={props.resetValues}
      />
      {access.rollenTyp !== "ATH" && (
        <MaterialDistributionTable automatData={automatData} />
      )}
    </Stack>
  );
};

export default DetailsLeftItem;
