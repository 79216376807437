import MaintenanceController from "global/hook/maintenance/interface/MaintenanceController";
import AccessService from "service/data-service/access-controller/Access.service";
import Access, {
  MaintenanceInfo,
} from "service/data-service/access-controller/interface/Access";

const SESSIONSTORAGE_KEY = "access";

const AccessController = {
  getAccess: () => {
    const storedAccess = sessionStorage.getItem(SESSIONSTORAGE_KEY);
    if (storedAccess !== null) {
      return JSON.parse(storedAccess) as Access;
    } else {
      const access = AccessService.retrieveAccess()
        .then((access) => {
          sessionStorage.setItem(SESSIONSTORAGE_KEY, JSON.stringify(access));
          return access;
        })
        .catch((error) => ({} as Access));
      return access;
    }
  },
  getMaintenanceInfo: async (
    maintenanceController: MaintenanceController,
    callbackOnFail?: (error: any) => void
  ) => {
    console.log("Maintenance support disabled.");
    // if (KeyCloakService.isLoggedIn()) {
    //   startMaintenanceRefreshTask(maintenanceController);
    //   AccessService.retrieveMaintenanceInfo()
    //     .then((info) => {
    //       maintenanceController.setMaintenanceInfo(info);
    //     })
    //     .catch((error) => {
    //       console.log("error when retrieving maintenance info");
    //       if (callbackOnFail) callbackOnFail(error);
    //     });
    // }
  },
  updateMaintenanceInfo: async (
    info: MaintenanceInfo,
    controller: MaintenanceController,
    callbackOnSuccess: (info: MaintenanceInfo) => void,
    callbackOnFail?: (error: any) => void
  ) => {
    AccessService.updateMaintenanceInfo(info)
      .then((info) => {
        controller.setMaintenanceInfo(info);
        callbackOnSuccess(info);
      })
      .catch(callbackOnFail);
  },
};

export default AccessController;
