import HttpService from "App/service/http.service";
import Config from "Config";
import ModalController from "global/hook/modal/interface/ModalController";
import LogfilefehlerDTO from "./dto/LogfilefehlerDTO";
import Logfilefehler from "./interface/Logfilefehler";

const basePath = "logfilefehler/";

const LogfilefehlerService = {
  loadByAutomatSerienNr: (
    automatSerienNr: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(
        `${Config.logfileServiceUrl}${basePath}automat/${automatSerienNr}`
      )
      .then((response) => {
        callbackOnSuccess(LogfilefehlerDTO.convertLogErrorResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  update: (
    logfilefehler: Logfilefehler,
    callbackOnSuccess: Function,
    callbackOnFail?: Function,
    modalController?: ModalController
  ) => {
    HttpService.api
      .sendJsonDataPost(
        `${Config.logfileServiceUrl}${basePath}updateErledigt`,
        logfilefehler
      )
      .then((response) => {
        callbackOnSuccess(LogfilefehlerDTO.convertUpdateResponse(response));
        if (modalController) {
          modalController.showMessageDialog(
            "Der erledigt-Status wurde erfolgreich aktualisiert!"
          );
        }
      })
      .catch((error) => {
        if (callbackOnFail) {
          callbackOnFail(error);
        }
        console.warn(error);
      });
  },
};
export default LogfilefehlerService;
