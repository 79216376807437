import ModalController from "global/hook/modal/interface/ModalController";

const TAB_CHANGE_MESSAGE =
  "Drücken Sie OK, um die Daten zu speichern und den Tab zu Wechseln.\n" +
  "Drücken Sie ABBRECHEN, um auf der aktuellen Seite zu bleiben.";

const AutomatDetailPanelController = {
  TabChangeHandler: (
    modalController: ModalController,
    onOkayAction: Function
  ) =>
    new Promise((resolve: (value: boolean) => void) => {
      modalController.showMessageDialog(
        TAB_CHANGE_MESSAGE,
        () => {
          onOkayAction();
          resolve(true);
        },
        "Ungespeicherte Änderungen vorhanden!",
        () => resolve(false)
      );
    }),
};

export default AutomatDetailPanelController;
