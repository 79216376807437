import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

const KaufanfragenColumnDefs: Array<ColDef> = [
  {
    headerName: "Region",
    field: "ORG_NAME",
    minWidth: 70,
    maxWidth: 70,
  },
  {
    headerName: "RNS-Name",
    field: "RNS_NAME",
    minWidth: 260,
  },
  {
    headerName: "PLZ",
    field: "PLZ",
    minWidth: 60,
  },
  {
    headerName: "Standort",
    field: "ORT",
    minWidth: 120,
  },
  {
    headerName: "Strasse",
    field: "STRASSE",
    minWidth: 160,
  },
  {
    headerName: "GLN / RNS",
    field: "GLN_RNS",
    minWidth: 120,
  },
  {
    headerName: "Modell",
    field: "MODELL_NAME",
    minWidth: 120,
  },
  {
    headerName: "Hersteller",
    field: "HERSTELLER_NAME",
    minWidth: 120,
  },
  {
    headerName: "Angefragt am",
    field: "ANGEFRAGT_AM",
    minWidth: 150,
    maxWidth: 150,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Entschieden durch",
    field: "ENTSCHIEDEN_DURCH",
    minWidth: 170,
  },
  {
    headerName: "Entschieden am",
    field: "ENTSCHIEDEN_AM",
    minWidth: 150,
    maxWidth: 150,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Status",
    field: "STATUS",
    minWidth: 110,
  },
  {
    headerName: "Letzte Bearbeitung",
    field: "LETZTE_BEARBEITUNG",
    minWidth: 160,
    maxWidth: 160,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Ablehnungsgrund",
    field: "GRUND",
    minWidth: 160,
  },
];

export default KaufanfragenColumnDefs;
