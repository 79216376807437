import {
  Alert,
  CircularProgress,
  Grid,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { useCockpitInfo } from "global/hook/cockpit/use-cockpit-info";
import React, { useEffect } from "react";
import "./Cockpit.css";
import CockpitTheme from "./CockpitTheme";
import Anwendernachrichten from "./components/Anwendernachrichten/Anwendernachrichten";
import Automatenstatus from "./components/Automatenstatus/Automatenstatus";
import EpgMonitoring from "./components/EpgMonitoring/EpgMonitoring";
import Fehleranzeige from "./components/Fehleranzeige/Fehleranzeige";
import Kaufanfragen from "./components/Kaufanfragen/Kaufanfragen";
import Notizen from "./components/Notizen/Notizen";
import Rechnungen from "./components/Rechnungen/Rechnungen";

const Cockpit: React.FC = () => {
  const [cockpitInfo, getCockpitInfo] = useCockpitInfo(true);

  useEffect(() => {
    getCockpitInfo(); // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={CockpitTheme}>
      <Stack alignItems="center">
        {cockpitInfo.isLoading && (
          <CircularProgress
            sx={{
              display: "inline",
              marginTop: 2,
              marginBottom: 2,
            }}
            size={80}
          />
        )}
        {cockpitInfo.loadingError && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              display: "flex",
              borderWidth: 2,
              alignItems: "center",
              fontSize: 16,
            }}
          >
            <strong>Beim Abrufen der Daten ist ein Fehler aufgetreten!</strong>
          </Alert>
        )}
      </Stack>
      <Grid
        container
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }}
        padding={6}
        sx={{ display: "flex" }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Automatenstatus />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Kaufanfragen />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Fehleranzeige />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Notizen />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Rechnungen />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <EpgMonitoring />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Anwendernachrichten />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Cockpit;
