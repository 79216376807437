import { Text, View } from "@react-pdf/renderer";
import { pageStyles } from "../PdfSteckbriefContent";

interface props {
  mainInfo: string;
  secondaryInfo?: string;
}

const PdfRowItemHeader = (props: props) => {
  return (
    <View style={[pageStyles.contentRow, pageStyles.contentRowHeader]}>
      <Text style={pageStyles.itemTextBold}>{props.mainInfo} </Text>
      {props.secondaryInfo && <Text>{" " && props.secondaryInfo}</Text>}
    </View>
  );
};

export default PdfRowItemHeader;
