import { ColDef } from "ag-grid-community";

const GLNOhneZuordnungColumnDefs: Array<ColDef> = [
  {
    headerName: "Anzahl",
    field: "anzahlPositionen",
    minWidth: 70,
  },
  {
    headerName: "Kunde-GLN",
    field: "kundeGln",
    minWidth: 120,
  },
  {
    headerName: "RNS-GLN",
    field: "rnsGln",
    minWidth: 100,
  },
  {
    headerName: "RNS-Name",
    field: "rnsName",
    minWidth: 280,
  },
  {
    headerName: "RNS-PLZ",
    field: "rnsPlz",
    minWidth: 100,
  },
  {
    headerName: "RNS-Ort",
    field: "rnsOrt",
    minWidth: 240,
  },
  {
    headerName: "RNS-Strasse",
    field: "rnsStrasse",
    minWidth: 280,
  },
  {
    headerName: "Rechnungsempfänger",
    field: "nameRechnungsEmpfaenger",
    minWidth: 220,
  },
  {
    headerName: "Rechn.Empf.GLN",
    field: "sapStammdatenRnGln",
    minWidth: 160,
  },
  {
    headerName: "Seriennummer lt.Rechnung",
    field: "serienNr",
    minWidth: 260,
  },
];

export default GLNOhneZuordnungColumnDefs;
