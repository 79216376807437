import ArtikleService from "service/artikel-service/Article.service";
import ArtikleSearchResult from "service/artikel-service/interface/ArtikleSearchResult";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_ARTICLE_SEARCH = "FETCH_ARTICLE_SEARCH";

const configureArticleSearchStore = () => {
  const doArticleSearch: AutomatAction = {
    identifier: FETCH_ARTICLE_SEARCH,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      ArtikleService.search(
        currentState.automatenblattInformation.ssidAktiv,
        payload.artikelGtin,
        (result: ArtikleSearchResult) =>
          callback({
            artikleSearchResult: result,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([doArticleSearch]);
};

export default configureArticleSearchStore;
