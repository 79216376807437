import { Grid, Typography } from "@mui/material"
import DataGrid from "global/components/UI/DataGrid/DataGrid"
import ServiceResponseHandler from "service/interface/ServiceResponseHandler"
import SapInputParam from "service/sap-service/interface/SapInputParam"
import SapOutputParam from "service/sap-service/interface/SapOutputParam"
import PositionService from "service/sap-service/Position.service"
import RegionSelectionColumnDefs from "./grid-column-defs"
import RegionListController from "./RegionListDialogController"

export interface RegionsListDialogContentProps {
    zipcode: string
    onRegionSelected: Function
}

const RegionsListDialogContent = (props: RegionsListDialogContentProps) => {

    const gridDataFetchCall = (handler: ServiceResponseHandler) => {
        PositionService.getRnsDataEpcomByPlz(
            {
                rnsPlz: props.zipcode
            } as SapInputParam,
            (response: SapOutputParam) => {
                handler.callbackOnSuccess(
                    RegionListController.convertPositionsData(
                        response.positionEdits,
                        props.onRegionSelected));
            },
            handler.callbackOnFail
        )
    }
    return (
        <>
            <Grid container spacing={5} width={1400}>
                <Grid item xs={12}>
                    <DataGrid
                        height={300}
                        rowHeight={44}
                        columnDefs={RegionSelectionColumnDefs}
                        gridActions={<Typography variant={"h1"}>PLZ Suche</Typography>}
                        rowsDataDef={{
                            uncontrolledDataFetchCall: gridDataFetchCall,
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default RegionsListDialogContent
