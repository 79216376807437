import { ColDef } from "ag-grid-community";

const WarengruppeZuordnungColumnDefs: Array<ColDef> = [
  {
    headerName: "Unter-Warengruppe Name",
    field: "unterWarengruppeName",
    minWidth: 360,
  },
  {
    headerName: "Kurztext",
    field: "kurzText",
    minWidth: 330,
  },
  {
    headerName: "Anzahl",
    field: "anzahlPositionen",
    minWidth: 70,
    maxWidth: 70,
  },
  {
    headerName: "ATH",
    field: "rechnungsstellerKuerzel",
    minWidth: 70,
    maxWidth: 70,
  },
];

export default WarengruppeZuordnungColumnDefs;
