import { View } from "@react-pdf/renderer";
import KeyValue from "global/interface/KeyValue";
import { SteckbriefProps, pageStyles } from "./PdfSteckbriefContent";
import PdfRowItemHeader from "./component/PdfRowItemHeader";
import PdfRowKeyValuesItem from "./component/PdfRowKeyValuesItem";
import PdfRowTextItem from "./component/PdfRowTextItem";

const PdfUsageDataItem = (props: SteckbriefProps) => {
  const data = props.steckbrief.zurAuslatung;

  const kvData: Array<KeyValue> = [
    { key: "Verfügbarkeit", value: data.verfugbarkeit },
    { key: "Auslastung", value: data.austlastung },
    { key: "Ranking", value: data.ranking },
  ];

  const anteileKVs = ensureKeyValueItems(
    data.anteile ?? [],
    props.steckbrief.undefinedValue
  );
  const bonSummeKVs = ensureKeyValueItems(
    data.bonSumme ?? [],
    props.steckbrief.undefinedValue
  );
  return (
    <View>
      <PdfRowItemHeader mainInfo="Daten zur Auslastung "></PdfRowItemHeader>
      <PdfRowTextItem
        header="Durchschnittliche Anzahl EW-Gebinde pro Tag der letzten Woche"
        info={data.durchschnittlicheProTag}
      ></PdfRowTextItem>
      <PdfRowKeyValuesItem
        header="Durchschnittliche EW-MW - Bonsumme pro Tag (Betrachtungszeitraum 1 Woche)"
        info={bonSummeKVs}
        keyStyle={pageStyles.keyContainer10}
      ></PdfRowKeyValuesItem>

      <PdfRowKeyValuesItem
        header="Anteile EW/MW in %"
        info={anteileKVs}
        keyStyle={pageStyles.keyContainer10}
      ></PdfRowKeyValuesItem>

      <PdfRowKeyValuesItem
        header="Verfügbarkeitsanalyse"
        info={kvData}
        keyStyle={pageStyles.keyContainer30}
      ></PdfRowKeyValuesItem>
      <PdfRowTextItem header=" " info=" "></PdfRowTextItem>
    </View>
  );
};

const ensureKeyValueItems = (
  data: Array<KeyValue>,
  undefinedValue: string
): Array<KeyValue> => {
  const ewKV: KeyValue = data.find((kv) => kv.key === "EW") ?? {
    key: "EW",
    value: undefinedValue,
  };
  const mwKV: KeyValue = data.find((kv) => kv.key === "MW") ?? {
    key: "MW",
    value: undefinedValue,
  };
  return [ewKV, mwKV];
};

export default PdfUsageDataItem;
