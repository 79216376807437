import HttpService from "App/service/http.service";
import Config from "Config";
import RnsSearchResultDTO from "./dto/RnsSearchResultDTO";
import RnsGln from "./interface/RnsGln";
import RnsHistory from "./interface/RnsHistory";
import SearchQueryOptions from "./interface/SearchQueryOptions";

const RnsService = {
  searchRNS: (
    searchQuery: SearchQueryOptions,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "rns/search", {
        params: {
          rnsGln: searchQuery.gln,
          oldrnsgln: searchQuery.oldGln,
          callSource: searchQuery.callSource,
        },
      })
      .then((response) => {
        callbackOnSuccess(
          RnsSearchResultDTO.convertResponseToRnsSearchResultDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  updateContactOfGLN: (
    gln: RnsGln,
    callbackOnSuccess?: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(Config.dataServiceUrl + "rns/update", gln)
      .then((response) => {
        if (callbackOnSuccess) callbackOnSuccess(response.data);
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  searchRNSHistory: (
    automatKey: string,
    callbackOnSuccess: (response: Array<RnsHistory>) => void,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(
        Config.dataServiceUrl + "rns/search/rns-history/" + automatKey + "/"
      )
      .then((response) => {
        callbackOnSuccess(
          RnsSearchResultDTO.convertResponseToRnsSearchHistoryDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
        // @TODO fire error message
      });
  },
  rnsMatches: (
    gln: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function,
    limit = 50
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "rns/findList", {
        params: {
          rnsGln: gln,
          limit: limit,
        },
      })
      .then((response) => {
        callbackOnSuccess(
          RnsSearchResultDTO.convertResponseToRnsList(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default RnsService;
