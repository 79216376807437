import Enumerator, {
  EnumeratorItem,
  enumeratorItemForId,
  EnumeratorItemId,
} from "global/components/EnumeratedValues/Enumerator";
import {
  deploymentInfoChangeMerger,
  TRACK_CHANGE,
} from "global/hook/datastore/automat/change-tracker-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { DispatchAutomatDataFunction } from "global/hook/datastore/use-automat-datastore";
import DateUtils from "global/util/DateUtils";
import {
  DeploymentInfo,
  DeploymentInfoType,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { ITEM_WITH_DATE } from "./AutomatDeploymentInfoPopperContent";

export interface AutomatDeploymentInfoControllerContext {
  deploymentInfoType: DeploymentInfoType;
  dispatchUpdate: DispatchAutomatDataFunction;
  referenceData: DeploymentInfo;
  userData: DeploymentInfo;
  auspragungEnumerator: Enumerator;
}

function registerDeploymentInfoChange(
  context: AutomatDeploymentInfoControllerContext,
  attributeName: string,
  referenceValue: string,
  newValue: string
) {
  context.dispatchUpdate(TRACK_CHANGE, {
    change: {
      refValue: referenceValue,
      value: newValue,
      path: `deploymentInfo:${context.deploymentInfoType}:.${attributeName}`,
      merger: deploymentInfoChangeMerger,
    },
  } as AutomatActionPayload);
}

function isVersionUpgrade(
  newValue: EnumeratorItem | null,
  context: AutomatDeploymentInfoControllerContext
): boolean {
  if (context.deploymentInfoType !== "VERSION") return false;
  if (!newValue) return false;
  if (!context.userData.value) return true;
  const refVersion =
    (enumeratorItemForId(context.auspragungEnumerator, context.userData.value)
      ?.value as string) ?? "";
  const newVersion = newValue.value as string;

  const refParts = refVersion.split(".").map((part) => parseInt(part));
  const newParts = newVersion.split(".").map((part) => parseInt(part));
  // Ensure both arrays have the same length
  while (refParts.length < newParts.length) refParts.push(0);
  while (newParts.length < refParts.length) newParts.push(0);

  for (let i = 0; i < refParts.length; i++) {
    if (newParts[i] > refParts[i]) return true;
    if (newParts[i] < refParts[i]) return false;
  }
  return false;
}

const AutomatDeploymentInfoController = {
  geplanteUmstellungChangeHandler(
    context: AutomatDeploymentInfoControllerContext,
    umstellungType: EnumeratorItemId,
    geplanteDate: Date | undefined
  ) {
    registerDeploymentInfoChange(
      context,
      "umstellungType",
      context.referenceData.umstellungType ?? "",
      umstellungType as string
    );
    const gDate =
      umstellungType !== ITEM_WITH_DATE
        ? undefined
        : geplanteDate ?? new Date();

    registerDeploymentInfoChange(
      context,
      "geplanteDate",
      context.referenceData.geplanteDate ?? "",
      DateUtils.dateToAPIString(gDate)
    );
  },
  valueChangeHandler(
    context: AutomatDeploymentInfoControllerContext,
    newValue: EnumeratorItem | null
  ) {
    registerDeploymentInfoChange(
      context,
      "value",
      context.referenceData.value ?? "",
      newValue?.id as string
    );

    AutomatDeploymentInfoController.seitChangeHandler(context, new Date());

    if (isVersionUpgrade(newValue, context)) {
      AutomatDeploymentInfoController.geplanteUmstellungChangeHandler(
        context,
        "NOT_REQUIRED",
        undefined
      );
    }
  },

  seitChangeHandler(
    context: AutomatDeploymentInfoControllerContext,
    newValue: Date | null
  ) {
    registerDeploymentInfoChange(
      context,
      "seit",
      context.referenceData.seit ?? "",
      DateUtils.dateToAPIString(newValue)
    );
  },
};

export default AutomatDeploymentInfoController;
