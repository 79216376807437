import DateUtils from "global/util/DateUtils";
import { returnUndefinedIfOnlyWhitespace } from "global/util/utils";
import AutomatenblattInformationService from "service/data-service/automate-controller/Automatenblatt.service";
import NewAutomat from "service/data-service/automate-controller/interface/NewAutomat";
import KaufanfragenService from "service/data-service/kaufanfragen-controller/Kaufanfragen.Service";
import Kaufanfrage from "service/data-service/kaufanfragen-controller/interface/Kaufanfrage";
import {
  KaufanfragenATHAutomatInsertDialogData,
  KaufanfragenATHCreateDialogData,
  KaufanfragenGridActionProps,
} from "../interface/Kaufanfragen";
import KaufanfragenUtil from "./KaufanfragenUtil";

const KaufanfragenATHController = {
  create(
    props: KaufanfragenGridActionProps,
    kaufanfrageStellenData: KaufanfragenATHCreateDialogData
  ) {
    if (kaufanfrageStellenData.rns && kaufanfrageStellenData.modell) {
      KaufanfragenUtil.showProgressIndicatorDialog(
        props.modals,
        "Kaufanfrage wird erstellt..."
      );
      KaufanfragenService.insert(
        {
          EDKORG_GLN: kaufanfrageStellenData.rns.edekaOrganisation.gln,
          ORG_NAME: kaufanfrageStellenData.rns.edekaOrganisation.region,
          RNS_NAME: kaufanfrageStellenData.rns.name,
          PLZ: kaufanfrageStellenData.rns.plz,
          ORT: kaufanfrageStellenData.rns.ort,
          STRASSE: kaufanfrageStellenData.rns.strasse,
          GLN_RNS: kaufanfrageStellenData.rns.gln,
          MODELL_KEY: kaufanfrageStellenData.modell.id as string,
          MODELL_NAME: kaufanfrageStellenData.modell.value,
          ORG_EMAIL: kaufanfrageStellenData.rns.edekaOrganisation.email,
          STATUS: "ANGEFRAGT",
        } as Kaufanfrage,
        (record: Kaufanfrage) => {
          KaufanfragenUtil.onServiceCallSuccess(
            "Kaufanfrage wurde erfolgreich angelegt.",
            props
          );
        },
        (err: Error) => {
          KaufanfragenUtil.onServiceCallFail(props);
        }
      );
    }
  },
  delete(props: KaufanfragenGridActionProps) {
    if (KaufanfragenUtil.isGridSelectionSuitable(props)) {
      KaufanfragenUtil.showProgressIndicatorDialog(
        props.modals,
        "Kaufanfrage wird geloescht..."
      );
      KaufanfragenUtil.updateKaufanfrageSelection(
        props,
        "GELOESCHT",
        undefined,
        "Die Kaufanfrage wurde erfolgreich geloescht!"
      );
    }
  },
  insertAutomat(
    props: KaufanfragenGridActionProps,
    kaufanfrageAnlegenData: KaufanfragenATHAutomatInsertDialogData
  ) {
    AutomatenblattInformationService.create(
      {
        rnsGln: props.gridSelection!.GLN_RNS,
        modellKey: props.gridSelection!.MODELL_KEY,
        serienNr: kaufanfrageAnlegenData.seriennummer!.trim(),
        ssidAktiv: returnUndefinedIfOnlyWhitespace(
          kaufanfrageAnlegenData.schlusselId
        ),
        automatStatusGueltigVonInZukunft:
          DateUtils.formatDateToAPIDateString(kaufanfrageAnlegenData.aktivAb) ??
          undefined,
        deploymentInfo: [{ value: kaufanfrageAnlegenData.aleVersion }],
      } as NewAutomat,
      (automatResult: NewAutomat) => {
        if (automatResult.errorTyp === "NO_ERROR") {
          // status auf angelegt setzen und Automat-Key übergeben
          KaufanfragenUtil.updateKaufanfrageSelection(
            props,
            "ANGELEGT",
            { AUTOMAT_KEY: automatResult.automatKey } as Kaufanfrage,
            "Der Automat wurde erfolgreich angelegt !"
          );
        }
      },
      undefined,
      (remoteError: string) => {
        KaufanfragenUtil.onServiceCallSuccess(remoteError, props, false);
      }
    );
  },
};
export default KaufanfragenATHController;
