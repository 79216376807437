import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "@ag-grid-community/styles/ag-theme-balham.min.css";
import {
  CellClickedEvent,
  ColDef,
  GridReadyEvent,
  RowDataUpdatedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import Row from "page/Automatensuche/interface/RowTypes";
import { Ref, useCallback, useEffect, useMemo, useState } from "react";
import DepositMachineSearchColums from "./colums";

import "./data-grid.scss";

interface props {
  dataToShow: Array<Row>;
  onCellClicked(data: Row): any;
  getRef?: (ref: AgGridReact | null) => void;
  dataGridRef?: Ref<AgGridReact>;
  onGridReady?: (event: GridReadyEvent) => void;
  onRowDataChanged?: (event: RowDataUpdatedEvent) => void;
}

const DataGrid = (props: props) => {
  const [rowData, setRowData] = useState<Array<Row>>(); // Set rowData to Array of Objects, one Object per Row

  // DefaultColDef sets props common to all Columns
  const defaultColumnDefinition = useMemo<ColDef>(
    () => ({
      sortable: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  // Example of consuming Grid Event
  const cellClickedListener = useCallback(
    (event: CellClickedEvent) => {
      props.onCellClicked(event.data);
    },
    [props]
  );

  // Example load data from sever
  useEffect(() => {
    setRowData(props.dataToShow);
  }, [props.dataToShow]);

  return (
    <div className="ag-theme-balham" style={{ width: "100%", height: 500 }}>
      <AgGridReact
        ref={props.dataGridRef}
        suppressColumnVirtualisation
        rowData={rowData} // Row Data for Rows
        columnDefs={DepositMachineSearchColums} // Column Defs for Columns
        defaultColDef={defaultColumnDefinition} // Default Column Properties
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
        onGridReady={(event) => {
          if (props.onGridReady) props.onGridReady(event);
        }}
        onRowDataUpdated={(event) => {
          if (props.onRowDataChanged) props.onRowDataChanged(event);
        }}
        columnHoverHighlight={true}
      />
    </div>
  );
};

export function CellValueRenderer(params: any) {
  return params.value;
}

export default DataGrid;
