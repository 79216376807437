import { ColDef } from "ag-grid-community";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

export const ClearingfehlerPanelColumnDefs: Array<ColDef> = [
  {
    headerName: "Schlüssel-ID",
    field: "schluesselId",
    minWidth: 130,
  },
  {
    headerName: "Gültig von",
    field: "gueltigVon",
    minWidth: 90,
    maxWidth: 110,
  },
  {
    headerName: "Gültig bis",
    field: "gueltigBis",
    minWidth: 90,
    maxWidth: 110,
  },
  {
    headerName: "Fehlerbeschreibung",
    field: "fehlerbeschreibung",
    minWidth: 300,
  },
  {
    headerName: "Anzahl MM",
    field: "anzahlMm",
    minWidth: 110,
  },
  {
    headerName: "Anzahl Rohdatensätze",
    field: "anzahlRohdatensaetze",
    minWidth: 190,
  },
  {
    headerName: "Prüfungsdatum",
    field: "pruefungsdatum",
    minWidth: 140,
  },
  {
    field: "erledigtCellValue",
    headerName: "Erledigt",
    cellRenderer: CellValueRenderer,
    maxWidth: 90,
    minWidth: 90,
    cellClass: "ag-cell-center-justified-content",
  },
];
