import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

const DepositMachineSearchColums: Array<ColDef> = [
  {
    field: "region",
    headerName: "Region",
    suppressSizeToFit: true,
    width: 55,
    flex: 0,
    cellClass: (params) => {
      return params.data?.andereRegion ? "hinweis" : "";
    },
  },
  {
    field: "regionName",
    headerName: "RN",
    suppressSizeToFit: true,
    width: 150,
    flex: 0,
    cellClass: (params) => {
      return params.data?.andereRegion ? "hinweis" : "";
    },
  },
  {
    field: "andereRegion",
    headerName: "andere GHB",
    suppressSizeToFit: true,
    width: 110,
    flex: 0,
    cellClass: (params) => {
      return params.value ? "hinweis" : "";
    },

    cellRenderer: "agGroupCellRenderer",
    valueFormatter: (params) => (params.value ? " X " : ""),
  },
  {
    field: "rnsName",
    headerName: "RNS",
  },
  {
    field: "rnsPlz",
    headerName: "PLZ",
  },
  {
    field: "rnsOrt",
    headerName: "Standort",
    suppressSizeToFit: true,
    width: 100,
    flex: 0,
  },
  {
    field: "rnsStrasse",
    headerName: "Straße",
  },
  {
    field: "rnsGln",
    headerName: "GLN/RNS",
  },
  {
    field: "automatModellName",
    headerName: "Modell",
    suppressSizeToFit: true,
    width: 110,
    flex: 0,
  },
  {
    field: "automatSerienNr",
    headerName: "Seriennummer",
  },
  {
    field: "automatSsid",
    headerName: "Schlüssel-ID",
  },
  {
    field: "automatStatus",
    headerName: "Automat Zustand",
  },
  {
    field: "automatStatusGueltigVon",
    headerName: "Zustand gültig ab",
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    field: "rnsSapGueltigVon",
    headerName: "GLN gültig ab",
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    field: "rnsSapGueltigBis",
    headerName: "GLN gültig bis",
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
];

export default DepositMachineSearchColums;
