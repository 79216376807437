import { Button } from "@mui/material";
import { returnUndefinedIfOnlyWhitespace } from "global/util/utils";
import KaufanfragenATHController from "../controller/KaufanfragenATHController";
import KaufanfragenUtil from "../controller/KaufanfragenUtil";
import {
  KaufanfragenATHAutomatInsertDialogData,
  KaufanfragenATHCreateDialogData,
  KaufanfragenGridActionProps,
} from "../interface/Kaufanfragen";
import KaufanfragenATHAutomatInsertDialogContent from "./KaufanfragenATHAutomatInsertDialog";
import KaufanfragenATHCreateDialogContent from "./KaufanfragenATHCreateDialog";

const KaufanfragenATHGridActions = (props: KaufanfragenGridActionProps) => {
  let kaufanfrageCreateData: KaufanfragenATHCreateDialogData = {};
  let kaufanfrageAutomatInsertData: KaufanfragenATHAutomatInsertDialogData =
    {} as KaufanfragenATHAutomatInsertDialogData;

  const onKaufanfrageCreateDataChange = (
    data: KaufanfragenATHCreateDialogData
  ) => {
    kaufanfrageCreateData = data;
    props.dialogController.disableOkAction(!kaufanfrageCreateData.modell);
  };

  const onKaufanfrageAutomatInsertDataChange = (
    data: KaufanfragenATHAutomatInsertDialogData
  ) => {
    kaufanfrageAutomatInsertData = data;
    props.dialogController.disableOkAction(
      !returnUndefinedIfOnlyWhitespace(
        kaufanfrageAutomatInsertData.seriennummer
      ) ||
        !returnUndefinedIfOnlyWhitespace(
          kaufanfrageAutomatInsertData.aleVersion
        )
    );
  };

  const doKaufanfrageCreate = () => {
    KaufanfragenATHController.create(props, kaufanfrageCreateData);
  };

  const showKaufanfrageCreateDialog = () => {
    props.dialogController.showDialog({
      message: "",
      title: "Kaufanfrage stellen",
      content: (
        <KaufanfragenATHCreateDialogContent
          dataChangeListener={onKaufanfrageCreateDataChange}
        />
      ),
      onOkClick: doKaufanfrageCreate,
      okCaption: "Kaufanfrage stellen",
      okActionDisabled: true,
      onAbortClick: () => {},
      abortCaption: "Abbrechen",
    });
  };

  const doInsertAutomat = () => {
    KaufanfragenATHController.insertAutomat(
      props,
      kaufanfrageAutomatInsertData
    );
  };
  const showAutomatCreateDialog = () => {
    if (KaufanfragenUtil.isGridSelectionSuitable(props, "GENEHMIGT")) {
      props.dialogController.showDialog({
        message: "",
        title: "Automat anlegen",
        content: (
          <KaufanfragenATHAutomatInsertDialogContent
            dataChangeListener={onKaufanfrageAutomatInsertDataChange}
          />
        ),
        onOkClick: doInsertAutomat,
        okCaption: "Automat anlegen",
        okActionDisabled: true,
        onAbortClick: () => {},
        abortCaption: "Abbrechen",
      });
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={showKaufanfrageCreateDialog}
      >
        Kaufanfrage stellen
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={() => {
          KaufanfragenATHController.delete(props);
        }}
      >
        Löschen
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={showAutomatCreateDialog}
      >
        Automat anlegen
      </Button>
    </>
  );
};

export default KaufanfragenATHGridActions;
