import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { useTabs } from "global/hook/tabs/use-tabs";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { AutomatWithTooManyFiles } from "service/data-service/automate-controller/interface/AutomatWithTooManyFiles";
import ExportService from "service/report-service/export/Export.service";
import AutomatenDatenGt30Controller from "./automatendatengt30-controller";
import AutomatendatenGt30ColumnDefs from "./grid-column-defs";

const AutomatenDatenGt30: React.FC = () => {
  const windowViewport = useWindowViewport(0, 210);
  const [, , tabsController] = useTabs();
  const pastDaysCount = 5;

  return (
    <Stack className="fehlerRecords">
      <Grid container spacing={1} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>{"Automaten Daten > 30"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={AutomatendatenGt30ColumnDefs(pastDaysCount)}
            onRowClicked={(row: AutomatWithTooManyFiles) => {
              tabsController.open(row.serial);
            }}
            exportDef={{
              action: ExportService.downloadAutomatWithTooManyFilesData,
            }}
            rowsDataDef={{
              uncontrolledDataFetchCall:
                AutomatenDatenGt30Controller.loadData.bind(null, pastDaysCount),
              cacheProps: {
                cacheKey: "automatendatengt30-records",
                expireAgeInSeconds: 30,
                lifespan: "LONG",
              },
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AutomatenDatenGt30;
