import { ColDef } from "ag-grid-community";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

const AnlageListColumnDefs: Array<ColDef> = [
  {
    headerName: "Anlagen herunterladen",
    field: "anlageBtn",
    minWidth: 320,
    cellRenderer: CellValueRenderer,
  },
];

export const SeriennrListColumnDefs: Array<ColDef> = [
  {
    headerName: "SN übernehmen",
    field: "seriennrBtn",
    minWidth: 260,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: "Automat status",
    field: "status",
    minWidth: 140,
  },
];

export default AnlageListColumnDefs;
