import StammdatenService from "service/data-service/stammdaten-controller/Stammdaten.service";
import DPGStammdatenResult from "service/data-service/stammdaten-controller/interface/DPGStammdaten";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_DPG_STAMMDATEN = "FETCH_DPG_STAMMDATEN";

const configureAutomatDpgStammdatenStore = () => {
  const fetchInfo: AutomatAction = {
    identifier: FETCH_DPG_STAMMDATEN,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      StammdatenService.getDpgStammdaten(
        payload.automatKey,
        (data: DPGStammdatenResult) =>
          callback({
            dpgStammdaten: data,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([fetchInfo]);
};

export default configureAutomatDpgStammdatenStore;
