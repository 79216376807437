import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CockpitInformationService from "service/data-service/cockpit-controller/CockpitInformation.service";
import CockpitInformationDTO from "service/data-service/cockpit-controller/interface/CockpitInformation";
import CockpitVisibilitiesDTO from "service/data-service/cockpit-controller/interface/CockpitVisibilities";
import EpgCardInformationDTO from "service/data-service/cockpit-controller/interface/EpgCardInformation";
import CockpitInfo from "./interface/CockpitInfo";

let cockpitData: CockpitInfo = {
  isLoading: false,
} as CockpitInfo;

let listeners: Array<Dispatch<SetStateAction<CockpitInfo>>> = [];

const updateCockpitInfo = (data: CockpitInfo) => {
  cockpitData = {
    ...cockpitData,
    ...data,
  };
  listeners.forEach((listener) => {
    listener(cockpitData);
  });
};

const callbackForData = (
  cockpitInformationDTO: CockpitInformationDTO,
  epgCardInformationDTO: EpgCardInformationDTO
) => {
  updateCockpitInfo({
    cockpitInformationDTO: cockpitInformationDTO,
    epgCardInformationDTO: epgCardInformationDTO,
  } as CockpitInfo);
};

const callbackForVisibilities = (
  cockpitVisibilitiesDTO: CockpitVisibilitiesDTO
) => {
  updateCockpitInfo({
    isLoading: false,
    visibilities: cockpitVisibilitiesDTO,
  } as CockpitInfo);
};

function callbackOnFail(error: Error) {
  updateCockpitInfo({
    isLoading: false,
    loadingError: true,
  } as CockpitInfo);
}

const getCockpitInfo = () => {
  if (cockpitData.isLoading) {
    return;
  }
  updateCockpitInfo({
    isLoading: true,
    loadingError: false,
  } as CockpitInfo);
  CockpitInformationService.retrieveCockpitInformation(
    callbackForData,
    callbackForVisibilities,
    callbackOnFail
  );
};

export function useCockpitInfo(canUpdate = false): [CockpitInfo, Function] {
  const setCockpitInfo = useState<CockpitInfo>(cockpitData)[1];

  useEffect(() => {
    if (canUpdate) {
      listeners.push(setCockpitInfo);
      return () => {
        listeners = listeners!.filter((li) => li !== setCockpitInfo);
      };
    } // eslint-disable-next-line
  }, [setCockpitInfo]);

  return [cockpitData, getCockpitInfo];
}
