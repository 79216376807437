import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import KeyValue from "global/interface/KeyValue";
import { pageStyles } from "../PdfSteckbriefContent";

interface props {
  header: string;
  info: Array<KeyValue>;
  keyStyle?: Style;
  valueStyle?: Style;
}

export const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
});

const PdfRowKeyValuesItem = (props: props) => {
  return (
    <View style={pageStyles.contentRow}>
      <View style={pageStyles.itemHeader}>
        <Text>{props.header}</Text>
      </View>
      <View style={pageStyles.itemInfo}>
        {props.info.map((info, index) => (
          <View key={`kv-info-${index}`} style={styles.row}>
            <Text style={props.keyStyle}>{info.key}</Text>
            <Text style={props.valueStyle}>{`= ${info.value ?? "xxxx"}`}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default PdfRowKeyValuesItem;
