import { Search } from "@mui/icons-material";
import { Button, IconButton, Stack, TypographyProps } from "@mui/material";
import TextValue from "global/components/UI/TextValue/TextValue";
import { ReactNode, useEffect, useState } from "react";

export interface WGRKurztextFilterProps {
  applyFilter: (filter?: string) => void;
  applyFilterWhileTyping?: boolean;
  TypographyProps?: TypographyProps;
  buttonCaption?: string;
  icon?: ReactNode;
  label?: string;
  inputClassName?: string;
}

const WGRKurztextFilter = (props: WGRKurztextFilterProps) => {
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState<string | undefined>();

  useEffect(() => {
    props.applyFilter(filter); // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (props.applyFilterWhileTyping) {
      const identifier = setTimeout(() => doSearch(), 500);
      return () => clearTimeout(identifier);
    } // eslint-disable-next-line
  }, [searchText]);

  const doSearch = () => {
    if (searchText && searchText.length > 2) {
      setFilter(searchText);
    } else {
      setFilter(undefined);
    }
  };

  return (
    <Stack className="wgrzuordnung__rowStack" columnGap={1}>
      <TextValue
        id={"wgr-suche-text"}
        label={props.label ?? "Kurztext:"}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value ?? "")}
        onEnterKey={doSearch}
        onEscapeKey={() => {
          setFilter(undefined);
          setSearchText("");
        }}
        TypographyProps={
          props.TypographyProps ?? { className: "wgrzuordnung__TextStyle" }
        }
        TextFieldProps={{ className: props.inputClassName }}
      />
      {props.buttonCaption && (
        <Button
          size="small"
          variant="contained"
          onClick={() => props.applyFilter(searchText)}
          endIcon={props.icon}
        >
          {props.buttonCaption}
        </Button>
      )}
      {!props.buttonCaption && (
        <IconButton
          color="primary"
          onClick={() => props.applyFilter(searchText)}
          size="large"
        >
          {props.icon ?? <Search />}
        </IconButton>
      )}
    </Stack>
  );
};

export default WGRKurztextFilter;
