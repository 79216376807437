import HttpService from "App/service/http.service";
import Config from "Config";
import ModalController from "global/hook/modal/interface/ModalController";
import ClearingfehlerDTO from "./dto/ClearingfehlerDTO";
import { Clearingfehler } from "./interface/Clearingfehler";

const ClearingfehlerService = {
  getClearingfehlerForAutomat: (
    automatKey: String,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "clearingfehler/" + automatKey)
      .then((response) => {
        callbackOnSuccess(
          ClearingfehlerDTO.convertResponseToClearingfehlerResultDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) {
          callbackOnFail(error);
        } else {
          console.warn(error);
          // @TODO fire error message
        }
      });
  },
  updateClearingfehlerForAutomat: (
    clearingfehler: Clearingfehler,
    callbackOnSuccess: Function,
    callbackOnFail?: Function,
    modalController?: ModalController
  ) => {
    HttpService.api
      .sendJsonDataPost(
        Config.dataServiceUrl + "clearingfehler/updateErledigt",
        clearingfehler
      )
      .then((response) => {
        callbackOnSuccess(
          ClearingfehlerDTO.convertResponseToClearingfehlerDTO(response)
        );
        if (modalController) {
          modalController.showMessageDialog(
            "Der erledigt-Status wurde erfolgreich aktualisiert!"
          );
        }
      })
      .catch((error) => {
        if (callbackOnFail) {
          callbackOnFail(error);
        }
        console.warn(error);
      });
  },
};

export default ClearingfehlerService;
