import DateUtils from "global/util/DateUtils";
import { MutableRefObject } from "react";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";

export default function clearUpdateValues(
  updateValues: MutableRefObject<AutomatenUpdateRequest>,
  automatenblattInformation: AutomatenblattInformation
) {
  // Change the automatKey on each Tab switch
  updateValues.current = generateDefaultUpdateValues(automatenblattInformation);
}

export function generateDefaultUpdateValues(
  automatenblattInformation: AutomatenblattInformation
): AutomatenUpdateRequest {
  return {
    // AutomatKey must change on each Tab switch
    automatKey: automatenblattInformation.automatKey,
    // Von and Bis needs to be set because the API will delete if no values are sent at update process.
    ruhtBis:
      DateUtils.formatDateToAPIDateString(automatenblattInformation.ruhtBis) ??
      "",
    ruhtVon:
      DateUtils.formatDateToAPIDateString(automatenblattInformation.ruhtVon) ??
      "",
    // Same goes for automatStatusGueltigVonInZukunft. If it is null, it's deleted by the backend.
  };
}
