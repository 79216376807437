import { ColDef } from "ag-grid-community";

const ErreichbarkeitColumnDefs: Array<ColDef> = [
  {
    headerName: "SerienNr.",
    field: "serienNr",
    minWidth: 100,
  },
  {
    headerName: "Name",
    field: "rnsName",
    minWidth: 100,
  },
  {
    headerName: "Ort",
    field: "rnsOrt",
    minWidth: 100,
  },
  {
    headerName: "RNS-GLN",
    field: "rnsGln",
    minWidth: 130,
  },
  {
    headerName: "Region",
    field: "region",
    minWidth: 60,
    maxWidth: 90,
  },
  {
    headerName: "Hersteller",
    field: "herstellerKuerzel",
    minWidth: 90,
  },
  {
    headerName: "Modell",
    field: "model",
    minWidth: 90,
  },
  {
    headerName: "Warnung",
    field: "warning",
    minWidth: 60,
  },
  {
    headerName: "SSID",
    field: "automatSchluesselId",
    minWidth: 100,
  },
  {
    headerName: "Durchschnitt",
    field: "erreichbarkeitDurchschnitt",
    minWidth: 80,
    valueFormatter: (params) => {
      return concatProcent(params.value);
    },
  },
  {
    headerName: "MM",
    field: "erreichbarkeitAtMm",
    minWidth: 70,
    valueFormatter: (params) => {
      return concatProcent(params.value);
    },
  },
  {
    headerName: "Art.Stamm",
    field: "erreichbarkeitCdStd",
    minWidth: 80,
    valueFormatter: (params) => {
      return concatProcent(params.value);
    },
  },
  {
    headerName: "Quit",
    field: "erreichbarkeitCdMmQuit",
    minWidth: 70,
    valueFormatter: (params) => {
      return concatProcent(params.value);
    },
  },
  {
    headerName: "LOG",
    field: "erreichbarkeitAtLog",
    minWidth: 70,
    valueFormatter: (params) => {
      return concatProcent(params.value);
    },
  },
  {
    headerName: "DPG-BTB",
    field: "erreichbarkeitAtDpgBtb",
    minWidth: 90,
    valueFormatter: (params) => {
      return concatProcent(params.value);
    },
  },
  {
    headerName: "EDK-BTB",
    field: "erreichbarkeitAtEdkBtb",
    minWidth: 90,
    valueFormatter: (params) => {
      return concatProcent(params.value);
    },
  },
  {
    headerName: "Daten vom",
    field: "loadDateFormatted",
    minWidth: 160,
  },
];

function concatProcent(value: string) {
  return value != null ? `${value}%` : "";
}

export default ErreichbarkeitColumnDefs;
