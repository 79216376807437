import AutomatenblattInformationService from "service/data-service/automate-controller/Automatenblatt.service";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_AUTOMATTENBLATT_INFORMATION =
  "FETCH_AUTOMATTENBLATT_INFORMATION";

const configureAutomatenblatInformationStore = () => {
  const fetchInfo: AutomatAction = {
    identifier: FETCH_AUTOMATTENBLATT_INFORMATION,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      AutomatenblattInformationService.retrieveAutomatenInformation(
        payload.automatKey,
        (infoDTO: AutomatenblattInformation) =>
          callback({
            automatenblattInformation: infoDTO,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([fetchInfo]);
};

export default configureAutomatenblatInformationStore;
