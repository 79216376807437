import { ColDef } from "ag-grid-community";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

const RegionSelectionColumnDefs: Array<ColDef> = [
  {
    headerName: "Übernehmen",
    field: "glnBtn",
    minWidth: 140,
    maxWidth: 140,
    cellStyle: {
      paddingTop: "1px",
      justifyContent: "center",
    },
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: "Rücknahmestelle",
    field: "rnsName",
    minWidth: 300,
  },
  {
    headerName: "PLZ",
    field: "rnsPlz",
    minWidth: 70,
  },
  {
    headerName: "Ort",
    field: "rnsOrt",
    minWidth: 160,
  },
  {
    headerName: "Strasse",
    field: "rnsStrasse",
    minWidth: 200,
  },
  {
    headerName: "RNS-GLN",
    field: "rnsGln",
    minWidth: 130,
  },
  {
    headerName: "Automatstatus",
    field: "automatStatus",
    minWidth: 140,
  },
  {
    headerName: "Rechnungsempfaenger",
    field: "nameRechnungsEmpfaenger",
    minWidth: 300,
  },
  {
    headerName: "RN-GLN",
    field: "rnGln",
    minWidth: 130,
  },
  {
    headerName: "Region",
    field: "region",
    minWidth: 90,
  },
];

export default RegionSelectionColumnDefs;
