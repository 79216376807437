import { EpcomResponse } from "App/service/http.service";
import ErrorTypeDTO from "service/data-service/error-typ-controller/interface/ErrorType";
import SapOutputParam from "../interface/SapOutputParam";
import UnterWarengruppe from "../interface/UnterWarengruppe";
import Warengruppe from "../interface/Warengruppe";

const SapDTO = {
  convertSapOutputResponseDTO: (response: EpcomResponse): SapOutputParam => {
    if (response.data) {
      return {
        anlagen: response.data.anlagen,
        cntDoublettenDb: response.data.cntDoublettenDb,
        cntImported: response.data.cntImported,
        cntMovedToPostion: response.data.cntMovedToPostion,
        cntNotForRegion: response.data.cntNotForRegion,
        cntPositions: response.data.cntPositions,
        cntPositionsWithoutWarengruppe:
          response.data.cntPositionsWithoutWarengruppe,
        doublettenCheckDb: response.data.doublettenCheckDb,
        errorTyp: response.data.errorTyp,
        excelDTO: response.data.excelDTO,
        exportPositions: response.data.exportPositions,
        importPositions: response.data.importPositions,
        message: response.data.message,
        page: response.data.page,
        pageSize: response.data.pageSize,
        positionEdits: response.data.positionEdits,
        rnGln: response.data.rnGln,
        rnsGln: response.data.rnsGln,
        rns: response.data.ruecknahmeStelle,
      };
    }
    return {} as SapOutputParam;
  },
  convertUnterWarengruppeItemsDTO: (
    response: EpcomResponse
  ): Array<UnterWarengruppe> => {
    if (response.data) {
      return response.data.map((e: UnterWarengruppe) => {
        return {
          ...e,
          key: e.id,
        };
      });
    }
    return [];
  },
  convertUnterWarengruppeResponseDTO: (
    response: EpcomResponse
  ): ErrorTypeDTO => {
    return {
      key: response.data.errorTyp,
      value: response.data.message,
    } as ErrorTypeDTO;
  },
  convertWarengruppeResponseDTO: (
    response: EpcomResponse
  ): Array<Warengruppe> => {
    if (response.data) {
      return response.data.map((e: Warengruppe) => {
        return {
          ...e,
          key: e.wgrNr,
        };
      });
    }
    return [];
  },
};
export default SapDTO;
