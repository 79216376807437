import { ColDef } from "ag-grid-community";
import {
  CronJobsCellValueRenderer,
  DateCellValueRenderer,
  DefaultCellValueRenderer,
  StatusCellValueRenderer,
} from "./grid-column-renderer";

const JobMonitoringColumnDefs: Array<ColDef> = [
  {
    headerName: "Berechneter Status",
    field: "calculatedJobStatus",
    minWidth: 215,
    maxWidth: 350,
    cellRenderer: StatusCellValueRenderer,
  },
  {
    headerName: "Jobname",
    field: "cronJobName",
    minWidth: 280,
    cellRenderer: DefaultCellValueRenderer,
  },
  {
    headerName: "Geplante Ausführungszeit",
    field: "plannedExecution",
    minWidth: 150,
    maxWidth: 240,
    cellRenderer: DateCellValueRenderer,
  },
  {
    headerName: "Tatsächliche Ausführungszeit",
    field: "realExecution",
    minWidth: 150,
    maxWidth: 240,
    cellRenderer: DateCellValueRenderer,
  },
  {
    headerName: "Ausführungszeiten",
    field: "cron",
    minWidth: 300,
    cellRenderer: CronJobsCellValueRenderer,
  },
];

export default JobMonitoringColumnDefs;
