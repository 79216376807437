import HttpService from "App/service/http.service";
import Config from "Config";
import Row from "page/Automatensuche/interface/RowTypes";
import DepositMachineFilter from "./interface/FilterObject";

const DepositMachineDataService = {
  retrieveDepositMachineData: (
    filterObject: DepositMachineFilter,
    callbackOnSuccess: (data: Array<Row>) => void,
    callbackOnFail?: (error: any) => void
  ) => {
    HttpService.api
      .sendJsonDataPost(Config.dataServiceUrl + "automate/search", filterObject)
      .then((response) => {
        callbackOnSuccess(response.data);
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default DepositMachineDataService;
