import HttpService, { EpcomResponse } from "App/service/http.service";
import Config from "Config";
import fileDownload from "js-file-download";
import SapDTO from "./dto/SapDTO";
import SapInputParam from "./interface/SapInputParam";

const basePath = "anlagen/";

const AnlagenService = {
  getAnlagenListe: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(
        `${Config.sapServiceUrl}${basePath}getAnlagenListe`,
        filter
      )
      .then((response: EpcomResponse) => {
        callbackOnSuccess(SapDTO.convertSapOutputResponseDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  downloadAnlagen: (
    regulierungsBeleg: string,
    anlage: string,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.sapServiceUrl}${basePath}downloadAnlagen`, {
        responseType: "blob",
        params: {
          regulierungsBeleg: regulierungsBeleg,
          anlage: anlage,
        },
      })
      .then((response: EpcomResponse) => {
        fileDownload(response.data, `${anlage}`, "application/octet-stream");
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default AnlagenService;
