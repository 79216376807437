import {
  Box,
  Breakpoint,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";

import "./Dialogs.scss";

export interface ModalMessageDialogProps {
  message: string;
  nonInteractiveMessage?: string;
  title?: string;
  content?: ReactNode;
  onOkClick?: Function;
  okCaption?: string;
  okIcon?: ReactNode;
  okActionDisabled?: boolean;
  onAbortClick?: Function;
  abortCaption?: string;
  abortIcon?: ReactNode;
  maxWidth?: Breakpoint | false;
}

const ModalMessageDialog: React.FC<ModalMessageDialogProps> = (
  props: ModalMessageDialogProps
) => {
  const singleAction =
    (props.onOkClick && !props.onAbortClick) ||
    (props.onAbortClick && !props.onOkClick);
  const dialogActionsClassName = singleAction
    ? "actionButtonContainer"
    : "actionsButtonContainer";
  return (
    <Dialog open maxWidth={props.maxWidth ?? "sm"}>
      {props.title && (
        <DialogTitle>
          <Typography className="dialogTitle">{props.title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        {props.content && <DialogContent> {props.content} </DialogContent>}
        {!props.content && !props.nonInteractiveMessage && (
          <DialogContentText>
            <pre>{props.message}</pre>
          </DialogContentText>
        )}
        {!props.content && props.nonInteractiveMessage && (
          <DialogContent>
            {" "}
            <Stack className="modalMessageDialog__nonInteractiveContainer">
              <CircularProgress />
              <DialogContentText ml={2}>
                {props.nonInteractiveMessage}
              </DialogContentText>
            </Stack>{" "}
          </DialogContent>
        )}
      </DialogContent>
      <DialogActions className={dialogActionsClassName}>
        {props.onOkClick && (
          <Button
            id="modalDialogActionButton"
            startIcon={props.okIcon}
            variant="contained"
            disabled={props.okActionDisabled}
            onClick={() => props.onOkClick!()}
          >
            {props.okCaption ?? "OK"}
          </Button>
        )}
        {props.onAbortClick && (
          <Button
            id="modalDialogAbortButton"
            startIcon={props.abortIcon}
            onClick={() => props.onAbortClick!()}
            sx={{ display: "inline" }}
          >
            {props.abortCaption ?? "Abbrechen"}
          </Button>
        )}
      </DialogActions>
      {props.content && props.nonInteractiveMessage && (
        <Box className="modalMessageDialog__nonInteractiveOverlay">
          <Stack className="modalMessageDialog__nonInteractiveContainer">
            <CircularProgress />
            <DialogContentText ml={2}>
              {props.nonInteractiveMessage}
            </DialogContentText>
          </Stack>
        </Box>
      )}
    </Dialog>
  );
};

export default ModalMessageDialog;
