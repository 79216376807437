import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";

export const WGR_SEARCH: EnumeratorItem = {
  id: 0,
  value: "Suchen",
  source: undefined,
};
export const WGR_OHNE_WGR = {
  id: 1,
  value: "Ohne WGR",
  source: undefined,
};
export const WGR_MIT_WGR = {
  id: 2,
  value: "Mit WGR",
  source: undefined,
};

const WGRZuordnungWorkAreaEnumerator: Enumerator = {
  items: [WGR_SEARCH, WGR_OHNE_WGR, WGR_MIT_WGR],
} as Enumerator;

export const WGRZuordnungMitWGRCaption =
  "Rechnungsdaten ATH - Positionen mit WGR";
export const WGRZuordnungOhneWGRCaption =
  "Rechnungsdaten ATH - Positionen ohne WGR";

export default WGRZuordnungWorkAreaEnumerator;
