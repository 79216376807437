import { createContext, ReactNode, useEffect, useState } from "react";
import Access, {
  generateDefaultAccess,
} from "service/data-service/access-controller/interface/Access";
import AccessController from "./AccessController";

interface IAccessContext {
  getAccessContainer: () => Access;
}

export const AccessContext = createContext<IAccessContext>({
  getAccessContainer: () => generateDefaultAccess(),
});

const AccessProvider = (props: {
  children: ReactNode | ReactNode[];
  restInterceptorsReady: boolean;
}) => {
  const [accessContainer, setAccessContainer] = useState<Access>(
    generateDefaultAccess()
  );

  useEffect(() => {
    if (props.restInterceptorsReady) {
      const access = AccessController.getAccess();
      if (access instanceof Promise) {
        access.then(setAccessContainer);
      } else {
        setAccessContainer(access);
      }
    }
  }, [props.restInterceptorsReady]);

  return (
    <AccessContext.Provider
      value={{
        getAccessContainer: () => accessContainer,
      }}
    >
      {props.children}
    </AccessContext.Provider>
  );
};

export default AccessProvider;
