import {
  Autocomplete,
  AutocompleteProps,
  Stack,
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";
import { Optional } from "global/util/interface/MappedTypesTransform";
import React, { useEffect, useState } from "react";
import "../../ui.scss";

interface SelectBoxProps {
  label: string;
  placeholder?: string;
  enumerator: Enumerator;
  id: string;
  enableClearable?: boolean;
  reset?: boolean;
  required?: boolean;
  selection?: EnumeratorItem;
  getSelectedValue?: (value: EnumeratorItem | null) => void;
  AutocompleteProps?: Optional<
    AutocompleteProps<EnumeratorItem, undefined, boolean, undefined>
  >;
  TypographyProps?: TypographyProps;
  className?: string;
}

const SelectBox: React.FC<SelectBoxProps> = (props: SelectBoxProps) => {
  const { enumerator, reset } = props;

  const [value, setValue] = useState(props.selection ?? null);

  const selectionChangeHandler = (
    event: any,
    newValue: EnumeratorItem | null
  ): void => {
    setValue(newValue);
    if (props.getSelectedValue) props.getSelectedValue(newValue);
  };

  useEffect(() => {
    setValue(props.selection ?? null);
  }, [props.selection, reset, setValue]);

  return (
    <Stack
      className={`epcom__SelectBox ${props.className ?? ""}`}
      columnGap={2}
    >
      {props.label && (
        <Typography id={`${props.id}-label`} {...props.TypographyProps}>
          {props.label}
        </Typography>
      )}
      <Autocomplete
        id={`${props.id}-autocomplete`}
        value={value}
        disableClearable={!props.enableClearable}
        onChange={selectionChangeHandler}
        options={enumerator.items}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={props.required}
              size={"small"}
              placeholder={props.placeholder ?? "Bitte Wählen"}
            />
          );
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        {...props.AutocompleteProps}
      />
    </Stack>
  );
};

export default SelectBox;
