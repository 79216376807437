import HttpService from "App/service/http.service";
import Config from "Config";
import JobMonitoringHistoryRequestBody from "./interface/JobMonitoringHistoryRequestBody";

const JobMonitoringService = {
  retrieveJobMonitorData: (
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}jobs/`)
      .then((response) => {
        if (response.data && response.data !== null) {
          callbackOnSuccess(response.data);
          // callbackOnSuccess(jobs_data);
        } else {
          throw new Error("No results");
        }
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  retrieveJobMonitoringHistoryData: (
    requestBody: JobMonitoringHistoryRequestBody,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(`${Config.dataServiceUrl}jobs/search`, requestBody)
      .then((response) => {
        if (response.data && response.data !== null) {
          callbackOnSuccess(response.data);
        } else {
          throw new Error("No results");
        }
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  retrieveGeneralJobMonitoringInformation: (
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}jobs/info`)
      .then((response) => {
        if (response.data && response.data !== null) {
          callbackOnSuccess(response.data);
        } else {
          throw new Error("No results");
        }
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default JobMonitoringService;
