import { Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { pageStyles } from "../PdfSteckbriefContent";

interface props {
  header: string;
  info: string;
  textStyle?: Style;
  itemKey?: string;
}

const PdfRowTextItem = (props: props) => {
  return (
    <View style={pageStyles.contentRow}>
      <Text key={`${props.itemKey} - header`} style={pageStyles.itemHeader}>
        {props.header}
      </Text>
      <View style={pageStyles.itemInfo}>
        <Text key={`${props.itemKey} - info`} style={props.textStyle}>
          {props.info}
        </Text>
      </View>
    </View>
  );
};

export default PdfRowTextItem;
