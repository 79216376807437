import { Stack } from "@mui/material";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import { FETCH_AUTOMATTENBLATT_INFORMATION } from "global/hook/datastore/automat/automatenblatt-information-store";
import { CLEAR_CHANGES } from "global/hook/datastore/automat/change-tracker-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useModalDialog } from "global/hook/modal/use-modals";
import { useEffect, useState } from "react";
import AutomatenblattInformationService from "service/data-service/automate-controller/Automatenblatt.service";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import "./automatenblatt.scss";
import AutomatDetailPanelContainer from "./components/AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import AutomatSheet from "./components/AutomatSheet/AutomatSheet";
import {
  validateUserEnteredValues,
  wrapUpdateValuesForRequest,
} from "./components/AutomatSheet/script/validateUdpateValues";

interface AutomatenblattProps {
  automatKey: string;
}

export const Automatenblatt: React.FC<AutomatenblattProps> = (
  props: AutomatenblattProps
) => {
  const [modal, dialogController] = useModalDialog("on-automat-save-dialog");
  const automatKey: string = props.automatKey;

  const [automatData, automatDataDispatch] = useAutomatData(props.automatKey);

  const [resetValues, setResetValues] = useState<boolean>(false);

  const sendUpdates = (changeTrackerUpdates: AutomatenUpdateRequest) => {
    AutomatenblattInformationService.update(changeTrackerUpdates, () => {
      automatDataDispatch(FETCH_AUTOMATTENBLATT_INFORMATION, {
        clearChanges: true,
      } as AutomatActionPayload);
    });
  };

  function updateAutomat() {
    if (validateUserEnteredValues(automatData, dialogController)) {
      const newUpdates = wrapUpdateValuesForRequest(automatData);
      if (
        newUpdates.rnsBonNummer &&
        !newUpdates.rnsBonNummerInfo &&
        automatData.automatenblattInformation.sapBonNummerDTO.info
      ) {
        dialogController.showDialog({
          message: "Soll der Text zur neuen SAP Bonnummer übernommen werden?",
          onOkClick: () => {
            newUpdates.rnsBonNummerInfo =
              automatData.automatenblattInformation.sapBonNummerDTO.info!;
            sendUpdates(newUpdates);
          },
          okCaption: "JA",
          onAbortClick: () => {
            newUpdates.rnsBonNummerInfo = "";
            sendUpdates(newUpdates);
          },
          abortCaption: "Nein",
        });
      } else {
        sendUpdates(newUpdates);
      }
    }
  }

  // Clear changes, when "Verwerfen" is pushed or the Tab is switched
  useEffect(() => {
    automatDataDispatch(CLEAR_CHANGES); // eslint-disable-next-line
  }, [resetValues]);

  return (
    <>
      <Stack className="automatenblattContainer">
        <AutomatSheet
          automatKey={props.automatKey}
          resetValues={resetValues}
          onReset={setResetValues}
          onSave={updateAutomat}
        />

        <AutomatDetailPanelContainer
          automatKey={automatKey}
          resetValues={resetValues}
          onSave={updateAutomat}
        />
      </Stack>
      {modal && <ModalMessageDialog {...modal} />}
    </>
  );
};
