import { EpcomResponse } from "App/service/http.service";
import DateUtils from "global/util/DateUtils";
import EditHistory from "../interface/EditHistory";

const EditHistoryDTO = {
  convertEditHistoryResponse: (response: EpcomResponse): Array<EditHistory> => {
    const dto = [] as Array<EditHistory>;
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach((editHistorie: EditHistory) => {
        dto.push({
          ...editHistorie,
          createdString: DateUtils.optionalTimeArrayToGermanString(
            editHistorie.created
          ),
          gueltigVonString: DateUtils.optionalTimeArrayToGermanString(
            editHistorie.gueltigVon
          ),
        });
      });
    }
    return dto;
  },
};
export default EditHistoryDTO;
