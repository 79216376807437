export const noop = () => {};

export function hasChildren(item: any) {
  const { children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export function capitalizeFirstLetterRestLowercase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function toLowerCaseReplaceUnderscoreWithSpace(string: string) {
  return string.toLowerCase().replaceAll("_", " ");
}

export function returnUndefinedIfEmptyString(value: string) {
  return value === "" ? undefined : value;
}

export function returnNullIfEmptyString(value: string) {
  return value === "" ? null : value;
}

export function returnUndefinedIfOnlyWhitespace(stringToCheck?: string) {
  if (stringToCheck) {
    return returnUndefinedIfEmptyString(stringToCheck.trim());
  }
  return undefined;
}

export function isFalsyOrEmptyString(value: string | undefined | null) {
  return value === null || value === undefined || value.trim() === "";
}

export function validateEmail(email: string) {
  return email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export function validateMacAddress(macAddress: string) {
  return macAddress.match(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/);
}

export function trimDataValues(record: any, overwriteUndefined?: boolean) {
  if (!record) return overwriteUndefined ? "" : record;
  //
  const recordTypeName = typeof record;
  if (recordTypeName === "string") return record.trim();
  if (
    recordTypeName === "number" ||
    recordTypeName === "boolean" ||
    recordTypeName === "function"
  )
    return record;
  // we have an object
  if (record.constructor === Date) {
    return record;
  }
  let newRecord = record.constructor === Array ? [...record] : { ...record };
  Object.entries(record).forEach(([key, value]) => {
    newRecord[key] = trimDataValues(value, overwriteUndefined);
  });

  return newRecord;
}

export function trimParamValues(params: any) {
  if (!params) return params;
  return trimDataValues(params, true);
}
