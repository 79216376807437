import { ColDef } from "ag-grid-community";

const LogfilePanelColumnDefs: Array<ColDef> = [
  {
    headerName: "Datum",
    field: "datum",
    minWidth: 160,
    maxWidth: 160,
    cellClass: "ag-cell-left-justified-content",
  },
  {
    headerName: "Ereignis",
    field: "text",
    minWidth: 230,
    cellClass: "ag-cell-left-justified-content",
  },
  {
    headerName: "Suchbegriff",
    field: "kategorie",
    minWidth: 250,
  },
];

export default LogfilePanelColumnDefs;
