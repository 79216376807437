import { Button, Stack } from "@mui/material";
import SapOutputParam, { PositionDTO } from "service/sap-service/interface/SapOutputParam";

export interface SeriennrListElement {
    status: string,
    seriennrBtn: React.ReactNode
}

const SeriennrListController = {
    convertResponse(response: SapOutputParam, onSeriennrSelected: Function): Array<SeriennrListElement> {
        let records: Array<SeriennrListElement> = [];

        response.positionEdits.forEach((position: PositionDTO) => {
            records.push({
                status: position.automatStatus,
                seriennrBtn: this.convertToButton(position.serienNrEpcom, () => {
                    onSeriennrSelected(position.serienNrEpcom)
                })
            })
        });
        return records;
    },
    convertToButton(
        caption: string | null,
        action: Function,
    ): React.ReactNode {
        if (!caption) {
            return null;
        }
        return (
            <Stack direction="row" justifyContent="center" width={"100%"} >
                <Button
                    className="fillAvailable"
                    variant="contained"
                    onClick={() => {
                        action()
                    }}>

                    {caption}
                </Button>
            </Stack>
        );
    }
};

export default SeriennrListController;
