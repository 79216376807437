import { EpcomResponse } from "App/service/http.service";
import EdekaOrganisation from "../interface/EdekaOrganisation";

const EdekaOrg = {
  convertEdekaOrgResponseDTO: (response: EpcomResponse): EdekaOrganisation => {
    if (response.data) {
      return {
        ...response.data,
      };
    }
    return {} as EdekaOrganisation;
  },
};
export default EdekaOrg;
