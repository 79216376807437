import { Card, CardHeader, List } from "@mui/material";
import { useCockpitInfo } from "global/hook/cockpit/use-cockpit-info";
import { HREF_BERICHTE_ZURBEARBEITUNG } from "global/util/routes";
import { ColoredListItemTypography } from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";

const Fehleranzeige: React.FC = () => {
  const cockpitInfo = useCockpitInfo()[0];
  // Variablen und Hooks
  const [countOfDataError, setCountDataError] = useState(Number);
  const [countOfClearingError, setCountOfClearingError] = useState(Number);
  const [countOfLogfileError, setCountOfLogfileError] = useState(Number);

  const [dataErrorVisible, setDataErrorVisible] = useState(Boolean);
  const [clearingErrorVisible, setClearingErrorVisible] = useState(Boolean);
  const [logfileErrorVisible, setlogFileErrorVisible] = useState(Boolean);
  let navigate = useNavigate();

  useEffect(() => {
    setCountDataError(
      cockpitInfo.cockpitInformationDTO?.zurBearbeitungAnstehendStammdaten ?? 0
    );
    setCountOfClearingError(
      cockpitInfo.cockpitInformationDTO?.zurBearbeitungAnstehendClearing ?? 0
    );
    setCountOfLogfileError(
      cockpitInfo.cockpitInformationDTO?.zurBearbeitungAnstehendLogfile ?? 0
    );

    setDataErrorVisible(
      cockpitInfo.visibilities?.zurBearbeitungAnstehendStammdatenShow !==
        undefined
        ? cockpitInfo.visibilities?.zurBearbeitungAnstehendStammdatenShow
        : false
    );
    setClearingErrorVisible(
      cockpitInfo.visibilities?.zurBearbeitungAnstehendClearingShow !==
        undefined
        ? cockpitInfo.visibilities?.zurBearbeitungAnstehendClearingShow
        : false
    );
    setlogFileErrorVisible(
      cockpitInfo.visibilities?.zurBearbeitungAnstehendLogfileShow !== undefined
        ? cockpitInfo.visibilities?.zurBearbeitungAnstehendLogfileShow
        : false
    );
  }, [cockpitInfo]);

  function selectCardVisibility() {
    if (dataErrorVisible || clearingErrorVisible || logfileErrorVisible) {
      return false;
    } else {
      return true;
    }
  }

  // React-Tree
  return (
    <Card hidden={selectCardVisibility()}>
      <CardHeader title="Zur Bearbeitung anstehend" />
      <List disablePadding>
        <CockpitListItemButton
          sx={{ display: dataErrorVisible ? "flex" : "none" }}
          aria-label="Stammdatenfehler"
          onClick={() => {
            navigate(HREF_BERICHTE_ZURBEARBEITUNG);
          }}
        >
          <CockpitListItemText primary="Stammdatenfehler: " />
          <ColoredListItemTypography
            colorChangeBool={countOfDataError <= 0}
            text={`${countOfDataError}`}
            id="countOfDataError"
          />
        </CockpitListItemButton>

        <CockpitListItemButton
          sx={{ display: clearingErrorVisible ? "flex" : "none" }}
          aria-label="Clearingfehler"
          onClick={() => {
            navigate(HREF_BERICHTE_ZURBEARBEITUNG);
          }}
        >
          <CockpitListItemText primary={"Clearingfehler: "} />
          <ColoredListItemTypography
            colorChangeBool={countOfClearingError <= 0}
            text={`${countOfClearingError}`}
            id="countOfClearingError"
          />
        </CockpitListItemButton>

        <CockpitListItemButton
          sx={{ display: logfileErrorVisible ? "flex" : "none" }}
          aria-label="Logfilefehler"
          onClick={() => {
            navigate(HREF_BERICHTE_ZURBEARBEITUNG);
          }}
        >
          <CockpitListItemText primary="Logfilefehler: " />
          <ColoredListItemTypography
            colorChangeBool={countOfLogfileError <= 0}
            text={`${countOfLogfileError}`}
            id="countOfLogfileError"
          />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Fehleranzeige;
