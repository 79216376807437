import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { SnackbarAction, SnackbarKey, useSnackbar } from "notistack";
import { ReactNode } from "react";

interface SnackbarCloseButtonProps {
  snackbarKey: SnackbarKey;
}

const SnackbarCloseButton: React.FC<SnackbarCloseButtonProps> = (
  props: SnackbarCloseButtonProps
) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      disableRipple
      className="snackbar-close-button"
      onClick={() => closeSnackbar(props.snackbarKey)}
    >
      <CloseIcon />
    </IconButton>
  );
};

export const snackbarCloseAction: SnackbarAction = (
  sKey: SnackbarKey
): ReactNode => {
  return <SnackbarCloseButton snackbarKey={sKey} />;
};

export default SnackbarCloseButton;
