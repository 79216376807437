interface JobInformation {
    readonly cronJobName: string;
    readonly plannedExecution: string;
    readonly realExecution: string;
    readonly jobStatus: JobStatus;
    calculatedJobStatus?: JobStatus;
    category?: JobCategory;
    cron: string;
    info: string;
}

export enum JobStatus {
    RUNNING = "RUNNING",
	FAILURE = "FAILURE", 
	SUCCESS = "SUCCESS",
    READY = "READY",
    DISABLED = "DISABLED",
    NOT_YET_RUN = "NOT_YET_RUN",
    NOT_MAPPED = "NOT_MAPPED"
}

export enum JobCategory {
    disabledJobs = "disabledJobs",
    failedJobs = "failedJobs",
    notAsPlannedRunningJobs = "notAsPlannedRunningJobs",
    notMappedJobs = "notMappedJobs",
    stillRunningJobs = "stillRunningJobs",
    successfulJobs = "successfulJobs"
}

export default JobInformation;

