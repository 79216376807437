import { Button, Stack } from "@mui/material";
import SapOutputParam, { NameValue } from "service/sap-service/interface/SapOutputParam";

export interface AnlageListElement {
    anlageBtn: React.ReactNode
}

const AnlageListController = {
    convertResponseAnlage(response: SapOutputParam, onAnlageSelected: Function): Array<AnlageListElement> {
        let records: Array<AnlageListElement> = [];
        records.push({
            anlageBtn: this.convertToButton('Alle', () => {
                onAnlageSelected('Alle')
            })
        })
        response.anlagen.forEach((anlage: NameValue) => {
            records.push({
                anlageBtn: this.convertToButton(anlage.value, () => {
                    onAnlageSelected(anlage.value)
                })
            })
        });
        return records;
    },
    convertToButton(
        caption: string | null,
        action: Function,
    ): React.ReactNode {
        if (!caption) {
            return null;
        }
        return (
            <Stack direction="row" justifyContent="center" width={"100%"} >
                <Button
                    className="fillAvailable"
                    variant="contained"
                    onClick={() => {
                        action()
                    }}>

                    {caption}
                </Button>
            </Stack>
        );
    }
};

export default AnlageListController;
