const AutomatPanelRoutes = {
  RNS_PATH: "rns",
  DETAILS_PATH: "details",
  STATUS_PATH: "status",
  TK_PARTNER_PATH: "tk-partner",
  ABRUF_PATH: "abruf",
  DPG_STAMMDATEN_PATH: "dpg-stammdaten",
  CLEARINGFEHLER_PATH: "clearingfehler",
  NOTIZEN_PATH: "notizen",
  VERTRAGE_PATH: "vertrage",
  ARTIKELSUCHE_PATH: "artikelsuche",
  LOGFILE_PATH: "logfile",
  LOGFILEFEHLER_PATH: "logfilefehler",
  HISTORIE_PATH: "historie",
};

export default AutomatPanelRoutes;
