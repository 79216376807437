import Enumerator from "global/components/EnumeratedValues/Enumerator";

const ZuordnenRegionEnumerator: Enumerator = {
  items: [
    { id: "HIDE", value: "Keine EPCOM-Region", source: undefined },
    { id: "HR", value: "HR", source: undefined },
    { id: "MH", value: "MH", source: undefined },
    { id: "NB", value: "NB", source: undefined },
    { id: "NO", value: "NO", source: undefined },
    { id: "RR", value: "RR", source: undefined },
    { id: "RRST", value: "RRST", source: undefined },
    { id: "SB", value: "SB", source: undefined },
    { id: "SW", value: "SW", source: undefined },
    { id: "NETT", value: "Netto", source: undefined },
  ],
} as Enumerator;

export default ZuordnenRegionEnumerator;
