import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

function AutomatendatenGt30ColumnDefs(delta: number): Array<ColDef> {
  return [
    {
      headerName: "SerienNr.",
      field: "serial",
      minWidth: 100,
    },
    {
      headerName: "Kommunikationsart",
      field: "kommType",
      minWidth: 140,
    },
    {
      headerName: "RNS GLN",
      field: "rnsGln",
      minWidth: 120,
    },
    {
      headerName: "Region",
      field: "region",
      minWidth: 50,
    },
    {
      headerName: "Hersteller",
      field: "hersteller",
      minWidth: 50,
    },

    ...getHeaders(delta),
  ];
}

function getHeaderDate(delta?: number): string {
  const date = new Date();
  date.setDate(date.getDate() - (delta ?? 0));
  return DateUtils.formatDateToAPIDateString(date, "dd.MM.yyyy") ?? "";
}

function getHeaders(delta: number): Array<ColDef> {
  const headers: Array<ColDef> = [];
  for (let index = 0; index <= delta; index++) {
    headers.push({
      headerName: getHeaderDate(index),
      field: "data",
      minWidth: 90,
      maxWidth: 110,
      valueFormatter: (params) => params.value[`PastDay${index}`] ?? "",
    });
  }
  return headers;
}

export default AutomatendatenGt30ColumnDefs;
