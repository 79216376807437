import HttpService, { EpcomResponse } from "App/service/http.service";
import Config from "Config";
import AutomatCheckDTO from "./dto/AutomatCheckDTO";

export enum AUTOMAT_CHECK_ACTION {
  "check",
  "result",
  "abholung",
}

const AutomatCheckService = {
  check: (
    checkAction: AUTOMAT_CHECK_ACTION,
    automateKey: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(
        Config.dataServiceUrl +
          "check/automate/" +
          AUTOMAT_CHECK_ACTION[checkAction] +
          "/" +
          automateKey
      )
      .then((response: EpcomResponse) => {
        callbackOnSuccess(
          AutomatCheckDTO.convertResponseToAutomatCheckDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default AutomatCheckService;
