import { ColDef } from "ag-grid-community";
import {
  FormatDateTimeCellValueRenderer,
  JobNameCellValueRenderer,
  StatusCellValueRenderer,
} from "./grid-column-renderer";

const JobMonitoringHistoryColumnDefs: Array<ColDef> = [
  {
    headerName: "Status",
    field: "status",
    minWidth: 150,
    maxWidth: 350,
    cellRenderer: StatusCellValueRenderer,
  },
  {
    headerName: "Jobname",
    field: "typ",
    minWidth: 320,
    maxWidth: 450,
    cellRenderer: JobNameCellValueRenderer,
  },
  // {
  //   headerName: "Datum",
  //   field: "datumDate",
  //   minWidth: 120,
  //   maxWidth: 240,
  //   cellRenderer: FormatDateCellValueRenderer
  // },
  {
    headerName: "Gestartet",
    field: "gestartetDate",
    minWidth: 120,
    maxWidth: 240,
    cellRenderer: FormatDateTimeCellValueRenderer,
  },
  {
    headerName: "Beendet",
    field: "beendetDate",
    minWidth: 120,
    maxWidth: 240,
    cellRenderer: FormatDateTimeCellValueRenderer,
  },
];

export default JobMonitoringHistoryColumnDefs;
