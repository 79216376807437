import HttpService from "App/service/http.service";
import Config from "Config";
import ErrorTypeDTO from "./interface/ErrorType";
import ErrorTypeMapper from "./mapping/ErrorTypeMapper";

const ErrorTypeService = {
  getErrorTypes: (
    callbackOnSuccess: (errorTypes: Array<ErrorTypeDTO>) => void
  ) => {
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}errortyp/geterrortypes`)
      .then((response) => {
        callbackOnSuccess(ErrorTypeMapper.mapResponseToErrorTypeDTO(response));
      })
      .catch((error) => {
        console.log("ErrorTypeService.getErrorTypes", error);
      });
  },
};

export default ErrorTypeService;
