import { EpcomResponse } from "App/service/http.service";
import IpWhitelist from "../interface/IpWhitelist";

const IpWhitelistDTO = {
  convertListWhitelistResponse: (
    response: EpcomResponse
  ): Array<IpWhitelist> => {
    if (response.data) {
      return [...response.data];
    }
    return [] as IpWhitelist[];
  },
  convertWhitelistResponse: (response: EpcomResponse): IpWhitelist => {
    if (response.data) {
      return { ...response.data };
    }
    return {} as IpWhitelist;
  },
};
export default IpWhitelistDTO;
