import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import useModalController from "global/hook/modal/use-modals";
import IpWhitelist from "service/logfile-service/interface/IpWhitelist";
import IpWhitelistService from "service/logfile-service/Whitelist.service";

export interface IpWhitelistGridActionsProps {
  record?: IpWhitelist;
  reloadTrigger: Function;
  showAddIpWhitelistDialog: Function;
}

const IpWhitelistGridActions = (props: IpWhitelistGridActionsProps) => {
  const modals = useModalController();
  return (
    <>
      <Button
        className="dialogButton"
        size="small"
        variant="outlined"
        startIcon={<AddCircleOutline color="success" />}
        onClick={() => {
          props.showAddIpWhitelistDialog();
        }}
      >
        Eintrag hinzufügen
      </Button>
      <Button
        className="dialogButton"
        size="small"
        variant="outlined"
        disabled={!props.record}
        startIcon={
          <RemoveCircleOutline color={props.record ? "error" : "disabled"} />
        }
        onClick={() => {
          IpWhitelistService.delete(
            props.record!.id,
            (response: IpWhitelist) => {
              props.reloadTrigger();
              modals.showMessageDialog(
                "Der Eintrag wurde erfolgreich gelöscht.",
                undefined,
                "Erfolg"
              );
            }
          );
        }}
      >
        Eintrag löschen
      </Button>
    </>
  );
};

export default IpWhitelistGridActions;
