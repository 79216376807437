import { StyleSheet, View } from "@react-pdf/renderer";
import KeyValue from "global/interface/KeyValue";
import { SteckbriefProps } from "./PdfSteckbriefContent";
import PdfRowItemHeader from "./component/PdfRowItemHeader";
import PdfRowKeyValuesItem from "./component/PdfRowKeyValuesItem";
import PdfRowTextItem from "./component/PdfRowTextItem";

export const styles = StyleSheet.create({
  keyContainer: {
    width: "45%",
  },
});

const PdfTechnicalDataItem = (props: SteckbriefProps) => {
  const data = props.steckbrief.technicheDaten;
  const modelData = `${data.hersteller}/${data.modell}`;
  const versionData: Array<KeyValue> = [
    { key: "ALE Version", value: data.alleVersion },
    { key: "ALE Software-Version", value: data.alleVersionSoftware },
    {
      key: "DSDM Software-Version",
      value: data.dsmdSoftwareVersion ?? props.steckbrief.undefinedValue,
    },
    {
      key: "DSMD Operation-Version",
      value: data.dsmdOperationVersion ?? props.steckbrief.undefinedValue,
    },
  ];

  return (
    <View>
      <PdfRowItemHeader mainInfo="Technische Daten"></PdfRowItemHeader>

      <PdfRowTextItem
        header="im DPG- Betrieb seit"
        info={data.betriebSeit}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Hersteller/Modell"
        info={modelData}
      ></PdfRowTextItem>

      <PdfRowKeyValuesItem
        header="ALE-Version inkl. Angabe ALE Version Operation und ALE Softwareversion"
        info={versionData}
        keyStyle={styles.keyContainer}
      ></PdfRowKeyValuesItem>
      <PdfRowTextItem
        header="Betriebssoftware"
        info={data.betriebssoftware ?? props.steckbrief.undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem header=" " info=" "></PdfRowTextItem>
    </View>
  );
};

export default PdfTechnicalDataItem;
