import { EpcomResponse } from "App/service/http.service";
import DateUtils from "global/util/DateUtils";
import DsmdStatus from "service/data-service/automate-controller/interface/DsmdStatus";
import AutomatDatenDsmd from "../interface/AutomatDatenDsmd";

const DsmdStatusDTO = {
  convertResponseToDsmdStatus: (response: EpcomResponse): DsmdStatus => {
    if (response.data) {
      return {
        dateiName: response.data.dateiName,
        datenVolume: response.data.datenVolume,
        empfangsIp: response.data.empfangsIp,
        endeZeit: response.data.endeZeit,
        kommunikationsTyp: response.data.kommunikationsTyp,
        minAle5Version: response.data.minAle5Version,
        modifiedTimestamp: response.data.modifiedTimestamp,
        nachrichtenCodeType: response.data.nachrichtenCodeType,
        nok: response.data.nok,
        posDatum: DateUtils.arrayToDateString(response.data.posDatum),
        posTimestamp: response.data.posTimestamp,
        serienNr: response.data.serienNr,
        startZeit: response.data.startZeit,
        status: response.data.status,
        versionAle: response.data.versionAle,
        versionAleSoftware: response.data.versionAleSoftware,
        versionDsmd: response.data.versionDsmd,
      };
    }
    return {} as DsmdStatus;
  },
  convertAutomatDatenDsmd: (
    response: EpcomResponse
  ): Array<AutomatDatenDsmd> => {
    if (response.data) {
      return [...response.data];
    }
    return {} as Array<AutomatDatenDsmd>;
  },
};

export default DsmdStatusDTO;
