import { ColDef } from "ag-grid-community";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

const AbrufPanelColumnDefs: Array<ColDef> = [
  {
    field: "kommTyp",
    headerName: "Auftragstyp",
    minWidth: 120,
  },
  {
    field: "statusCellNode",
    headerName: "Status",
    minWidth: 100,
    cellRenderer: CellValueRenderer,
  },
  {
    field: "created",
    headerName: "gestartet",
    minWidth: 200,
  },
  {
    field: "ended",
    headerName: "beendet",
    minWidth: 200,
  },
  {
    field: "ftpReplyMessage",
    headerName: "FTP-Meldung",
    minWidth: 280,
  },
  {
    field: "ftpFilename",
    headerName: "Dateiname",
    minWidth: 180,
  },
  {
    field: "ftpFileDateTime",
    headerName: "Dateidatum",
    minWidth: 200,
  },
  {
    field: "ftpFileSize",
    headerName: "Größe",
    minWidth: 140,
  },
];

export default AbrufPanelColumnDefs;
