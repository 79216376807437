import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";

const JOB_EPG_ARCHIVIERUNG: EnumeratorItem = {
  value: "epg_archivierung",
  source: {},
  id: "EPG_ARCHIVIERUNG",
};

const JOB_AR_CLEANUP: EnumeratorItem = {
  value: "ar_cleanup",
  source: {},
  id: "ARCHIV_LOESCHUNG",
};

const JOB_DS_CLEANUP_AUFTRAEGE: EnumeratorItem = {
  value: "ds_cleanup_auftraege",
  source: {},
  id: "DATENBANK_AUFTRAEGE_CLEANUP",
};

const JOB_DPG_STAMMDATEN: EnumeratorItem = {
  value: "dpg_stammdaten",
  source: {},
  id: "DPG_AUTOMAT_STAMMDATEN",
};

const JOB_CLEARINGFEHLER: EnumeratorItem = {
  value: "clearingfehler",
  source: {},
  id: "CLEARINGFEHLER",
};

const JOB_GH_CHANGE: EnumeratorItem = {
  value: "gh_change",
  source: {},
  id: "GHCHANGE",
};

const JOB_MIGRATION_GLN: EnumeratorItem = {
  value: "migration_gln",
  source: {},
  id: "RNS_MIGRATION",
};

const JOB_ERREICHBARKEITS_REPORT: EnumeratorItem = {
  value: "erreichbarkeits_report",
  source: {},
  id: "ERREICHBARKEITSREPORT",
};

const JOB_CLEARER_VERARBEITETE_DATEIEN: EnumeratorItem = {
  value: "clearer_verarbeitete_dateien",
  source: {},
  id: "CLEARER_VERARBEITETE_DATEIEN",
};

const JOB_CD_STD_ABRUF: EnumeratorItem = {
  value: "cd_std_abruf",
  source: {},
  id: "CLEARER_STAMMDATEN_ABRUF",
};

const JOB_AUSLVERF_ABRUF: EnumeratorItem = {
  value: "auslverf_abruf",
  source: {},
  id: "CLEARER_VERFUEGBARKEIT_ABRUF",
};

const JOB_AUSLVERF_ANDERUNGEN: EnumeratorItem = {
  value: "auslverf_abruf_anderungen",
  source: {},
  id: "CLEARER_VERFUEGBARKEIT_ANDERUNGEN",
};

const JOB_CLEANUP_AUTOMAT_LOGFILES: EnumeratorItem = {
  value: "cleanup_automat_logfiles",
  source: {},
  id: "CLEANUP_AUTOMAT_LOGFILES",
};

const JOB_LOGFILE_FEHLER: EnumeratorItem = {
  value: "logfile_fehler",
  source: {},
  id: "DETERMINE_LOGFILEFEHLER",
};

const JOB_LOGIN_VON_DRITTEN: EnumeratorItem = {
  value: "login_von_dritten",
  source: {},
  id: "LOGINS_FROM_THIRD_PARTY",
};

const JOB_AT_EDKBTB_MIGRATE: EnumeratorItem = {
  value: "at_edkbtb_migrate",
  source: {},
  id: "RNBTB_MIGRATE",
};

const JOB_RNBTB_ARCHIVE_RAW_POS: EnumeratorItem = {
  value: "rnbtb_archive_raw_pos",
  source: {},
  id: "RNBTB_ARCHIVE_RAW_POS",
};

const JOB_RNBTB_SERIALS_NACHRICHTENCODE_ACCESS: EnumeratorItem = {
  value: "rnbtb_serials_nachrichtencode_access",
  source: {},
  id: "RNBTB_SERIALS_NACHRICHTENCODE_ACCESS",
};

const JOB_FLATFILE_GENERATE: EnumeratorItem = {
  value: "flat_file_export",
  source: {},
  id: "FLATFILE_GENERATE",
};

const JOB_SAPRD_POSIMPORT: EnumeratorItem = {
  value: "saprd_posimport",
  source: {},
  id: "saprd_posimport",
};

const JOB_SAPRD_SAPIMPORT: EnumeratorItem = {
  value: "saprd_sapimport",
  source: {},
  id: "saprd_sapimport",
};

const JobsEnumerator = {
  items: [
    {
      value: "Alle",
      source: {},
      id: "alle",
    },
    JOB_EPG_ARCHIVIERUNG,
    JOB_AR_CLEANUP,
    JOB_DS_CLEANUP_AUFTRAEGE,
    JOB_DPG_STAMMDATEN,
    JOB_CLEARINGFEHLER,
    JOB_GH_CHANGE,
    JOB_MIGRATION_GLN,
    JOB_ERREICHBARKEITS_REPORT,
    JOB_CLEARER_VERARBEITETE_DATEIEN,
    JOB_CD_STD_ABRUF,
    JOB_AUSLVERF_ABRUF,
    JOB_AUSLVERF_ANDERUNGEN,
    JOB_CLEANUP_AUTOMAT_LOGFILES,
    JOB_LOGFILE_FEHLER,
    JOB_LOGIN_VON_DRITTEN,
    JOB_AT_EDKBTB_MIGRATE,
    JOB_RNBTB_ARCHIVE_RAW_POS,
    JOB_RNBTB_SERIALS_NACHRICHTENCODE_ACCESS,
    JOB_FLATFILE_GENERATE,
    JOB_SAPRD_POSIMPORT,
    JOB_SAPRD_SAPIMPORT,
  ],
} as Enumerator;

export default JobsEnumerator;
