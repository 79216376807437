import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import DepositMachineFilterModel from "service/data-service/automate-controller/interface/FilterObject";

const FilterCache: DepositMachineFilterModel = {
  automatStatus: ["INBETRIEB", "NONDPG"],
};

const FilterController = {
  save: <T extends keyof DepositMachineFilterModel>(
    key: T,
    value: DepositMachineFilterModel[T]
  ) => {
    FilterCache[key] = value;
  },
  get: <T extends keyof DepositMachineFilterModel>(valueToGet: T) =>
    FilterCache[valueToGet],
  getFilterObject: (): DepositMachineFilterModel => {
    return { ...FilterCache };
  },
  mapFromEnumeratorItemsToIdStrings: function (
    enumeratorItems: Array<EnumeratorItem>
  ) {
    return enumeratorItems.map((value) => value.id.toString());
  },
  resetFilterCache: () => {
    FilterCache.automatHerstellerKuerzel = [];
    FilterCache.automatModellKey = [];
    FilterCache.automatSchluesselId = "";
    FilterCache.automatStatus = ["INBETRIEB", "NONDPG"];
    FilterCache.dpgAutomat = "";
    FilterCache.ipAddress = "";
    FilterCache.macAddress = "";
    FilterCache.rnsGln = "";
    FilterCache.rnsName = "";
    FilterCache.rnsOrt = "";
    FilterCache.rnsPlz = "";
    FilterCache.ruecknehmer = [];
    FilterCache.serienNr = "";
  },
};

export default FilterController;
