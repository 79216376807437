export function cronToReadableText(cron: string): string {

    if (cron !== null) {
        const cronSplitted = cron.split(' ')

        const cronjob = {
            seconds: cronSplitted[0].split(","),
            minutes: cronSplitted[1].split(","),
            hours: cronSplitted[2].split(","),
            dayOfMonth: cronSplitted[3].split(","),
            month: cronSplitted[4].split(","),
            dayOfWeek: cronSplitted[5].split(",")
        }


        // DayOfWeek
        let textDayOfWeek = "";
        cronjob.dayOfWeek.forEach(dayOfWeek => {
            if (dayOfWeek.includes("-")) {
                const days = dayOfWeek.split("-");
                textDayOfWeek += `jeden ${formatWeekDay(days[0])} bis ${formatWeekDay(days[1])}; `

            } else {
                textDayOfWeek += dayOfWeek !== "?" ? `jeden ${formatWeekDay(dayOfWeek)}; ` : ""
            }
        })

        // DayOfMonth

        let textDayOfMonth = "";
        cronjob.dayOfMonth.forEach(dayOfMonth => {
            const possibleDot = Number.isNaN(parseInt(dayOfMonth)) ? "": "."

            if (dayOfMonth.includes("-")) {
                const days = dayOfMonth.split("-");
                textDayOfMonth += `jeden ${days[0]}${possibleDot} bis ${days[1]}${possibleDot} im Monat; `

            } else {
                textDayOfMonth += dayOfMonth !== "?" ? dayOfMonth !== "?" ? "" : `jeden ${dayOfMonth}${possibleDot} im Monat; ` : ""
            }
        })

        // Hours
        let textHours = "";

        cronjob.hours.forEach(hour => {

            if (hour.includes("-")) {
                const hourRange = hour.split("-");
                textHours += `um ${hourRange[0]} bis ${hourRange[1]} Uhr; `

            } else {
                textHours += hour !== "?" ? hour === "*" ? "jede Stunde" : `um ${hour} Uhr; ` : ""
            }
        })

        // Minutes
        let textMinutes = "";

        cronjob.minutes.forEach(hour => {

            if (hour.includes("-")) {
                const minutesRange = hour.split("-");
                textMinutes += `um ${minutesRange[0]} bis ${minutesRange[1]} Uhr; `

            } else {
                textMinutes += hour !== "?" ? hour === "*" ? "jede Minute" : `auf die Minute ${hour}; ` : ""
            }
        })

        // const textHours =
        // const textTime = `um ${cronjob.hours}:${cronjob.minutes}:${cronjob.seconds} Uhr`
        const text = `${textDayOfMonth} ${textDayOfWeek} ${textHours} ${textMinutes}`.trim().slice(0, -1);
    
        return text;
    }

    return "";
}

function formatWeekDay(weekday: string): string {
    switch (weekday) {
        case "0":
        case "7":
        case "SUN": return "Sonntag"
        case "1":
        case "MON": return "Montag"
        case "2":
        case "TUE": return "Dienstag"
        case "3":
        case "WED": return "Mittwoch"
        case "4":
        case "THU": return "Donnerstag"
        case "5":
        case "FRI": return "Freitag"
        case "6":
        case "SAT": return "Samstag"
        default: return ""
    }

}