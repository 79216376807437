import { Button, Grid, Stack, Typography } from "@mui/material";
import {
  EnumeratorItem,
  enumeratorItemForId,
} from "global/components/EnumeratedValues/Enumerator";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import { useModalDialog } from "global/hook/modal/use-modals";
import { ChangeEvent, useState } from "react";
import { PositionDTO } from "service/sap-service/interface/SapOutputParam";
import ZuordnenRegionEnumerator from "./RegionZuordnenEnumerator";
import RegionsListDialogContent from "./RegionsListDialogContent";

import "./positiondetails.scss";

export interface PositionDetailsDialogContentProps {
  onContentChange: Function;
  entry: PositionDTO;
}

const PositionDetailsDialogContent = (
  props: PositionDetailsDialogContentProps
) => {
  const [dialogHelper, dialogController] = useModalDialog(
    "regionen-zuordnen-helper"
  );
  const [region, setRegion] = useState<EnumeratorItem | null>();
  const bemerkung = useState<string>()[0];

  const bemerkungTextChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ?? undefined;
    props.onContentChange({ bemerkung: value });
  };

  const onRegionSelectHandler = (selection: EnumeratorItem | null) => {
    setRegion(selection);
    if (selection) {
      props.onContentChange({ region: selection.id as string });
    }
  };

  const onPlzSucheRegionHandler = (regionID: string) => {
    const item = enumeratorItemForId(ZuordnenRegionEnumerator, regionID);
    if (item) onRegionSelectHandler(item);
    dialogController.closeDialog();
  };

  const showRegionsListDialog = () => {
    dialogController.showDialog({
      message: "",
      content: (
        <RegionsListDialogContent
          zipcode={props.entry.rnsPlz}
          onRegionSelected={onPlzSucheRegionHandler}
        />
      ),
      onAbortClick: () => {},
      abortCaption: "Abbrechen",
      maxWidth: "xl",
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextValue
            label="Anzahl:"
            value={props.entry.anzahlPositionen}
            readOnly
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            InputProps={{ className: "positionDetails__InputStyle" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="RN-GLN:"
            value={props.entry.rnGln}
            readOnly
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            InputProps={{ className: "positionDetails__InputStyle" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="RNS-PLZ:"
            value={props.entry.rnsPlz}
            readOnly
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            InputProps={{ className: "positionDetails__InputStyle" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="RNS-Ort:"
            value={props.entry.rnsOrt}
            readOnly
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            InputProps={{ className: "positionDetails__InputStyle" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="RNS-Strasse:"
            value={props.entry.rnsStrasse}
            readOnly
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            InputProps={{ className: "positionDetails__InputStyle" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="RNS-Name:"
            value={props.entry.rnsName}
            readOnly
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            InputProps={{ className: "positionDetails__InputStyle" }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectBox
            id="regions-select-box"
            label="Region ausw.:"
            enableClearable
            enumerator={ZuordnenRegionEnumerator}
            selection={region ?? undefined}
            getSelectedValue={(value) => onRegionSelectHandler(value)}
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={3}
            mt={1}
            mb={3}
          >
            <Button
              className="dialogButton"
              variant="contained"
              onClick={showRegionsListDialog}
            >
              PLZ Suche
            </Button>
            <Typography className="positionDetails__TextStyle">
              Ist die Region nicht bekannt, dann PLZ-Suche verwenden
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="Bemerkung:"
            value={bemerkung}
            onChange={bemerkungTextChangeHandler}
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          />
        </Grid>
      </Grid>
      {dialogHelper && <ModalMessageDialog {...dialogHelper} />}
    </>
  );
};

export default PositionDetailsDialogContent;
