import {
  CheckCircle,
  CrisisAlert,
  Pause,
  ReportOff,
  WarningAmber,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import ColorPaletteOptions from "App/theme/ColorTheme";
import { enumeratorItemForId } from "global/components/EnumeratedValues/Enumerator";
import { StatusIconType } from "global/components/UI/StatusIcon/StatusIcon";
import DateUtils from "global/util/DateUtils";
import { JobStatus } from "service/data-service/job-controller/interface/JobInformation";
import { cronToReadableText } from "../utils/cron-formatter";
import JobsEnumerator from "../utils/job-list";

export function DefaultCellValueRenderer(params: any) {
  return (
    <>
      <Typography color={ColorPaletteOptions.text?.primary}>
        {params.value}
      </Typography>
    </>
  );
}

export function DateCellValueRenderer(params: any) {
  let date = new Date(Date.parse(params.value));
  let dateFormated = DateUtils.formatDate(date, {
    minute: "2-digit",
    hour: "2-digit",
  });
  if (dateFormated === "Invalid Date") {
    dateFormated = "";
  }

  return (
    <>
      <Typography>{dateFormated}</Typography>
    </>
  );
}

export function FormatDateTimeCellValueRenderer(params: any) {
  let dateFormated = DateUtils.formatDate(params.value, {
    minute: "2-digit",
    hour: "2-digit",
  });

  return (
    <>
      <Typography>{dateFormated}</Typography>
    </>
  );
}

export function FormatDateCellValueRenderer(params: any) {
  let dateFormated = DateUtils.formatDate(params.value, {});

  return (
    <>
      <Typography>{dateFormated}</Typography>
    </>
  );
}

export function CronJobsCellValueRenderer(params: any) {
  return params.value ? setText("", cronToReadableText(params.value)) : "";
}

export function JobNameCellValueRenderer(params: any) {
  const jobName =
    enumeratorItemForId(JobsEnumerator, params.value as string)?.value ??
    params.value;
  return params.value ? setText("", jobName) : "";
}

export function StatusCellValueRenderer(params: any) {
  return (
    <>
      {params.value === JobStatus.RUNNING && (
        <>
          <WarningAmber
            className="uiElement"
            fontSize="small"
            sx={{ color: StatusIconType.RN }}
          />
          <Typography
            className="uiElement statusIcon__Text"
            sx={{ color: StatusIconType.RN }}
          >
            Gerade am Laufen
          </Typography>
        </>
      )}
      {params.value === JobStatus.READY && (
        <>
          <CrisisAlert
            className="uiElement"
            fontSize="small"
            sx={{ color: StatusIconType.NOK }}
          />
          <Typography
            className="uiElement statusIcon__Text"
            sx={{ color: StatusIconType.NOK }}
          >
            Bereit
          </Typography>
        </>
      )}
      {params.value === JobStatus.FAILURE && (
        <>
          <CrisisAlert
            className="uiElement"
            fontSize="small"
            sx={{ color: StatusIconType.NOK }}
          />
          <Typography
            className="uiElement statusIcon__Text"
            sx={{ color: StatusIconType.NOK }}
          >
            Fehlerhaft
          </Typography>
        </>
      )}
      {params.value === JobStatus.SUCCESS && (
        <>
          <CheckCircle
            className="uiElement"
            fontSize="small"
            sx={{ color: StatusIconType.OK }}
          />
          <Typography
            className="uiElement statusIcon__Text"
            sx={{ color: StatusIconType.OK }}
          >
            Erfolgreich
          </Typography>
        </>
      )}
      {params.value === JobStatus.DISABLED && (
        <>
          <Pause
            className="uiElement"
            fontSize="small"
            sx={{ color: StatusIconType.NRN }}
          />
          <Typography
            className="uiElement statusIcon__Text"
            sx={{ color: StatusIconType.NRN }}
          >
            Deaktiviert
          </Typography>
        </>
      )}
      {params.value === JobStatus.NOT_YET_RUN && (
        <>
          <WarningAmber
            className="uiElement"
            fontSize="small"
            sx={{ color: "primary.main" }}
          />
          <Typography
            className="uiElement statusIcon__Text"
            sx={{ color: "primary.main" }}
          >
            Heute noch nicht gelaufen
          </Typography>
        </>
      )}
      {params.value === JobStatus.NOT_MAPPED && (
        <>
          <ReportOff
            className="uiElement"
            fontSize="small"
            sx={{ color: StatusIconType.NRN }}
          />
          <Typography
            className="uiElement statusIcon__Text"
            sx={{ color: StatusIconType.NRN }}
          >
            Nicht reportfähig
          </Typography>
        </>
      )}
    </>
  );
}

function setText(className: string, text: string) {
  return (
    <>
      <Typography
        className={className}
        color={ColorPaletteOptions.text?.primary}
      >
        {text}
      </Typography>
    </>
  );
}
