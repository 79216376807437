import { EpcomResponse } from "App/service/http.service";
import DateUtils from "global/util/DateUtils";
import AutomatStatusLog, { StatusLog } from "../interface/AutomatStatusLog";

const AutomatStatusLogDTO = {
  convertResponseToAutomatStatusLogDTO: (
    response: EpcomResponse
  ): AutomatStatusLog => {
    if (response.data) {
      let rows: Array<StatusLog> = [];
      response.data.Row.forEach((row: StatusLog) => {
        let record: StatusLog = {
          ...row,
          datumEndeString: DateUtils.optionalTimeArrayToGermanString(
            row.datumEnde as Array<number>
          ) as string,
        };
        rows.push(record);
      });
      return {
        rows: rows,
      };
    }
    return {} as AutomatStatusLog;
  },
};
export default AutomatStatusLogDTO;
