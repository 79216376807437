import { Button, Grid, Stack, Typography } from "@mui/material";
import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  useEnumerator,
  VISIBLE_AND_CURRENT_EPCOM_GROUPS,
} from "global/hook/enumerator/use-enumerator";
import useModalController from "global/hook/modal/use-modals";
import {
  returnUndefinedIfOnlyWhitespace,
  validateEmail,
} from "global/util/utils";
import { useEffect, useState } from "react";
import EpcomUserGroupAutomat, {
  RollenType,
  UserActionType,
} from "service/data-service/user-controller/interface/EpcomUserGroupAutomat";
import UserService from "service/data-service/user-controller/User.service";

import "./anlageneueruser.scss";

const AnlageNeuerUser = () => {
  const modals = useModalController();
  const epcomGroupsEnum: Enumerator = useEnumerator(
    VISIBLE_AND_CURRENT_EPCOM_GROUPS
  );

  const [natID, setNatID] = useState<string>("");
  const [vorname, setVorname] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [group, setGroup] = useState<EnumeratorItem | null>();
  const [reset, setReset] = useState(false);
  const [saveIsDisabled, setSaveIsDisabled] = useState(true);

  const resetFilter = () => {
    setNatID("");
    setVorname("");
    setName("");
    setEmail("");
    setGroup(null);
    setReset((state) => !state);
  };

  const btnSaveUserHandler = () => {
    const groupRegion = group?.source?.region;
    const groupName = group?.source?.name;
    const rollenTyp: RollenType =
      groupRegion && groupRegion.trim()
        ? groupRegion === "EPG"
          ? RollenType.EPG
          : RollenType.GHB
        : groupName === "GETEC"
        ? RollenType.CLD
        : RollenType.ATH;

    UserService.processUser(
      UserActionType.CREATEUSER,
      {
        epcomGroupDTO: {
          key: group?.id,
        },
        userDTOs: [
          {
            nationaleId: natID,
            vorName: vorname,
            name: name,
            email: email,
            auspraegung: groupRegion,
            rollenTyp: rollenTyp,
            extern: [RollenType.CLD, RollenType.ATH].includes(rollenTyp),
          },
        ],
      } as EpcomUserGroupAutomat,
      (output: EpcomUserGroupAutomat) => {
        if (output.userErrorType !== "USERERROR_NO_ERROR") {
          modals.showMessageDialog(
            output.message,
            undefined,
            "Speichern nicht erfolgreich"
          );
        } else {
          resetFilter();
          modals.showMessageDialog(
            "Benutzer wurde gespeichert.",
            undefined,
            "Speichern"
          );
        }
      },
      () => {}
    );
  };

  useEffect(() => {
    setSaveIsDisabled(
      group === null ||
        returnUndefinedIfOnlyWhitespace(natID) === undefined ||
        returnUndefinedIfOnlyWhitespace(vorname) === undefined ||
        returnUndefinedIfOnlyWhitespace(name) === undefined ||
        returnUndefinedIfOnlyWhitespace(email) === undefined ||
        !validateEmail(email)
    );
  }, [group, natID, vorname, name, email]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={3}>
        <Typography variant={"h1"}>Anlage neue User</Typography>
      </Grid>

      <Grid item xs={12}>
        <SelectBox
          label={"Gruppe:"}
          enumerator={epcomGroupsEnum}
          id="anlageneueruser-group"
          getSelectedValue={(value) => setGroup(value)}
          reset={reset}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          AutocompleteProps={{
            className: "anlageneueruser__InputStyle",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"anlageneueruser-natid"}
          label="Nationale ID:"
          value={natID}
          maxLength={20}
          onChange={(event) => setNatID(event.target.value ?? "")}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          TextFieldProps={{ className: "anlageneueruser__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"anlageneueruser-vorname"}
          label="Vorname:"
          maxLength={50}
          value={vorname ?? ""}
          onChange={(event) => setVorname(event.target.value ?? "")}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          TextFieldProps={{ className: "anlageneueruser__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"anlageneueruser-nachname"}
          label="Nachname:"
          maxLength={50}
          value={name ?? ""}
          onChange={(event) => setName(event.target.value ?? "")}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          TextFieldProps={{ className: "anlageneueruser__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"anlageneueruser-email"}
          label="E-Mail:"
          value={email ?? ""}
          onChange={(event) => setEmail(event.target.value ?? "")}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          TextFieldProps={{ className: "anlageneueruser__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Button size="small" variant="secondary" onClick={resetFilter}>
            Felder zurücksetzen
          </Button>
          <Button
            disabled={saveIsDisabled}
            size="small"
            variant="contained"
            onClick={btnSaveUserHandler}
          >
            Speichern
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AnlageNeuerUser;
