import { Checkbox, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { ChangeEvent } from "react";
import ClearingfehlerService from "service/data-service/clearingfehler-controller/Clearingfehler.service";
import ClearingfehlerResult, {
  Clearingfehler
} from "service/data-service/clearingfehler-controller/interface/Clearingfehler";

import "global/components/ui.scss";
import { CLEARINGFEHLER_ERLEDIGT_CHANGE } from "global/hook/datastore/automat/automat-clearingfehler-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { DispatchAutomatDataFunction } from "global/hook/datastore/use-automat-datastore";
import ModalController from "global/hook/modal/interface/ModalController";
import Access from "service/data-service/access-controller/interface/Access";

const ClearingfehlerPanelController = {
  transmuteToGridData(
    logData: ClearingfehlerResult,
    updatingState: Array<boolean>,
    updatingStateSetter: (index: number, value: boolean) => void,
    dispatchAutomatDataAction: DispatchAutomatDataFunction,
    modalController: ModalController,
    access: Access,
  ): Array<Clearingfehler> {
    const onRecordChangeTrigger = updateErledigtTrigger.bind(
      null,
      updatingStateSetter,
      dispatchAutomatDataAction,
      modalController
    );

    let logElements: Array<Clearingfehler> = [];
    logData.rows.forEach((element, index) => {
      const onRecordErledigtChange = onRecordChangeTrigger.bind(
        null,
        element,
        index
      );

      const record = {
        ...element,
        erledigtCellValue: this.buildCheckboxNode(
          element,
          updatingState[index],
          onRecordErledigtChange,
          access,
        ),
      };
      logElements.push(record);
    });
    return logElements;
  },
  buildCheckboxNode(
    record: Clearingfehler,
    isUpdating: boolean,
    erledigtChangeHandler: (value: boolean) => void,
    access: Access,
  ): React.ReactNode {
    let disabled = access.rollenTyp === 'ATH' || access.rollenTyp === 'CLD'

    const hasErledigtValue = typeof record.erledigt === "boolean";

    const checkboxChangeHandler = (_: ChangeEvent, value: boolean) => {
      erledigtChangeHandler(value);
    };

    return (
      <Box className="box-with-centered-content">
        {hasErledigtValue && (
          <Checkbox
            checked={record.erledigt === true}
            disabled={disabled || isUpdating}
            color="success"
            onChange={checkboxChangeHandler}
          />
        )}
        {hasErledigtValue && isUpdating && (
          <CircularProgress className="checkboxActionProgress" size={16} />
        )}
      </Box>
    );
  },
};

export default ClearingfehlerPanelController;

function updateErledigtTrigger(
  updatingStateSetter: (index: number, value: boolean) => void,
  dispatchAutomatDataAction: DispatchAutomatDataFunction,
  modalController: ModalController,
  record: Clearingfehler,
  recordIndex: number,
  newErledigtValue: boolean
) {
  updatingStateSetter(recordIndex, true);
  dispatchAutomatDataAction(CLEARINGFEHLER_ERLEDIGT_CHANGE, {
    clearingfehler: record,
  } as AutomatActionPayload);

  ClearingfehlerService.updateClearingfehlerForAutomat(
    {
      ...record,
      erledigt: newErledigtValue,
    },
    (newRecord: Clearingfehler) => {
      updatingStateSetter(recordIndex, false);
      dispatchAutomatDataAction(CLEARINGFEHLER_ERLEDIGT_CHANGE, {
        clearingfehler: newRecord,
      } as AutomatActionPayload);
    },
    (error: Error) => {
      updatingStateSetter(recordIndex, false);
      dispatchAutomatDataAction(CLEARINGFEHLER_ERLEDIGT_CHANGE, {
        clearingfehler: record,
      } as AutomatActionPayload);
    },
    modalController
  );
}
