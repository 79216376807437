import PositionService from "service/sap-service/Position.service";
import SapInputParam from "service/sap-service/interface/SapInputParam";
import SapOutputParam from "service/sap-service/interface/SapOutputParam";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_SAPRDVERTRAG_RECORDS = "FETCH_SAPRDVERTRAG_RECORDS";

const configureSapRdVertragStore = () => {
  const fetchSapRdVertragRecords: AutomatAction = {
    identifier: FETCH_SAPRDVERTRAG_RECORDS,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      PositionService.getPositionenForUnterwgrs(
        {
          serienNr: `${currentState.automatenblattInformation.seriennummer}`,
          rnsGln: currentState.automatenblattInformation.rns.gln,
          unterWarengruppenKuerzel: ["LRS - 0410", "LRS - 0411"],
        } as SapInputParam,
        (data: SapOutputParam) =>
          callback({
            sapVertragOutput: data,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([
    fetchSapRdVertragRecords,
  ]);
};

export default configureSapRdVertragStore;
