import HttpService from "App/service/http.service";
import Config from "Config";
import EdekaOrg from "./dto/EdekaOrg";
import EdekaOrganisation from "./interface/EdekaOrganisation";

const EdekaOrgService = {
  createRechnungsnehmer: (
    edeksOrgDto: EdekaOrganisation,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(
        Config.dataServiceUrl + "edkorg/create/rechnungsnehmer",
        edeksOrgDto
      )
      .then((response) => {
        callbackOnSuccess(EdekaOrg.convertEdekaOrgResponseDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default EdekaOrgService;
