export default interface EpcomUserGroupAutomat {
  readonly userDTOs: Array<User>;
  readonly epcomGroupDTO: EpcomGroup;
  readonly epcomGroupDTOs: Array<EpcomGroup>;
  readonly automatShortInfoDTOs: Array<AutomatShortInfo>;
  readonly message: string;
  readonly userErrorType: string;
  readonly automatShortInfoDTO: AutomatShortInfo;
  readonly serial: string;
  readonly nationaleId: string;
  readonly role: string;
  readonly auspraegung: string;
  readonly gueltigVon: string;
  readonly gueltigBis: string;
}

export interface User {
  readonly user: string;
  readonly rollenTyp: RollenType;
  readonly auspraegung: string;
  readonly nationaleId: string;
  readonly name: string;
  readonly vorName: string;
  readonly email: string;
  readonly telefon: string;
  readonly telefonVorwahl: string;
  readonly userKuerzel: string;
  readonly modifiedTimestamp: string;
  readonly gueltigVon: string;
  readonly gueltigBis: string;
  readonly telefonMobil: string;
  readonly telefonMobilVorwahl: string;
  readonly extern: boolean;
}

export interface EpcomGroup {
  readonly key: string;
  readonly region: string;
  readonly name: string;
  readonly nameForGui: string;
  readonly beschreibung: string;
  readonly gueltigVon: string;
  readonly gueltigBis: string;
  readonly readonly: boolean;
  readonly notVisible: boolean;
  readonly andereRegion: boolean;
  readonly ghWechsel: boolean;
}

export interface AutomatShortInfo {
  readonly serienNr: string;
  // add other field from response if needed
}

export enum RollenType {
  GHB,
  EPG,
  ATH,
  CLD,
  SYS,
}

export enum UserActionType {
  ADDAUTOMATTOGROUP = "ADDAUTOMATTOGROUP",
  ADDUSERTOGROUP = "ADDUSERTOGROUP",
  CREATEGROUP = "CREATEGROUP",
  CREATEUSER = "CREATEUSER",
  DEACTIVATEUSER = "DEACTIVATEUSER",
  DELETEGROUP = "DELETEGROUP",
  GETAUTOMATFORUSER = "GETAUTOMATFORUSER",
  GETALLGROUPS = "GETALLGROUPS",
  GETGROUP = "GETGROUP",
  GETUSER = "GETUSER",
  REMOVEAUTOMATFROMGROUP = "REMOVEAUTOMATFROMGROUP",
  REMOVEUSERFROMGROUP = "REMOVEUSERFROMGROUP",
  UPDATEGROUP = "UPDATEGROUP",
  UPDATEUSER = "UPDATEUSER",
}
