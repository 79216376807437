import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Typography } from "@mui/material";

interface AutomatTablabelProps {
  label: string;
  changeCount: number;
  switchTab: Function;
  removeTab: Function;
}

const AutomatTabLabel: React.FC<AutomatTablabelProps> = (
  props: AutomatTablabelProps
) => {
  return (
    <Stack
      className="tab-stack"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <div className="tab-div" onClick={() => props.switchTab()}>
        <Typography>{props.label}</Typography>
      </div>
      <IconButton onMouseDown={(event) => props.removeTab()}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
export default AutomatTabLabel;
