import { useState } from "react";

import AccessController from "App/components/Access-Control/AccessController";
import useSnackbarGenerator, {
  SnackbarGeneratorFunction,
} from "App/hook/use-snackbars";
import DateUtils from "global/util/DateUtils";
import { SnackbarKey, closeSnackbar } from "notistack";
import { MaintenanceInfo } from "service/data-service/access-controller/interface/Access";
import ModalController from "../modal/interface/ModalController";
import useModalController from "../modal/use-modals";
import MaintenanceController from "./interface/MaintenanceController";

let info: MaintenanceInfo | undefined;
let maintenanceSnackbarKey: SnackbarKey | undefined;
let maintenanceWarningMessage = "";
let showSnackbar: SnackbarGeneratorFunction | undefined;
let dialogController: ModalController | undefined;
const GUI_MAINTENANCE_DEFAULT_MESSAGE =
  "Die Anwendung ist im Wartungsmodus. Bitte versuchen Sie es später erneut.";
export const MAINTENANCETORAGE_KEY = "maintenance";
let maintenanceRefreshTask: NodeJS.Timeout | undefined;
const MAINTENANCE_PRE_WARNING_TIME = 4 * 60 * 60 * 1000; // 4 hours in ms
const DEF_MAINTENANCE_REFRESH_TIMEOUT = 5 * 60 * 1000;
const ENGAGED_MAINTENANCE_REFRESH_TIMEOUT = 1 * 60 * 1000;
let maintenanceRefreshTimeout = 0;

const isUrlAllowedDuringMaintenance = (url: string) => {
  return (
    url.includes("ui/access/getAccess") ||
    url.includes("maintenance/load") ||
    url.includes("maintenance/update")
  );
};

const canDisplayMaintenanceMessageFor = (url: string) => {
  return !(
    url.endsWith("errortyp/geterrortypes") ||
    url.endsWith("user/epcom-group/get-all-visible") ||
    url.endsWith("getUnterwarengruppen") ||
    url.endsWith("getWarengruppen") ||
    url.endsWith("rns/findList") ||
    url.endsWith("status") ||
    url.includes("stammdaten/") ||
    url.includes("ath/modell")
  );
};

const requirestMaintenanceWarning = (): boolean => {
  if (!info) return false;

  const now = new Date().getTime();
  const startTime = (
    DateUtils.arrayToDate(info.startDate ?? [], true) ??
    new Date(now + 2 * MAINTENANCE_PRE_WARNING_TIME)
  ).getTime();
  const endTime = (
    DateUtils.arrayToDate(info?.endDate ?? [], true) ??
    new Date(now + 2 * MAINTENANCE_PRE_WARNING_TIME)
  ).getTime();

  const delta = Math.abs(startTime - now);
  return (
    info.engaged || (delta <= MAINTENANCE_PRE_WARNING_TIME && now <= endTime)
  );
};

const getMaintenanceWarningMessage = (): string => {
  if (!requirestMaintenanceWarning()) return "";

  const startDate =
    DateUtils.arrayToDate(info?.startDate ?? [], true) ??
    new Date(Date.now() + 2 * MAINTENANCE_PRE_WARNING_TIME);
  const endDate =
    DateUtils.arrayToDate(info?.endDate ?? [], true) ??
    new Date(Date.now() + 2 * MAINTENANCE_PRE_WARNING_TIME);

  return `Wartungsarbeiten sind zwischen ${String(
    startDate.getHours()
  ).padStart(2, "0")}:${String(startDate.getMinutes()).padStart(
    2,
    "0"
  )} und ${String(endDate.getHours()).padStart(2, "0")}:${String(
    endDate.getMinutes()
  ).padStart(2, "0")}  Uhr geplant`;
};

const manageMaintenanceWarning = () => {
  const warnMessage = getMaintenanceWarningMessage();
  if (warnMessage !== "") {
    if (warnMessage !== maintenanceWarningMessage || !maintenanceSnackbarKey) {
      maintenanceWarningMessage = warnMessage;
      const oldKey = maintenanceSnackbarKey;
      maintenanceSnackbarKey = showSnackbar?.(maintenanceWarningMessage, {
        anchorOrigin: { horizontal: "right", vertical: "top" },
        className: "snackbar-maintenance ",
        hidesAction: true,
        hasIcon: true,
      });
      if (oldKey) closeSnackbar(oldKey);
    }
  } else if (maintenanceSnackbarKey) {
    closeSnackbar(maintenanceSnackbarKey);
    maintenanceSnackbarKey = undefined;
    maintenanceWarningMessage = "";
  }
};

const isUnderMaintenance = (url: string) => {
  manageMaintenanceWarning();

  if (isUrlAllowedDuringMaintenance(url)) return false;

  if (info?.active) {
    console.log("Abort request because of maintenance mode....");

    if (dialogController && canDisplayMaintenanceMessageFor(url)) {
      const maintenanceMessage = info?.message
        ? info?.message
        : GUI_MAINTENANCE_DEFAULT_MESSAGE;
      dialogController.showMessageDialog(
        maintenanceMessage,
        undefined,
        info?.endDate
          ? "Wartung bis " +
              DateUtils.optionalTimeArrayToGermanString(info!.endDate)
          : undefined
      );
    }
    return true;
  }
  return false;
};

const updateMaintenanceRefreshTimeout = (): boolean => {
  let timeout = DEF_MAINTENANCE_REFRESH_TIMEOUT;

  if (!info) {
    // console.log("Missing maintenance mode, refresh timeout set to 5s");
    timeout = 5 * 1000; // 5s
  } else if (requirestMaintenanceWarning()) {
    if (info.engaged) {
      //   console.log("Engaged maintenance mode, refresh timeout set to 60s");
      timeout = ENGAGED_MAINTENANCE_REFRESH_TIMEOUT;
    } else {
      const now = new Date().getTime();
      const startTime = DateUtils.arrayToDate(
        info.startDate ?? [],
        true
      )?.getTime();
      const delta = Math.abs((startTime ?? 0) - now);
      timeout = Math.min(
        ENGAGED_MAINTENANCE_REFRESH_TIMEOUT,
        Math.max(Math.floor(delta / 2), 5 * 1000)
      );
    }
  }
  if (timeout !== maintenanceRefreshTimeout) {
    // console.log("Update maintenance refresh timeout to: ", timeout);
    maintenanceRefreshTimeout = timeout;
    return true;
  }
  return false;
};

export function useMaintenanceInfo(): [
  MaintenanceInfo | undefined,
  MaintenanceController
] {
  showSnackbar = useSnackbarGenerator().showWarn;
  dialogController = useModalController();

  const setMaintenanceInfo = useState<MaintenanceInfo>()[1];

  const setMaintenanceData = (data: MaintenanceInfo | undefined) => {
    if (
      info?.active !== data?.active ||
      info?.engaged !== data?.engaged ||
      JSON.stringify(info?.startDate) !== JSON.stringify(data?.startDate) ||
      JSON.stringify(info?.endDate) !== JSON.stringify(data?.endDate) ||
      info?.message !== data?.message ||
      JSON.stringify(info?.roles) !== JSON.stringify(data?.roles)
    ) {
      info = data;
      sessionStorage.setItem(MAINTENANCETORAGE_KEY, JSON.stringify(data));
      setMaintenanceInfo(data);
      manageMaintenanceWarning();
    }
  };
  return [
    info,
    {
      setMaintenanceInfo: setMaintenanceData,
      isUnderMaintenance,
      manageMaintenanceWarning: manageMaintenanceWarning,
      clear: setMaintenanceData.bind(null, undefined),
    },
  ];
}

export function startMaintenanceRefreshTask(controller: MaintenanceController) {
  if (updateMaintenanceRefreshTimeout()) {
    if (maintenanceRefreshTask) {
      clearInterval(maintenanceRefreshTask);
    }
    maintenanceRefreshTask = setInterval(() => {
      AccessController.getMaintenanceInfo(controller, () =>
        console.log("error when updated maintenance details from backend")
      );
    }, maintenanceRefreshTimeout);
  }
}
