import { Card, CardHeader, List } from "@mui/material";
import { useCockpitInfo } from "global/hook/cockpit/use-cockpit-info";
import {
  HREF_BERICHTE_ANSICHTFEHLER,
  HREF_BERICHTE_DSMD_FEHLERHAFT,
  HREF_BERICHTE_ERREICHBARKEITEN,
} from "global/util/routes";
import {
  ColoredListItemTypography,
  ListItemTypography,
} from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";

const Automatenstatus: React.FC = () => {
  const cockpitInfo = useCockpitInfo()[0];

  const [countOfVendingMachineFailure, setCountOfVendingMachineFailure] =
    useState(Number);
  const [countOfDsmdFailure, setCountOfDsmdFailure] = useState<number | string>(
    "n/a"
  );
  const [countOfWarningsReachability, setCountOfWarningsReachability] =
    useState(Number);

  const [vendingMachineFailureVisible, setVendingMachineFailureVisible] =
    useState(Boolean);
  const [dsmdFailureVisible, setDsmdFailureVisible] = useState(Boolean);
  const [warningsReachabilityVisible, setWarningsReachabilityVisible] =
    useState(Boolean);

  let navigate = useNavigate();

  useEffect(() => {
    setCountOfVendingMachineFailure(
      cockpitInfo.cockpitInformationDTO?.atMmFailed ?? 0
    );
    setCountOfDsmdFailure(
      cockpitInfo.cockpitInformationDTO?.rnbtbDsmdFailed ?? "n/a"
    );
    setCountOfWarningsReachability(
      cockpitInfo.cockpitInformationDTO?.erreichbarkeitWarnings ?? 0
    );

    setVendingMachineFailureVisible(
      cockpitInfo.visibilities?.atMmFailedShow ?? false
    );
    setDsmdFailureVisible(
      cockpitInfo.visibilities?.rnbtbDsmdFailedShow ?? false
    );
    setWarningsReachabilityVisible(
      cockpitInfo.visibilities?.erreichbarkeitWarningsShow ?? false
    );
  }, [cockpitInfo]);

  function selectCardVisibility() {
    if (
      vendingMachineFailureVisible ||
      dsmdFailureVisible ||
      warningsReachabilityVisible
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Card hidden={selectCardVisibility()} aria-label="Automatenstatus">
      <CardHeader title="Automatenstatus" />
      <List disablePadding>
        <CockpitListItemButton
          divider
          sx={{ display: vendingMachineFailureVisible ? "flex" : "none" }}
          onClick={() => navigate(HREF_BERICHTE_ANSICHTFEHLER)}
        >
          <CockpitListItemText primary="Automaten fehlerhaft: " />
          <ColoredListItemTypography
            colorChangeBool={countOfVendingMachineFailure <= 0}
            text={`${countOfVendingMachineFailure}`}
            id={"countOfVendingMachineFailure"}
          />
        </CockpitListItemButton>

        <CockpitListItemButton
          divider
          sx={{ display: dsmdFailureVisible ? "flex" : "none" }}
          onClick={() => navigate(HREF_BERICHTE_DSMD_FEHLERHAFT)}
        >
          <CockpitListItemText primary={"DSMD fehlerhaft: "} />
          <ColoredListItemTypography
            colorChangeBool={countOfDsmdFailure <= 0}
            text={`${countOfDsmdFailure}`}
            id={"countOfDsmdFailure"}
          />
        </CockpitListItemButton>

        <CockpitListItemButton
          sx={{ display: warningsReachabilityVisible ? "flex" : "none" }}
          onClick={() => navigate(HREF_BERICHTE_ERREICHBARKEITEN)}
        >
          <CockpitListItemText primary="Warn. Erreichbarkeiten: " />
          <ListItemTypography
            text={`${countOfWarningsReachability}`}
            id="countOfWarningsReachability"
          />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Automatenstatus;
