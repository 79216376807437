import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import RoleSwitch from "./component/RoleSwitch";

import { StopCircle } from "@mui/icons-material";
import AccessController from "App/components/Access-Control/AccessController";
import useSnackbarGenerator from "App/hook/use-snackbars";
import { useMaintenanceInfo } from "global/hook/maintenance/use-maintenance";
import DateUtils from "global/util/DateUtils";
import { MaintenanceInfo } from "service/data-service/access-controller/interface/Access";
import "./maintenance.scss";

const MaintenanceGUI = () => {
  const { showError } = useSnackbarGenerator();
  const [maintenanceInfo, maintenanceController] = useMaintenanceInfo();

  const [info, setInfo] = useState<MaintenanceInfo>({} as MaintenanceInfo);
  const [isEngaged, setIsEngaged] = useState<boolean>(info.engaged ?? false);
  const [message, setMessage] = useState<string>(info.message ?? "");
  const [impactedRoles, setImpactedRoles] = useState<string[]>(
    info.roles ?? []
  );
  const [startDate, setStartDate] = useState<Date | null>(
    DateUtils.arrayToDate(info.startDate ?? [], true) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    DateUtils.arrayToDate(info.endDate ?? [], true) ?? null
  );
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isApplyingChanges, setIsApplyingChanges] = useState<boolean>(false);
  const [changes, setChanges] = useState<Map<string, string>>(new Map());

  const handleChange = (key: string, value: string, refValue: string) => {
    setChanges((prevchanges) => {
      const _changes = new Map(prevchanges);
      if (refValue === value) {
        _changes.delete(key);
      } else {
        _changes.set(key, value);
      }
      return _changes;
    });
  };

  const dateToString = (date: Date | null) => {
    const dateAsArray = DateUtils.dateToArray(date, true);
    return dateAsArray ? dateAsArray.join(",") : "";
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    handleChange(
      "startDate",
      dateToString(date),
      info.startDate ? info.startDate.join(",") : ""
    );
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    handleChange(
      "endDate",
      dateToString(date),
      info.endDate ? info.endDate.join(",") : ""
    );
  };

  const handleMessageChange = (value: string) => {
    setMessage(value);
    handleChange("message", value, info.message ?? "");
  };

  const switchRole = (role: string) => {
    let _roles: Array<string> = [];
    if (impactedRoles.includes(role)) {
      _roles = impactedRoles.filter((r) => r !== role);
    } else {
      _roles = [...impactedRoles, role];
    }
    setImpactedRoles(_roles);
    handleChange("roles", _roles.join(","), (info.roles ?? []).join(","));
  };

  const clearChanges = () => {
    setInfo({ ...info });
  };

  const saveChangesWithDelay = () => {
    setIsApplyingChanges(true);
    setTimeout(() => {
      saveChanges();
    }, 600);
  };

  const saveChanges = () => {
    const changedInfo: MaintenanceInfo = {
      ...info,
      message: message,
      roles: impactedRoles,
      startDate: DateUtils.dateToArray(startDate, true) ?? [],
      endDate: DateUtils.dateToArray(endDate, true) ?? [],
    };

    AccessController.updateMaintenanceInfo(
      changedInfo,
      maintenanceController,
      (data) => {
        // setInfo(data);
        setIsApplyingChanges(false);
      },
      (error) => {
        showError("Failed to save maintenance changes", { error: error });
        setIsApplyingChanges(false);
      }
    );
  };

  const endMaintenance = () => {
    const changedInfo: MaintenanceInfo = {
      ...info,
      startDate: [],
      endDate: [],
    };

    AccessController.updateMaintenanceInfo(
      changedInfo,
      maintenanceController,
      (data) => {
        setIsApplyingChanges(false);
      },
      (error) => {
        showError("Failed to save maintenance changes", { error: error });
        setIsApplyingChanges(false);
      }
    );
  };

  useEffect(() => {
    const canApplyChanges =
      changes.size > 0 &&
      !(
        (startDate && !endDate) ||
        (endDate && !startDate) ||
        (startDate && endDate && startDate > endDate)
      );
    setHasChanges(canApplyChanges);
  }, [changes]);

  useEffect(() => {
    setIsEngaged(info.engaged ?? false);
    setMessage(info.message ?? "");
    setImpactedRoles(info.roles ?? []);
    setStartDate(DateUtils.arrayToDate(info.startDate ?? [], true) ?? null);
    setEndDate(DateUtils.arrayToDate(info.endDate ?? [], true) ?? null);
    setChanges(new Map());
  }, [info]);

  useEffect(() => {
    setInfo(maintenanceInfo ?? ({} as MaintenanceInfo));
  }, [maintenanceInfo]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>Maintenance</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className={
              isEngaged
                ? "maintenanceui__StatusStyleActive"
                : "maintenanceui__StatusStyle"
            }
          >
            {`STATUS ist ${isEngaged ? "ACTIVE" : "INACTIVE"}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Badge
            color="error"
            variant="dot"
            invisible={!changes.has("roles")}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography>Affected Roles</Typography>
          </Badge>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <RoleSwitch
              label="GHB"
              on={impactedRoles.includes("GHB")}
              onChange={() => switchRole("GHB")}
            />
            <RoleSwitch
              label="EPG"
              on={impactedRoles.includes("EPG")}
              onChange={() => switchRole("EPG")}
            />
            <RoleSwitch
              label="CLD"
              on={impactedRoles.includes("CLD")}
              onChange={() => switchRole("CLD")}
            />

            <RoleSwitch
              label="ATH"
              on={impactedRoles.includes("ATH")}
              onChange={() => switchRole("ATH")}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={3}>
            <Badge
              color="error"
              variant="dot"
              invisible={!changes.has("startDate")}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <DateTimePicker
                label="Start of Maintenance"
                value={startDate ?? null}
                onChange={handleStartDateChange}
              ></DateTimePicker>
            </Badge>
            <Badge
              color="error"
              variant="dot"
              invisible={!changes.has("endDate")}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <DateTimePicker
                label="End of Maintenance"
                value={endDate ?? null}
                onChange={(value) => {
                  handleEndDateChange(value ? new Date(value) : null);
                }}
              ></DateTimePicker>
            </Badge>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Badge
            color="error"
            variant="dot"
            invisible={!changes.has("message")}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <TextField
              className="maintenanceui__MessageInputStyle"
              multiline
              rows={5}
              value={message}
              label="Maintenance Message"
              onChange={(event) => handleMessageChange(event.target.value)}
            />
          </Badge>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Button
              className="maintenanceui___applyButton"
              variant="contained"
              startIcon={isApplyingChanges ? <SaveIcon /> : <CheckIcon />}
              onClick={() => saveChangesWithDelay()}
              disabled={!hasChanges || isApplyingChanges}
            >
              {isApplyingChanges ? "Applying ..." : "Apply Changes"}
            </Button>
            <CircularProgress
              color="warning"
              className="maintenanceui___applyProgress"
              variant={isApplyingChanges ? "indeterminate" : "determinate"}
              value={0}
            />

            <Button
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => clearChanges()}
              disabled={changes.size === 0 || isApplyingChanges}
            >
              Clear Changes
            </Button>

            <Button
              className="maintenanceui___endButton"
              variant="contained"
              startIcon={<StopCircle />}
              onClick={() => endMaintenance()}
              disabled={info.engaged !== true || isApplyingChanges}
            >
              End Maintenance
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default MaintenanceGUI;
