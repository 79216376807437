import { EpcomResponse } from "App/service/http.service";
import LogfileZeile from "../interface/LogfileZeile";

const LogfileDTO = {
  convertLogfileResponse: (response: EpcomResponse): Array<LogfileZeile> => {
    const records: Array<LogfileZeile> = [];
    if (response.data) {
      response.data.map((element: LogfileZeile) => records.push(element));
    }
    return records;
  },
};
export default LogfileDTO;
