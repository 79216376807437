import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import AutomatModellResult, {
  AutomatModell,
} from "service/data-service/kaufanfragen-controller/interface/AutomatModell";
import KaufanfrageStatusResult, {
  KaufanfrageStatus,
} from "service/data-service/kaufanfragen-controller/interface/KaufanfrageStatus";
import KaufanfragenService from "service/data-service/kaufanfragen-controller/Kaufanfragen.Service";
import {
  EnumeratorItemSourceType,
  EnumItemBuilder,
  EnumItemsExtractFn,
} from "../use-enumerator";

const kaufanfragenStatusBuilder: EnumItemBuilder = (
  raw: EnumeratorItemSourceType
) => {
  const rawItem = raw as KaufanfrageStatus;
  return {
    id: rawItem.key,
    value: rawItem.value,
    source: raw,
  } as EnumeratorItem;
};

export const onKaufanfragenStatusResult: EnumItemsExtractFn = (
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  KaufanfragenService.status(
    (kaufanfrageStatusResult: KaufanfrageStatusResult) =>
      onEnumSyncSuccess(kaufanfrageStatusResult.Row, kaufanfragenStatusBuilder),
    (err: Error) => onEnumSyncError("Syncing Kaufanfragen STATUS failed", err)
  );
};

const kaufanfragenAutomatModellBuilder: EnumItemBuilder = (
  raw: EnumeratorItemSourceType
) => {
  const rawItem = raw as AutomatModell;
  return {
    id: rawItem.key,
    value: rawItem.name,
    source: raw,
  } as EnumeratorItem;
};

export const onKaufanfragenAutomatModellResult: EnumItemsExtractFn = (
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  KaufanfragenService.modell(
    (result: AutomatModellResult) =>
      onEnumSyncSuccess(result.Row, kaufanfragenAutomatModellBuilder),
    (err: Error) =>
      onEnumSyncError("Syncing Kaufanfragen Automat Modell failed", err)
  );
};
