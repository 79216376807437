import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { AutomatTransponderInfo } from "global/hook/transponder/interface/Transponder";
import { useTransponder } from "global/hook/transponder/use-transponder";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { HREF_AUTOMATENSUCHE } from "global/util/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IpBereichService from "service/data-service/automate-controller/IpBereich.service";
import IpBereich from "service/data-service/automate-controller/interface/IpBereich";
import ExportService from "service/report-service/export/Export.service";
import IpRangeColumnDefs from "./grid-column-defs";

const IpRange: React.FC = () => {
  const windowViewport = useWindowViewport(0, 268);
  const transponderCtrl = useTransponder()[1];
  const navigate = useNavigate();
  const [selection, setSelection] = useState<IpBereich>();
  //Navigate to Rechnungsdaten ATH search positions page
  const navigateToAutomatSearch = (entry: IpBereich) => {
    if (entry === selection) {
      transponderCtrl.setTransponderData({
        ipAddress: entry.ipRange,
      } as AutomatTransponderInfo);
      navigate(HREF_AUTOMATENSUCHE);
    }
  };

  return (
    <Stack className="ipRange">
      <Grid container spacing={5} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>IP Bereiche</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={IpRangeColumnDefs}
            exportDef={{ action: ExportService.ipRange }}
            onRowSelected={setSelection}
            onRowClicked={(entry: IpBereich) => navigateToAutomatSearch(entry)}
            rowsDataDef={{
              uncontrolledDataFetchCall: IpBereichService.load,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default IpRange;
