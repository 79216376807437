import { ColDef } from "ag-grid-community";

const IpWhitelistColumnDefs: Array<ColDef> = [
  {
    headerName: "Host",
    field: "host",
    minWidth: 100,
  },
  {
    headerName: "Benutzername",
    field: "username",
    minWidth: 100,
  },
  {
    headerName: "Beschreibung",
    field: "description",
    minWidth: 200,
  },
];

export default IpWhitelistColumnDefs;
