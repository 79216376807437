import HttpService from "App/service/http.service";
import Config from "Config";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import DsmdStatusDTO from "./dto/DsmdStatusDTO";

const DsmdService = {
  getDsmdStatus: (
    automatkey: string,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.btbApiUrl}info/getAllInfoByAutomateKey/${automatkey}`)
      .then((response) => {
        callbackOnSuccess(DsmdStatusDTO.convertResponseToDsmdStatus(response));
      })
      .catch((error) => {
        if (callbackOnFail) {
          callbackOnFail(error);
          // @TODO fire error message
        }
      });
  },
  getFailedDsmd: (handler: ServiceResponseHandler) => {
    HttpService.api
      .sendGet(`${Config.btbApiUrl}info/getDsmdFailedForRegionAndRole`)
      .then((response) => {
        handler.callbackOnSuccess(
          DsmdStatusDTO.convertAutomatDatenDsmd(response)
        );
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error);
      });
  },
};

export default DsmdService;
