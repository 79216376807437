import HttpService from "App/service/http.service";
import Config from "Config";
import EditHistoryDTO from "./dto/EditHistoryDTO";

const HistroryService = {
  loadByAutomatkey: (
    automatkey: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}historie/${automatkey}`)
      .then((response) => {
        callbackOnSuccess(EditHistoryDTO.convertEditHistoryResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default HistroryService;
