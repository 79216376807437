import HttpService from "App/service/http.service";
import Config from "Config";
import DPGStammdatenDTO from "./dto/DPGStammdatenDTO";
import AutomatenblattStammdaten from "./interface/AutomatenblattStammdaten";
import { AxiosResponseToStammdatenDTOMapper } from "./mapping/AxiosResponseToStammdatenDTOMapper";

const StammdatenService = {
  retrieveStammdatenInformation: (
    type: string,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "stammdaten/" + type)
      .then((response) => {
        const data: AutomatenblattStammdaten =
          AxiosResponseToStammdatenDTOMapper.map(response);
        callbackOnSuccess(data);
      })
      .catch((error) => {
        callbackOnFail(error);
      });
  },
  getDpgStammdaten: (
    automatKey: String,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "dpg/" + automatKey)
      .then((response) => {
        callbackOnSuccess(
          DPGStammdatenDTO.convertResponseToDPGStammdatenDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) {
          callbackOnFail(error);
        } else {
          console.warn(error);
          // @TODO fire error message
        }
      });
  },
  manufaturerAutomatModels: (
    manufacturer: string,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}ath/modell/${manufacturer}`)
      .then((response) => {
        callbackOnSuccess(response.data);
      })
      .catch((error) => {
        callbackOnFail(error);
      });
  },
};

export default StammdatenService;
