import { Grid, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DataGridRowsDataDef from "global/components/UI/DataGrid/interface/DataGridRowsDataDef";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import useModalController, {
  useModalDialog,
} from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useEffect, useState } from "react";
import PositionService from "service/sap-service/Position.service";
import SapInputParam from "service/sap-service/interface/SapInputParam";
import SapOutputParam, {
  PositionDTO,
} from "service/sap-service/interface/SapOutputParam";
import PositionOhneGlnDetailsDialogContent from "./component/PositionOhneGlnDetailsDialogContent";
import GLNOhneZuordnungColumnDefs from "./grid-column-defs";

import "./glnohnezuordnung.scss";

const GLNOhneZuordnung = () => {
  const modals = useModalController();
  const [dialog, dialogController] = useModalDialog("regionen-zuordnen");
  const windowViewport = useWindowViewport(0, 144);
  const [gridData, setGridData] = useState<DataGridRowsDataDef<PositionDTO>>({
    isFetchingData: false,
  });

  let outParams = {} as SapInputParam;

  const rowClickHandler = (entry: PositionDTO) => {
    outParams = { kundeGln: entry.kundeGln } as SapInputParam;
    dialogController.showDialog({
      message: "",
      title: "RNS-GLN zuordnen",
      content: (
        <PositionOhneGlnDetailsDialogContent
          entry={entry}
          onContentChange={updateOutParams}
        />
      ),
      onOkClick: savePositionRnsGLN,
      okCaption: "Speichern",
      onAbortClick: () => {},
      abortCaption: "Abbrechen",
    });
  };

  const onBeginFetchingRecords = () => {
    setGridData({
      ...gridData,
      isFetchingData: true,
    });
  };

  const onEndFetchingRecords = (data?: Array<PositionDTO>) => {
    setGridData({
      data: data ? [...data] : gridData.data,
      isFetchingData: false,
    });
  };

  const reloadRecords = () => {
    onBeginFetchingRecords();
    PositionService.getPositionenByUserUnallocated(
      {} as SapInputParam,
      (sapOutput: SapOutputParam) =>
        onEndFetchingRecords(sapOutput.positionEdits),
      onEndFetchingRecords
    );
  };

  useEffect(
    () => reloadRecords(), // eslint-disable-next-line
    []
  );

  const updateOutParams = (changes: SapInputParam) => {
    outParams = {
      ...outParams,
      ...changes,
    };
  };

  const savePositionRnsGLN = () => {
    if (!outParams.rnsGlnChanged && outParams.knzNotEpcom === undefined) {
      return;
    }
    onBeginFetchingRecords();
    PositionService.savePositionRnsGln(
      outParams,
      (response: SapOutputParam) => {
        if (response.errorTyp === "NO_ERROR") {
          reloadRecords();
          modals.showMessageDialog("Erfolgreich gespeichert");
        } else {
          modals.showMessageDialog(
            response.message ?? "Internal error",
            undefined,
            "Fehler"
          );
          onEndFetchingRecords();
        }
      },
      onEndFetchingRecords
    );
  };

  return (
    <>
      <Grid container spacing={5} width={"auto"}>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={GLNOhneZuordnungColumnDefs}
            rowsDataDef={gridData}
            onRowClicked={rowClickHandler}
            gridActions={
              <Typography variant={"h1"}>
                Rechnungsdaten ATH - GLN's ohne Zuordnung
              </Typography>
            }
          />
        </Grid>
      </Grid>
      {dialog && <ModalMessageDialog {...dialog} />}
    </>
  );
};

export default GLNOhneZuordnung;
