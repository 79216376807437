import HttpService from "App/service/http.service";
import Config from "Config";
import NewAutomatDTO from "./dto/NewAutomatDTO";
import AutomatenUpdateRequest from "./interface/AutomatenUpdateRequest";
import AutomatenblattInformation from "./interface/AutomatenblattInformation";
import NewAutomat from "./interface/NewAutomat";
import AxiosResponseToAutomatenblattInformationDTOMapper from "./mapper/AxiosResponseToAutomatenblattInformationDTOMapper";

const AutomatenblattInformationService = {
  retrieveAutomatenInformation: (
    automatkey: string,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "automate/" + automatkey)
      .then((response) => {
        const ResponseDTO: AutomatenblattInformation =
          AxiosResponseToAutomatenblattInformationDTOMapper.map(response);
        callbackOnSuccess(ResponseDTO);
      })
      .catch((error) => {
        callbackOnFail(error);
      });
  },
  update: (
    automatUpdate: AutomatenUpdateRequest,
    callbackOnSuccess?: () => void
  ) => {
    HttpService.api
      .sendJsonDataPost(
        Config.dataServiceUrl + "automate/update",
        automatUpdate
      )
      .then((response) => {
        // @TODO fire succes message
        if (callbackOnSuccess) callbackOnSuccess();
      })
      .catch((error) => {
        console.warn(error);
        // @TODO fire error message
      });
  },
  create: (
    automat: NewAutomat,
    callbackOnSuccess?: Function,
    callbackOnFail?: Function,
    callbackOnRemoteError?: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(
        Config.dataServiceUrl + "automate/createautomat",
        automat,
        undefined,
        callbackOnRemoteError
      )
      .then((response) => {
        if (callbackOnSuccess)
          callbackOnSuccess(
            NewAutomatDTO.convertResponseToNewAutomatDTO(response)
          );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
        // @TODO fire error message
      });
  },
};

export default AutomatenblattInformationService;
