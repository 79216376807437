import Enumerator, {
  EnumeratorID,
  EnumeratorItem,
  EnumeratorStatus,
  EnumeratorSyncFunction,
} from "global/components/EnumeratedValues/Enumerator";
import { noop } from "global/util/utils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AutomatModell } from "service/data-service/kaufanfragen-controller/interface/AutomatModell";
import { KaufanfrageStatus } from "service/data-service/kaufanfragen-controller/interface/KaufanfrageStatus";
import { Stammdaten } from "service/data-service/stammdaten-controller/interface/AutomatenblattStammdaten";
import { EpcomGroup } from "service/data-service/user-controller/interface/EpcomUserGroupAutomat";
import UnterWarengruppe from "service/sap-service/interface/UnterWarengruppe";
import Warengruppe from "service/sap-service/interface/Warengruppe";
import useSnackbarGenerator from "../../../App/hook/use-snackbars";
import { onEpcomGroupsResult } from "./builders/epcomBuilder";
import {
  onKaufanfragenAutomatModellResult,
  onKaufanfragenStatusResult,
} from "./builders/kaufanfragenBuilders";
import { onRnsGlnListResult } from "./builders/resyncBuilders";
import {
  onAutomatModellResult,
  onStammdatenResult,
} from "./builders/stammdatenBuilder";
import {
  onUnterWarengruppeResult,
  onWarengruppeResult,
} from "./builders/warengruppeBuilders";

export const AUTOMATENBLATT_STANDORT_ENUM_ID = "AUTOMATSTANDORT";
export const AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID = "SORTIERKONFIG";
export const AUTOMATENBLATT_KOMMFORM_ENUM_ID = "TKART";
export const AUTOMATENBLATT_KOMMART_ENUM_ID = "PROTOKOLLTYP";
export const AUTOMATENBLATT_CLEARER_ENUM_ID = "CLEARER";
export const AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID = "DATENSPEDITEUR";
export const AUTOMATENBLATT_NOTIZTYP_ENUM_ID = "NOTIZTYP";
export const AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID = "NOTIZSTATUS";
export const AUTOMATENBLATT_LOGFILEKATEGORIE_ENUM_ID = "LOGFILEKATEGORIE";
export const AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID =
  "AUTOMATSTATUSZUKUNFT";
export const AUTOMATENBLATT_AUTOMATART_ENUM_ID = "AUTOMATART";

export const AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID = "AUTOMATSTATUS";
export const AUTOMATENBLATT_AUTOMATMODELLE_ENUM_ID = "AUTOMATMODELLE";
export const AUTOMATENBLATT_AUTOMATMODELLE_REGION_ENUM_ID =
  "AUTOMATMODELLE_REGION";
export const AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID =
  "AUTOMATHERSTELLER_REGION";
export const AUTOMATENBLATT_DPGAUTOMAT_ENUM_ID = "DPGAUTOMAT";
export const AUTOMATENBLATT_RUECKNEHMER_ENUM_ID = "RUECKNEHMER";
export const AUTOMAT_DPG_KATEGORIE_ENUM_ID = "DPG_KATEGORIE";
export const AUTOMAT_IT_SICHERHEITSKONZEPT_ENUM_ID = "IT_SICHERHEITSKONZEPT";
export const AUTOMAT_ALE_VERSION_ENUM_ID = "ALE_VERSION";
export const AUTOMAT_GEPLANTE_UMSTELLUNG_ENUM_ID = "GEPLANTE_UMSTELLUNG";

export const AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID = "KAUFANFRAGENSTATUS";
export const AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID = "KAUFANFRAGENMODELL";
export const RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID = "WARENGRUPPE";
export const RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID = "UNTERWARENGRUPPE";

export const VISIBLE_AND_CURRENT_EPCOM_GROUPS = "VisibleAndCurrentEpcomGroups";

export const RNS_GLN_LIST = "RNSGLNLIST";

const ALL_ENUMERATOR_IDS = [
  AUTOMATENBLATT_STANDORT_ENUM_ID,
  AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID,
  AUTOMATENBLATT_KOMMFORM_ENUM_ID,
  AUTOMATENBLATT_KOMMART_ENUM_ID,
  AUTOMATENBLATT_CLEARER_ENUM_ID,
  AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID,
  AUTOMATENBLATT_NOTIZTYP_ENUM_ID,
  AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID,
  AUTOMATENBLATT_LOGFILEKATEGORIE_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID,
  AUTOMATENBLATT_AUTOMATART_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID,
  AUTOMATENBLATT_AUTOMATMODELLE_ENUM_ID,
  AUTOMATENBLATT_AUTOMATMODELLE_REGION_ENUM_ID,
  AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID,
  AUTOMATENBLATT_DPGAUTOMAT_ENUM_ID,
  AUTOMATENBLATT_RUECKNEHMER_ENUM_ID,
  AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID,
  AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID,
  RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID,
  RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID,
  VISIBLE_AND_CURRENT_EPCOM_GROUPS,
  RNS_GLN_LIST,
  AUTOMAT_DPG_KATEGORIE_ENUM_ID,
  AUTOMAT_IT_SICHERHEITSKONZEPT_ENUM_ID,
  AUTOMAT_ALE_VERSION_ENUM_ID,
  AUTOMAT_GEPLANTE_UMSTELLUNG_ENUM_ID,
] as const;

export type Enumerators = Array<Enumerator>;

let globalEnumerators: Enumerators = ALL_ENUMERATOR_IDS.map((id) => {
  return { id: id, status: "INITIAL", items: [], syncFN: noop };
});
let listeners = new Map<
  EnumeratorID,
  Array<Dispatch<SetStateAction<Enumerators>>>
>();

const broadcastEnumeratorStateChange = (enumeratorKey: EnumeratorID) => {
  const enumListeners = listeners.get(enumeratorKey) ?? [];
  for (const listener of enumListeners) {
    listener(globalEnumerators);
  }
};

let rnsGlnListFilter: string = "";

const updateEnumerator = (
  enumeratorID: EnumeratorID,
  enumStatus: EnumeratorStatus,
  enumeratorItems?: Array<EnumeratorItem>,
  syncFN?: EnumeratorSyncFunction
) => {
  const index = globalEnumerators.findIndex((e) => e.id === enumeratorID);
  const updatedEnumerators = [...globalEnumerators];
  updatedEnumerators[index] = {
    id: enumeratorID,
    status: enumStatus,
    items: enumeratorItems ?? globalEnumerators[index].items,
    syncFN: syncFN ?? globalEnumerators[index].syncFN,
  };
  globalEnumerators = updatedEnumerators;
  broadcastEnumeratorStateChange(enumeratorID);
};

export type EnumeratorItemSourceType =
  | Stammdaten
  | KaufanfrageStatus
  | AutomatModell
  | Warengruppe
  | UnterWarengruppe
  | EpcomGroup
  | string;
type EnumSourceArray = Array<EnumeratorItemSourceType>;
export type EnumItemBuilder = (raw: EnumeratorItemSourceType) => EnumeratorItem;
export type EnumItemsExtractFn = Function;

const extractEnumeratorItems = (
  sourceItems: EnumSourceArray,
  itemBuilder: EnumItemBuilder
): Array<EnumeratorItem> => {
  return sourceItems.map((i) => {
    return itemBuilder(i);
  });
};

export const useEnumerator = (
  enumeratorID: EnumeratorID,
  canResyncWithFilter = false
) => {
  const setState = useState(globalEnumerators)[1];
  const { showError } = useSnackbarGenerator();

  const enumIndex = globalEnumerators.findIndex((e) => e.id === enumeratorID);

  if (globalEnumerators[enumIndex].syncFN === noop) {
    const onEnumSyncSuccess = (
      rawItems: EnumSourceArray,
      itemBuilder: EnumItemBuilder
    ) => {
      updateEnumerator(
        enumeratorID,
        "SYNC",
        extractEnumeratorItems(rawItems, itemBuilder)
      );
    };

    const onEnumSyncError = (message: string, error: Error) => {
      updateEnumerator(enumeratorID, "INITIAL", []);
      showError(message, { error: error });
    };

    const syncEnumerator = (filter?: string) => {
      if (
        !canResyncWithFilter &&
        globalEnumerators[enumIndex].status === "SYNC"
      ) {
        return;
      }
      updateEnumerator(enumeratorID, "PENDING");

      switch (enumeratorID) {
        case AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID:
          onKaufanfragenStatusResult(onEnumSyncSuccess, onEnumSyncError);
          break;
        case AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID:
          onKaufanfragenAutomatModellResult(onEnumSyncSuccess, onEnumSyncError);
          break;
        case RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID:
          onWarengruppeResult(onEnumSyncSuccess, onEnumSyncError);
          break;
        case RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID:
          onUnterWarengruppeResult(onEnumSyncSuccess, onEnumSyncError);
          break;
        case VISIBLE_AND_CURRENT_EPCOM_GROUPS:
          onEpcomGroupsResult(onEnumSyncSuccess, onEnumSyncError);
          break;
        case RNS_GLN_LIST:
          onRnsGlnListResult(
            filter ?? "",
            rnsGlnListFilter,
            globalEnumerators[enumIndex].items,
            (rawItems: EnumSourceArray, itemBuilder: EnumItemBuilder) => {
              rnsGlnListFilter = filter ?? "";
              onEnumSyncSuccess(rawItems, itemBuilder);
            },
            (message: string, error: Error) => {
              rnsGlnListFilter = "";
              onEnumSyncError(message, error);
            }
          );
          break;
        case AUTOMATENBLATT_AUTOMATMODELLE_ENUM_ID:
          onAutomatModellResult(
            filter ?? "",
            onEnumSyncSuccess,
            onEnumSyncError
          );
          break;
        default:
          onStammdatenResult(enumeratorID, onEnumSyncSuccess, onEnumSyncError);
      }
    };

    updateEnumerator(enumeratorID, "INITIAL", [], syncEnumerator);
  }

  useEffect(() => {
    const enumListeners = listeners.get(enumeratorID) ?? [];
    enumListeners.push(setState);
    listeners.set(enumeratorID, enumListeners);

    const enumerator = globalEnumerators[enumIndex];
    const forceSyncing = canResyncWithFilter ?? false;
    if (forceSyncing || enumerator.status === "INITIAL") {
      enumerator.syncFN();
    }

    return () => {
      let enumListeners = listeners.get(enumeratorID);
      enumListeners = enumListeners!.filter((li) => li !== setState);
      if (enumListeners.length === 0) {
        listeners.delete(enumeratorID);
      }
    }; // eslint-disable-next-line
  }, [setState]);

  return globalEnumerators[enumIndex];
};
