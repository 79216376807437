import { ColDef } from "ag-grid-community";

const RegionenZuordnenColumnDefs: Array<ColDef> = [
  {
    headerName: "Anzahl",
    field: "anzahlPositionen",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    headerName: "RN-GLN",
    field: "rnGln",
    minWidth: 190,
  },
  {
    headerName: "RNS-PLZ",
    field: "rnsPlz",
    minWidth: 130,
  },
  {
    headerName: "RNS-Ort",
    field: "rnsOrt",
    minWidth: 300,
  },
  {
    headerName: "RNS-Strasse",
    field: "rnsStrasse",
    minWidth: 330,
  },
  {
    headerName: "RNS-Name",
    field: "rnsName",
    minWidth: 330,
  },
  {
    headerName: "Region",
    field: "region",
    minWidth: 90,
  },
];

export default RegionenZuordnenColumnDefs;
