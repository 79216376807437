import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SteckbriefProps, pageStyles } from "./PdfSteckbriefContent";
import PdfRepairHistorySection from "./component/PdfRepairHistorySection";
import PdfRowItemHeader from "./component/PdfRowItemHeader";
import PdfRowTextItem from "./component/PdfRowTextItem";

const styles = StyleSheet.create({
  column: {
    width: "13%",
    fontSize: 6,
    textAlign: "center",
    paddingTop: 1,
  },
  rowHeader: {
    width: "9%",
    fontSize: 6,
    marginTop: 3,
  },
  rowHeaderContent: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  tableContentMargin: {
    marginLeft: 1,
    marginRight: 1,
    marginBottom: 1,
  },
});
const PdfRepairHistoryContractStatusDataItem = (props: SteckbriefProps) => {
  return (
    <View>
      <PdfRowItemHeader mainInfo="Reparaturhistorie/ Vertragsstand"></PdfRowItemHeader>
      <PdfRowTextItem header="Vertragsstand" info=" "></PdfRowTextItem>
      <View style={pageStyles.contentRow}>
        <View style={[pageStyles.tableContent, styles.tableContentMargin]}>
          <View style={pageStyles.tableRow}>
            <View style={styles.rowHeader}>
              <Text> </Text>
              <Text> </Text>
              <Text
                style={[pageStyles.tableCellBorderTop, styles.rowHeaderContent]}
              >
                letzten 12 Monate
              </Text>
              <Text
                style={[pageStyles.tableCellBorderTop, styles.rowHeaderContent]}
              >
                letzten 24/Monate
              </Text>
            </View>
            <PdfRepairHistorySection
              name="Reparatur Vordergrund"
              data={["", "", "", ""]}
            ></PdfRepairHistorySection>
            <PdfRepairHistorySection
              name="Reparatur Hintergrund"
              data={["", "", "", ""]}
            ></PdfRepairHistorySection>
            <PdfRepairHistorySection
              name="Dienstleistung"
              data={["", "", "", ""]}
            ></PdfRepairHistorySection>
            <PdfRepairHistorySection
              name="Verbrauchsmaterial"
              data={["", "", "", ""]}
            ></PdfRepairHistorySection>
            <PdfRepairHistorySection
              name="Neuanschaffung"
              data={["", "", "", ""]}
            ></PdfRepairHistorySection>
            <PdfRepairHistorySection
              name="nicht zugeordnet"
              data={["", "", "", ""]}
            ></PdfRepairHistorySection>
            <PdfRepairHistorySection
              name="keine"
              data={["", "", "", ""]}
            ></PdfRepairHistorySection>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfRepairHistoryContractStatusDataItem;
