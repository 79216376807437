import HttpService from "App/service/http.service";
import Config from "Config";
import AccessDTO from "./dto/AccessDTO";
import MaintenanceInfoDTO from "./dto/MaintenanceInfoDTO";
import { MaintenanceInfo } from "./interface/Access";

const AccessService = {
  retrieveAccess: async () => {
    const response = await HttpService.api.sendGet(
      `${Config.dataServiceUrl}ui/access/getAccess`
    );
    return AccessDTO.convertAccessResponse(response);
  },
  retrieveMaintenanceInfo: async () => {
    const response = await HttpService.api.sendGet(
      `${Config.dataServiceUrl}maintenance/load`
    );
    return MaintenanceInfoDTO.convertMaintenanceResponse(response);
  },
  updateMaintenanceInfo: async (info: MaintenanceInfo) => {
    const response = await HttpService.api.sendJsonDataPost(
      `${Config.dataServiceUrl}maintenance/update`,
      info
    );
    return MaintenanceInfoDTO.convertMaintenanceResponse(response);
  },
};

export default AccessService;
