import { useState } from "react";
import TransponderController, {
  AutomatTransponderInfo,
} from "./interface/Transponder";

let transponderData: AutomatTransponderInfo | undefined;

export function useTransponder(): [
  AutomatTransponderInfo | undefined,
  TransponderController
] {
  const setAutomatTransponderInfo = useState<AutomatTransponderInfo>()[1];

  const setTransponderData = (data: AutomatTransponderInfo | undefined) => {
    transponderData = data;
    setAutomatTransponderInfo(data);
  };
  return [
    transponderData,
    {
      setTransponderData: setTransponderData,
      clearData: setTransponderData.bind(null, undefined),
    },
  ];
}
