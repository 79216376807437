import { EpcomResponse } from "App/service/http.service";
import NewAutomat from "../interface/NewAutomat";

const NewAutomatDTO = {
  convertResponseToNewAutomatDTO: (response: EpcomResponse): NewAutomat => {
    return {
      ...response.data,
    };
  },
};
export default NewAutomatDTO;
