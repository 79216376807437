import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import AutomatenblattInformation, {
  AutomatCredentials,
  DeploymentInfoType,
  KommTypen,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatChangeInfo, {
  RecordChangeMergerFunction,
} from "../interface/AutomatChangeInfo";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const TRACK_CHANGE = "TRACK CHANGE";
export const CLEAR_CHANGES = "CLEAR CHANGES";
export const SAP_BON_NUMMER_PATH = "sapBonNummerDTO.bonNummer";
export const SAP_BON_NUMMER_INFO_PATH = "sapBonNummerDTO.info";
export const SAP_BON_NUMMER_GUELTIG_PATH = "sapBonNummerDTO.gueltigVon";
export const AUTOMAT_STATUS_IN_ZUKUNFT_PATH = "automatStatusInZukunft";
export const AUTOMAT_STATUS_GUELTIG_VON_IN_ZUKUNFT_PATH =
  "automatStatusGueltigVonInZukunft";

const mergePathElements = (
  changePath: string,
  mergeForRequest = false
): Array<string> => {
  if (mergeForRequest) {
    switch (changePath) {
      case SAP_BON_NUMMER_PATH:
        return ["rnsBonNummer"];
      case SAP_BON_NUMMER_INFO_PATH:
        return ["rnsBonNummerInfo"];
      case SAP_BON_NUMMER_GUELTIG_PATH:
        return ["rnsBonNummerGueltigVon"];
      case "modell":
        return ["modellKey"];
      case "artKey":
        return ["art"];
      case "tkClearer":
        return ["tkDaten", "tkClearer"];
    }
    if (changePath.startsWith("tkDaten.")) {
      const key = changePath.split(".")[1];
      switch (key) {
        case "ipV4Lan":
          return ["tkDaten", "ipLan4"];
        case "stdGatewayV4":
          return ["tkDaten", "ipGateway4"];
        case "subnetzV4":
          return ["tkDaten", "ipSubnetz4"];
      }
    }
  }

  return changePath.split(".");
};

export const directPathMerger: RecordChangeMergerFunction = (
  data,
  change,
  mergeForRequest = false
) => {
  let changeWrapper: Record<string, any> = {};
  const pathElements = mergePathElements(change.path, mergeForRequest);
  let referenceValues: Array<any> = [data];
  pathElements.forEach((pathElement, ix) => {
    referenceValues.push(referenceValues[ix][pathElement] ?? {});
  });
  const key = pathElements[pathElements.length - 1];

  for (let index = pathElements.length; index > 0; index--) {
    const pathKey = pathElements[index - 1];
    const pathValue = referenceValues[index];

    if (pathKey === key) {
      changeWrapper[key] = change.value;
    } else {
      let parentWrapper: Record<string, any> = {};
      parentWrapper[pathKey] = {
        ...pathValue,
        ...changeWrapper,
      };
      changeWrapper = { ...parentWrapper };
    }
  }

  return {
    ...data,
    ...changeWrapper,
  };
};

export const automatCredentialsChangeMerger: RecordChangeMergerFunction = (
  data,
  change,
  mergeForRequest = false
) => {
  if (mergeForRequest) {
    return automatCredentialsUpdateRequestMerger(
      data as AutomatenUpdateRequest,
      change
    );
  }
  return credentialsAutomatblatInfoChangeMerger(
    data as AutomatenblattInformation,
    change
  );
};

const credentialsAutomatblatInfoChangeMerger = (
  data: AutomatenblattInformation,
  change: AutomatChangeInfo
) => {
  const typeComponents = change.path.split(":");
  const typeId = typeComponents[1];
  const pathComponents = change.path.split(".");
  const key = pathComponents[pathComponents.length - 1];

  const changeWrapper: Record<string, string> = {};
  changeWrapper[key] = change.value;

  const credentialsIndex = data.automatCredentialList.findIndex(
    (e) => e.kommTyp.id === typeId
  );

  const changedRecord =
    key === "delete"
      ? ({
          kommTyp: { id: typeId },
        } as AutomatCredentials)
      : {
          ...data.automatCredentialList[credentialsIndex],
          ...changeWrapper,
        };

  const credentialsList = [...data.automatCredentialList];
  credentialsList[credentialsIndex] = changedRecord;

  return {
    ...data,
    automatCredentialList: credentialsList,
  };
};

const automatCredentialsUpdateRequestMerger = (
  data: AutomatenUpdateRequest,
  change: AutomatChangeInfo
) => {
  const typeComponents = change.path.split(":");
  const typeId = typeComponents[1];
  const pathComponents = change.path.split(".");
  const key = pathComponents[pathComponents.length - 1];

  const changeWrapper: Record<string, string> = {};
  changeWrapper[key] = change.value;

  const credentialsList = [...(data.kommTypen ?? [])];

  let credentialsIndex = credentialsList.findIndex((e) => e.kommTyp === typeId);

  if (credentialsIndex === -1) {
    credentialsIndex = credentialsList.length;
    credentialsList.push({ kommTyp: typeId });
  }

  const changedRecord =
    key === "delete"
      ? {
          kommTyp: typeId,
          delete: true,
        }
      : {
          ...credentialsList[credentialsIndex],
          ...changeWrapper,
        };

  credentialsList[credentialsIndex] = changedRecord;

  return {
    ...data,
    kommTypen: credentialsList,
  };
};

export const tkDetailsChangeMerger: RecordChangeMergerFunction = (
  data,
  change,
  mergeForRequest = false
) => {
  if (mergeForRequest) {
    return tkDetailsUpdateRequestMerger(data as AutomatenUpdateRequest, change);
  }
  return tkDetailsAutomatblatInfoChangeMerger(
    data as AutomatenblattInformation,
    change
  );
};

export const deploymentInfoChangeMerger: RecordChangeMergerFunction = (
  data,
  change,
  mergeForRequest = false
) => {
  if (mergeForRequest) {
    return deploymentInfoUpdateRequestMerger(
      data as AutomatenUpdateRequest,
      change
    );
  }
  return deploymentInfoAutomatblatInfoChangeMerger(
    data as AutomatenblattInformation,
    change
  );
};

const tkDetailsAutomatblatInfoChangeMerger = (
  data: AutomatenblattInformation,
  change: AutomatChangeInfo
): AutomatenblattInformation => {
  const typeComponents = change.path.split(":");
  const typeId = typeComponents[1];
  const pathComponents = change.path.split(".");
  const key = pathComponents[pathComponents.length - 1];

  const changeWrapper: Record<string, string> = {};
  changeWrapper[key] = change.value;

  const detailsIndex = data.kommTypen.findIndex((e) => e.kommTyp === typeId);

  const changedRecord =
    key === "delete"
      ? ({} as KommTypen)
      : {
          ...data.kommTypen[detailsIndex],
          ...changeWrapper,
        };

  const detailsList = [...data.kommTypen];
  detailsList[detailsIndex] = changedRecord;

  return {
    ...data,
    kommTypen: detailsList,
  };
};

const tkDetailsUpdateRequestMerger = (
  data: AutomatenUpdateRequest,
  change: AutomatChangeInfo
): AutomatenUpdateRequest => {
  const typeComponents = change.path.split(":");
  const typeId = typeComponents[1];
  const pathComponents = change.path.split(".");
  const key = pathComponents[pathComponents.length - 1];

  const changeWrapper: Record<string, string> = {};
  changeWrapper[key] = change.value;

  const detailsList = [...(data.kommTypen ?? [])];
  let detailsIndex = detailsList.findIndex((e) => e.kommTyp === typeId);

  if (detailsIndex === -1) {
    detailsIndex = detailsList.length;
    detailsList.push({ kommTyp: typeId });
  }
  const changedRecord =
    key === "delete"
      ? {
          kommTyp: typeId,
          delete: true,
        }
      : {
          ...detailsList[detailsIndex],
          ...changeWrapper,
        };

  detailsList[detailsIndex] = changedRecord;

  return {
    ...data,
    kommTypen: detailsList,
  };
};

const deploymentInfoAutomatblatInfoChangeMerger = (
  data: AutomatenblattInformation,
  change: AutomatChangeInfo
): AutomatenblattInformation => {
  const typeComponents = change.path.split(":");
  const typeId = typeComponents[1] as DeploymentInfoType;
  const pathComponents = change.path.split(".");
  const key = pathComponents[pathComponents.length - 1];

  const changeWrapper: Record<string, string> = {};
  changeWrapper[key] = change.value;

  const detailsIndex = data.deploymentInfo.findIndex((e) => e.type === typeId);

  const changedRecord = {
    ...data.deploymentInfo[detailsIndex],
    ...changeWrapper,
  };

  const detailsList = [...data.deploymentInfo];
  detailsList[detailsIndex] = changedRecord;

  return {
    ...data,
    deploymentInfo: detailsList,
  };
};

const deploymentInfoUpdateRequestMerger = (
  data: AutomatenUpdateRequest,
  change: AutomatChangeInfo
): AutomatenUpdateRequest => {
  const typeComponents = change.path.split(":");
  const typeId = typeComponents[1] as DeploymentInfoType;
  const pathComponents = change.path.split(".");
  const key = pathComponents[pathComponents.length - 1];

  const changeWrapper: Record<string, string> = {};
  changeWrapper[key] = change.value;

  const detailsList = [...(data.deploymentInfo ?? [])];
  let detailsIndex = detailsList.findIndex((e) => e.type === typeId);

  if (detailsIndex === -1) {
    detailsIndex = detailsList.length;
    detailsList.push({ type: typeId });
  }
  const changedRecord = {
    ...detailsList[detailsIndex],
    ...changeWrapper,
  };

  detailsList[detailsIndex] = changedRecord;

  return {
    ...data,
    deploymentInfo: detailsList,
  };
};

const configureChangeTrackStore = () => {
  const trackChange: AutomatAction = {
    identifier: TRACK_CHANGE,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      let changes: Array<AutomatChangeInfo> = [...(currentState.changes ?? [])];

      const change = payload.change;
      const refValue = change.refValue ?? "";
      const newValue = change.value ?? "";

      if (newValue === refValue) {
        // remove change tracking record
        changes = changes.filter((ch) => ch.path !== change.path);
      } else {
        // add or update the change tracking record
        const changeIndex = changes.findIndex((ch) => ch.path === change.path);
        if (changeIndex === -1) {
          changes.push(change);
        } else {
          changes[changeIndex] = change;
        }
      }
      callback({
        changes: changes,
      } as AutomatDataRecord);
    },
  };
  const clearChanges: AutomatAction = {
    identifier: CLEAR_CHANGES,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      let changes: Array<AutomatChangeInfo> = [];
      callback({
        changes: changes,
      } as AutomatDataRecord);
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([
    trackChange,
    clearChanges,
  ]);
};

export default configureChangeTrackStore;
