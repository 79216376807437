import { Grid } from "@mui/material"
import DataGrid from "global/components/UI/DataGrid/DataGrid"
import ServiceResponseHandler from "service/interface/ServiceResponseHandler"
import AnlagenService from "service/sap-service/Anlagen.service"
import SapInputParam from "service/sap-service/interface/SapInputParam"
import SapOutputParam from "service/sap-service/interface/SapOutputParam"
import AnlageListController from "./AnlageListController"
import AnlageListColumnDefs from "./grid-column-defs"

export interface AnlageListDialogContentProps {
    regulierungsBeleg: string,
    anlageSelectHandler: (regulierungsBeleg: string, anlage: string) => void
}

const AnlageListDialogContent = (props: AnlageListDialogContentProps) => {

    const gridDataFetchCall = (handler: ServiceResponseHandler) => {
        AnlagenService.getAnlagenListe(
            {
                regulierungsBeleg: props.regulierungsBeleg
            } as SapInputParam,
            (response: SapOutputParam) => {
                handler.callbackOnSuccess(AnlageListController.convertResponseAnlage(response, props.anlageSelectHandler.bind(null, props.regulierungsBeleg)));
            },
            handler.callbackOnFail
        )
    }
    return (
        <>
            <Grid container width={330}>
                <Grid item xs={12}>
                    <DataGrid
                        height={300}
                        rowHeight={44}
                        columnDefs={AnlageListColumnDefs}
                        rowsDataDef={{
                            uncontrolledDataFetchCall: gridDataFetchCall,
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default AnlageListDialogContent
