import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import TextValue from "global/components/UI/TextValue/TextValue";
import UIButton from "global/components/UI/UIButton/UIButton";
import { FETCH_AUTOMATTENBLATT_INFORMATION } from "global/hook/datastore/automat/automatenblatt-information-store";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useEffect, useState } from "react";
import { Rns } from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import RnsGln from "service/data-service/rns-controller/interface/RnsGln";
import RnsService from "service/data-service/rns-controller/Rns.service";
import "./cpChange.scss";

interface CPChangeProps {
  automatKey: string;
  rnsInfo: Rns;
  disabled?: boolean;
  getChangedRnsGln?: (changed: RnsGln) => void;
}

const CPChange: React.FC<CPChangeProps> = (props: CPChangeProps) => {
  const [open, setOpen] = useState(false);
  const [valuesToSave, setValuesToSave] = useState<RnsGln>(props.rnsInfo); // autoconvert Rns -> RnsGln
  const dispatch = useAutomatData(props.automatKey, false)[1];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSave = () => {
    RnsService.updateContactOfGLN(valuesToSave, successCallback);
  };

  function successCallback() {
    if (props.getChangedRnsGln) {
      props.getChangedRnsGln(valuesToSave);
    }
    setOpen(false);
    dispatch(FETCH_AUTOMATTENBLATT_INFORMATION);
  }

  function handleClose() {
    setValuesToSave(props.rnsInfo);
    setOpen(false);
  }

  useEffect(() => {
    setValuesToSave(props.rnsInfo);
  }, [props.rnsInfo]);

  return (
    <Stack className="stack flexRow" columnGap={2}>
      <Button
        className="cpChangeButton"
        variant="contained"
        onClick={handleOpen}
        size="small"
        disabled={props.disabled}
      >
        Ansprechpartnerdaten ändern
      </Button>

      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Ansprechpartnerdaten ändern</DialogTitle>
          <DialogContent>
            <TextValue
              onChange={(event) => {
                setValuesToSave({
                  ...valuesToSave,
                  kontaktName: event.target.value,
                });
              }}
              label="Ansprechpartner:"
              value={valuesToSave.kontaktName ?? ""}
              id="rns-cp-ansprechpartner"
            />
            <TextValue
              label="Telefon1:"
              onChange={(event) => {
                setValuesToSave({
                  ...valuesToSave,
                  kontaktTelefon1: event.target.value,
                });
              }}
              value={valuesToSave.kontaktTelefon1 ?? ""}
              id="rns-cp-telefon1"
            />
            <TextValue
              label="Telefon2:"
              onChange={(event) => {
                setValuesToSave({
                  ...valuesToSave,
                  kontaktTelefon2: event.target.value,
                });
              }}
              value={valuesToSave.kontaktTelefon2 ?? ""}
              id="rns-cp-telefon2"
            />
            <TextValue
              label="EMail:"
              onChange={(event) => {
                setValuesToSave({
                  ...valuesToSave,
                  kontaktEmail: event.target.value,
                });
              }}
              value={valuesToSave.kontaktEmail ?? ""}
              id="rns-cp-email"
            />
            <TextValue
              label="Bemerkung1:"
              onChange={(event) => {
                setValuesToSave({
                  ...valuesToSave,
                  bemerkung1: event.target.value,
                });
              }}
              value={valuesToSave.bemerkung1 ?? ""}
              id="rns-cp-bemerkung1"
            />
            <TextValue
              label="Bemerkung2:"
              onChange={(event) => {
                setValuesToSave({
                  ...valuesToSave,
                  bemerkung2: event.target.value,
                });
              }}
              value={valuesToSave.bemerkung2 ?? ""}
              id="rns-cp-bemerkung2"
            />
            <TextValue
              label="Bemerkung3:"
              onChange={(event) => {
                setValuesToSave({
                  ...valuesToSave,
                  bemerkung3: event.target.value,
                });
              }}
              value={valuesToSave.bemerkung3 ?? ""}
              id="rns-cp-bemerkung3"
            />
          </DialogContent>
          <DialogActions>
            <UIButton onClick={handleSave} label="Speichern" />
          </DialogActions>
        </Dialog>
      </div>
    </Stack>
  );
};
export default CPChange;
