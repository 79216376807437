import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

const NotizenSucheColumnDefs: Array<ColDef> = [
  {
    headerName: "SerienNr.",
    field: "serienNr",
    minWidth: 100,
  },
  {
    headerName: "Hersteller",
    field: "automatModell",
    minWidth: 130,
  },
  {
    headerName: "Anlagedatum",
    field: "datumErfasst",
    minWidth: 130,
    maxWidth: 130,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Typ",
    field: "notizTyp",
    minWidth: 160,
  },
  {
    headerName: "Status",
    field: "notizStatus",
    minWidth: 90,
  },
  {
    headerName: "Text",
    field: "notizText",
    minWidth: 500,
  },
  {
    headerName: "User",
    field: "userCreated",
    minWidth: 80,
  },
  {
    headerName: "Änderungsdatum",
    field: "datumUpdated",
    minWidth: 154,
    maxWidth: 154,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Wiedervorlage",
    field: "datumFaellig",
    minWidth: 140,
    maxWidth: 140,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Zeile",
    field: "notizKopf",
    minWidth: 80,
    maxWidth: 80,
    cellRenderer: "agGroupCellRenderer",
    valueFormatter: (params) => (params.value === true ? "" : "Zeile"),
  },
];

export default NotizenSucheColumnDefs;
