import { View } from "@react-pdf/renderer";
import KeyValue from "global/interface/KeyValue";
import { SteckbriefProps, pageStyles } from "./PdfSteckbriefContent";
import PdfRowItemHeader from "./component/PdfRowItemHeader";
import PdfRowKeyValuesItem from "./component/PdfRowKeyValuesItem";
import PdfRowMultitextItem from "./component/PdfRowMultitextItem";
import PdfRowTextItem from "./component/PdfRowTextItem";

const PdfConfigurationDataItem = (props: SteckbriefProps) => {
  const data = props.steckbrief.konfigurationsdaten;
  const materialartenData: Array<KeyValue> = [];
  if (data.materialarten.pet) {
    materialartenData.push({
      key: "PET",
      value: data.materialarten.pet,
    });
  }
  if (data.materialarten.metall) {
    materialartenData.push({
      key: "Metall",
      value: data.materialarten.metall,
    });
  }
  if (data.materialarten.glas) {
    materialartenData.push({
      key: "Glas",
      value: data.materialarten.glas,
    });
  }
  if (data.materialarten.sonstige) {
    materialartenData.push({
      key: "Sonstige",
      value: data.materialarten.sonstige,
    });
  }

  let rucknahmeMoglichkeit: Array<string> = [props.steckbrief.undefinedValue];
  if (data.rucknahmeMoglichkeit && data.rucknahmeMoglichkeit.length > 0) {
    rucknahmeMoglichkeit = data.rucknahmeMoglichkeit;
  }

  let kundenbontext: Array<string> = [props.steckbrief.undefinedValue];
  if (data.kundenbontext && data.kundenbontext.length > 0) {
    kundenbontext = data.kundenbontext;
  }

  return (
    <View>
      <PdfRowItemHeader mainInfo="Konfigurationsdaten"></PdfRowItemHeader>
      <PdfRowKeyValuesItem
        header="Zurückgenommene Materialarten in % (PET/Metall/Glas/Sonstige)"
        info={materialartenData}
        keyStyle={pageStyles.keyContainer30}
      ></PdfRowKeyValuesItem>
      <PdfRowMultitextItem
        header="Rücknahmemöglichkeit"
        info={rucknahmeMoglichkeit}
      ></PdfRowMultitextItem>

      <PdfRowMultitextItem
        header="Kundenbontext inkl. Gültigkeitsdatum"
        info={kundenbontext}
      ></PdfRowMultitextItem>

      <PdfRowTextItem header=" " info=" "></PdfRowTextItem>
    </View>
  );
};

export default PdfConfigurationDataItem;
