import { Grid } from "@mui/material"
import DataGrid from "global/components/UI/DataGrid/DataGrid"
import ServiceResponseHandler from "service/interface/ServiceResponseHandler"
import SapInputParam from "service/sap-service/interface/SapInputParam"
import SapOutputParam from "service/sap-service/interface/SapOutputParam"
import PositionService from "service/sap-service/Position.service"
import { SeriennrListColumnDefs } from "./grid-column-defs"
import SeriennrListController from "./SeriennrListController"

export interface SeriennrListDialogContentProps {
    rnsGln: string,
    seriennrSelectHandler: (seriennr: string) => void
}

const SeriennrListDialogContent = (props: SeriennrListDialogContentProps) => {

    const gridDataFetchCall = (handler: ServiceResponseHandler) => {
        PositionService.getSerienNrForRns(
            {
                rnsGln: props.rnsGln
            } as SapInputParam,
            (response: SapOutputParam) => {
                handler.callbackOnSuccess(
                    SeriennrListController.convertResponse(
                        response,
                        props.seriennrSelectHandler));
            },
            handler.callbackOnFail
        )
    }
    return (
        <>
            <Grid container width={404}>
                <Grid item xs={12}>
                    <DataGrid
                        height={300}
                        rowHeight={44}
                        columnDefs={SeriennrListColumnDefs}
                        rowsDataDef={{
                            uncontrolledDataFetchCall: gridDataFetchCall,
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default SeriennrListDialogContent
