import { Stack, Typography } from "@mui/material";
import AutomatDataRecord from "global/hook/datastore/interface/AutomatDataRecord";
import DateUtils from "global/util/DateUtils";
import { useEffect, useState } from "react";
import { AuslastungVerfuegbarkeitAutomat } from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import BasicTable from "../TableContainer/TableContainer";
import "./materialdistribution.scss";
import MaterialDistributionController from "./resource/MaterialDistributionController";
import { MaterialDistributionTableHeader } from "./resource/MaterialDistributionTableHeader";

interface MaterialDistributionTableProps {
  automatData: AutomatDataRecord;
}

const MaterialDistributionTable: React.FC<MaterialDistributionTableProps> = (
  props: MaterialDistributionTableProps
) => {
  const [verfuegbarkeit, setVerfuegbarkeit] =
    useState<AuslastungVerfuegbarkeitAutomat | null>(null);

  useEffect(() => {
    setVerfuegbarkeit(
      props.automatData.automatenblattInformation.auslastungVerfuegbarkeitDTO
    );
  }, [props.automatData]);

  return (
    <Stack className="materialdistribution">
      <Typography className="uiElement__Text materialdistribution_header_label">
        {"Zurückgenommene Materialarten in %"}
      </Typography>
      <Typography className="uiElement__Text materialdistribution_header_label">
        {`Daten vom: ${
          DateUtils.formatDate(
            DateUtils.arrayToDate(verfuegbarkeit?.datenbestandVom ?? [])
          ) ?? ""
        }`}
      </Typography>

      <BasicTable
        head={MaterialDistributionTableHeader}
        rows={MaterialDistributionController.createTable(verfuegbarkeit)}
        id="materialdistribution"
      />
    </Stack>
  );
};
export default MaterialDistributionTable;
