import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import Enumerator from "global/components/EnumeratedValues/Enumerator";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import {
  AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID,
  AUTOMATENBLATT_AUTOMATMODELLE_REGION_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID,
  AUTOMATENBLATT_DPGAUTOMAT_ENUM_ID,
  AUTOMATENBLATT_RUECKNEHMER_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import StammdatenUtils from "global/util/StammdatenUtils";
import { useEffect, useRef } from "react";
import SearchField from "./SearchField";
import SingleSelect from "./SingleSelect";
import FilterController from "./controller/FilterController";
import "./filterInput.scss";

interface props {
  resetFilters: boolean;
  onEnterKey: () => void;
}

const FilterInput = (props: props) => {
  const dropdownEntriesStatus: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID
  );
  const dropdownEntriesModell: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATMODELLE_REGION_ENUM_ID
  );
  const dropdownEntriesHersteller: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID
  );
  const dropdownEntriesDPGAutomat: Enumerator = useEnumerator(
    AUTOMATENBLATT_DPGAUTOMAT_ENUM_ID
  );
  const dropdownEntriesRuecknehmer: Enumerator = useEnumerator(
    AUTOMATENBLATT_RUECKNEHMER_ENUM_ID
  );

  const serialNumberRef = useRef<any>();

  useEffect(() => {
    serialNumberRef.current?.focus();
  }, []);

  return (
    <Accordion
      className="accordion"
      disableGutters
      defaultExpanded
      elevation={0}
    >
      <AccordionSummary
        className="accordionSummary"
        expandIcon={<ExpandMoreIcon />}
      >
        Filter
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0.8} columnSpacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <MultipleSelectCheckbox
              label="Rücknehmer:"
              options={dropdownEntriesRuecknehmer.items}
              defaultValues={FilterController.get("ruecknehmer")}
              onChange={(values) => {
                FilterController.save(
                  "ruecknehmer",
                  FilterController.mapFromEnumeratorItemsToIdStrings(values)
                );
              }}
              resetSelectedValues={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 6, sm: 6, md: 6, lg: 11, xl: 2 }}
          >
            <SearchField
              label="Name der RNS:"
              defaultValue={FilterController.get("rnsName")}
              getSelectedValue={(value) => {
                FilterController.save("rnsName", value);
              }}
              reset={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 11, sm: 11, md: 11, lg: 10, xl: 3 }}
          >
            <MultipleSelectCheckbox
              label="Hersteller:"
              options={dropdownEntriesHersteller.items}
              defaultValues={FilterController.get("automatHerstellerKuerzel")}
              onChange={(values) => {
                FilterController.save(
                  "automatHerstellerKuerzel",
                  FilterController.mapFromEnumeratorItemsToIdStrings(values)
                );
              }}
              resetSelectedValues={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 4 }}
          >
            <MultipleSelectCheckbox
              label="Status:"
              options={dropdownEntriesStatus.items}
              defaultValues={FilterController.get("automatStatus")}
              onChange={(values) => {
                FilterController.save(
                  "automatStatus",
                  FilterController.mapFromEnumeratorItemsToIdStrings(values)
                );
              }}
              resetSelectedValues={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 7, sm: 7, md: 7, lg: 2, xl: 5 }}
          >
            <SearchField
              label="GLN der RNS:"
              defaultValue={FilterController.get("rnsGln")}
              getSelectedValue={(value) => {
                FilterController.save("rnsGln", value);
              }}
              reset={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 6 }}
          >
            <MultipleSelectCheckbox
              label="Modell:"
              options={dropdownEntriesModell.items}
              defaultValues={FilterController.get("automatModellKey")}
              onChange={(values) => {
                FilterController.save(
                  "automatModellKey",
                  FilterController.mapFromEnumeratorItemsToIdStrings(values)
                );
              }}
              resetSelectedValues={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 3, sm: 3, md: 3, lg: 5, xl: 7 }}
          >
            <SingleSelect
              label="DPG Automat:"
              options={StammdatenUtils.stammdatenArrayToSelectItemArray(
                dropdownEntriesDPGAutomat.items
              )}
              defaultValue={FilterController.get("dpgAutomat")}
              getSelectedValue={(value) => {
                FilterController.save("dpgAutomat", value);
              }}
              resetSelectedValues={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 8, sm: 8, md: 8, lg: 4, xl: 8 }}
          >
            <SearchField
              label="Schlüssel-ID:"
              defaultValue={FilterController.get("automatSchluesselId")}
              getSelectedValue={(value) => {
                FilterController.save("automatSchluesselId", value);
              }}
              reset={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 4, sm: 4, md: 4, lg: 7, xl: 10 }}
          >
            <SearchField
              label="PLZ:"
              defaultValue={FilterController.get("rnsPlz")}
              getSelectedValue={(value) => {
                FilterController.save("rnsPlz", value);
              }}
              reset={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 9, sm: 9, md: 9, lg: 6, xl: 11 }}
          >
            <SearchField
              label="Seriennummer:"
              defaultValue={FilterController.get("serienNr")}
              getSelectedValue={(value) => {
                FilterController.save("serienNr", value);
              }}
              reset={props.resetFilters}
              textFieldProps={{ inputRef: serialNumberRef }}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 5, sm: 5, md: 5, lg: 9, xl: 13 }}
          >
            <SearchField
              label="Ort:"
              defaultValue={FilterController.get("rnsOrt")}
              getSelectedValue={(value) => {
                FilterController.save("rnsOrt", value);
              }}
              reset={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 10, sm: 10, md: 10, lg: 8, xl: 14 }}
          >
            <SearchField
              label="IP/DNS-Adresse:"
              defaultValue={FilterController.get("ipAddress")}
              getSelectedValue={(value) => {
                FilterController.save("ipAddress", value);
              }}
              reset={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xs: 13, sm: 13, md: 13, lg: 14, xl: 9 }}
          >
            <SearchField
              label="MAC-Adresse:"
              defaultValue={FilterController.get("macAddress")}
              getSelectedValue={(value) => {
                FilterController.save("macAddress", value);
              }}
              reset={props.resetFilters}
              onEnterKey={props.onEnterKey}
            />
          </Grid>

          <Grid
            aria-label="spacer"
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ lg: 13 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "inline",
                xl: "none",
              },
            }}
          ></Grid>

          <Grid
            aria-label="spacer"
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            order={{ xl: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "inline",
              },
            }}
          ></Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterInput;
