import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";

const MARKT_SEH: EnumeratorItem = {
  id: "SEH",
  source: {},
  value: "SEH",
};
const MARKT_REGIEMARKT: EnumeratorItem = {
  id: "REGIEMARKT",
  source: {},
  value: "REGIEMARKT",
};

const MarktArtEnumerator = {
  items: [MARKT_SEH, MARKT_REGIEMARKT],
} as Enumerator;

export default MarktArtEnumerator;
