import { Grid, Typography } from "@mui/material";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import StatusIconElement, {
  StatusIconType,
} from "global/components/UI/StatusIcon/StatusIcon";
import TextValue from "global/components/UI/TextValue/TextValue";
import { returnUndefinedIfOnlyWhitespace } from "global/util/utils";
import { useEffect, useState } from "react";
import {
  KaufanfragenATHAutomatInsertDialogData,
  KaufanfragenATHInsertAutomatDialogContentProps,
} from "../interface/Kaufanfragen";

import Enumerator, {
  enumeratorItemForId,
} from "global/components/EnumeratedValues/Enumerator";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import {
  AUTOMAT_ALE_VERSION_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import "../kaufanfragen.scss";

const KaufanfragenATHAutomatInsertDialogContent: React.FC<
  KaufanfragenATHInsertAutomatDialogContentProps
> = (props: KaufanfragenATHInsertAutomatDialogContentProps) => {
  const [hasMissingSerienNummer, setHasMissingSerienNummer] = useState(true);
  const [hasMissingALEVersion, setHasMissingALEVersion] = useState(true);
  const [anlegenDialogData, setAnlegenDialogData] =
    useState<KaufanfragenATHAutomatInsertDialogData>({
      aktivAb: new Date(),
      aleVersion: "",
    });

  const aleVersion: Enumerator = useEnumerator(AUTOMAT_ALE_VERSION_ENUM_ID);

  const onDataChangeHandler = (
    value: KaufanfragenATHAutomatInsertDialogData
  ) => {
    setAnlegenDialogData((oldState: KaufanfragenATHAutomatInsertDialogData) => {
      return {
        ...oldState,
        ...value,
      };
    });
  };

  useEffect(() => {
    props.dataChangeListener(anlegenDialogData);
    setHasMissingSerienNummer(
      !returnUndefinedIfOnlyWhitespace(anlegenDialogData.seriennummer)
    );
    setHasMissingALEVersion(
      !returnUndefinedIfOnlyWhitespace(anlegenDialogData.aleVersion)
    ); // eslint-disable-next-line
  }, [anlegenDialogData]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextValue
          label="Seriennummer:"
          value={anlegenDialogData.seriennummer ?? ""}
          id="seriennummer"
          onChange={(event) =>
            onDataChangeHandler({
              seriennummer: event.target.value,
            } as KaufanfragenATHAutomatInsertDialogData)
          }
          TypographyProps={{ className: "dialogLabelStyle2 dialogTextStyle" }}
          TextFieldProps={{
            className: "dialogTextStyle kaufanfragenDialogInput",
          }}
        />
      </Grid>
      {hasMissingSerienNummer && (
        <Grid item xs={1}>
          <StatusIconElement type={StatusIconType.RN} />
        </Grid>
      )}
      {hasMissingSerienNummer && (
        <Grid item xs={11}>
          <Typography className="dialogTextErrorStyle">
            Seriennummer muss gefüllt sein!
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextValue
          label="Schlüssel-ID:"
          value={anlegenDialogData.schlusselId ?? ""}
          id="schlussel-id"
          onChange={(event) =>
            onDataChangeHandler({
              schlusselId: event.target.value,
            } as KaufanfragenATHAutomatInsertDialogData)
          }
          TypographyProps={{ className: "dialogLabelStyle2 dialogTextStyle" }}
          TextFieldProps={{
            className: "dialogTextStyle kaufanfragenDialogInput",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePickerElement
          label="Aktiv ab:"
          defaultValue={anlegenDialogData.aktivAb}
          getSelectedValue={(value) => {
            onDataChangeHandler({
              aktivAb: value,
            } as KaufanfragenATHAutomatInsertDialogData);
          }}
          id="aktiv-ab-date"
          TypographyProps={{ className: "dialogLabelStyle2 dialogTextStyle" }}
          textFieldClassName="dialogTextStyle kaufanfragenDialogInput"
          datePickerProperties={{
            disablePast: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox
          id="ale-version-selectbox"
          label="ALE-Ausprägungs:"
          enumerator={aleVersion}
          selection={enumeratorItemForId(
            aleVersion,
            anlegenDialogData.aleVersion
          )}
          getSelectedValue={(value) =>
            onDataChangeHandler({
              aleVersion: value?.id.toString() ?? "",
            } as KaufanfragenATHAutomatInsertDialogData)
          }
          AutocompleteProps={{
            className: "dialogTextStyle kaufanfragenDialogInput",
          }}
          TypographyProps={{ className: "dialogLabelStyle2 dialogTextStyle" }}
        />
      </Grid>
      {hasMissingALEVersion && (
        <Grid item xs={1}>
          <StatusIconElement type={StatusIconType.RN} />
        </Grid>
      )}
      {hasMissingALEVersion && (
        <Grid item xs={11}>
          <Typography className="dialogTextErrorStyle">
            ALE-Ausprägungs muss gefüllt sein!
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default KaufanfragenATHAutomatInsertDialogContent;
