import { Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { pageStyles } from "../PdfSteckbriefContent";

interface props {
  header: string;
  info: Array<string>;
  textStyle?: Style;
}

const PdfRowMultitextItem = (props: props) => {
  return (
    <View style={pageStyles.contentRow}>
      <Text style={pageStyles.itemHeader}>{props.header}</Text>
      <View style={pageStyles.itemInfo}>
        {props.info.map((info, index) => (
          <Text key={`index-${index}`} style={props.textStyle}>
            {info}
          </Text>
        ))}
      </View>
    </View>
  );
};

export default PdfRowMultitextItem;
