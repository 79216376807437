import {
  Stack,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import {
  DateValidationError,
  DesktopDatePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import {
  EpcomReducer,
  EpcomReducerFunction,
} from "global/interface/EpcomReducer";
import DateUtils from "global/util/DateUtils";
import { DataControl } from "global/util/enum/DataControl";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import "../../ui.scss";

interface DatePickerProps {
  className?: string;
  textFieldClassName?: string;
  label?: string;
  defaultValue?: Date;
  getSelectedValue: (values: Date | null) => void;
  id: string;
  resetToDefault?: boolean;
  TypographyProps?: TypographyProps;
  TextFieldProps?: TextFieldProps;
  hidden?: boolean;
  datePickerProperties?: DesktopDatePickerProps<Date>;
}

const DatePickerElement: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  const [, setPreviousDefaultValues] = useState<null | Date>(null);
  const stateChangeAndReset: EpcomReducerFunction<Date | null> = (
    state,
    action
  ) => {
    if (action.type === DataControl.CHANGE) {
      return action.valueToChange ?? null;
    } else if (action.type === DataControl.CLEAR) {
      return null;
    } else {
      return props.defaultValue ?? null;
    }
  };

  const datePickerTextFieldID: string = props.id;
  const datePickerTextFieldClassName: string = props.textFieldClassName ?? "";

  const [date, dispatchDate] = useReducer<EpcomReducer<Date | null>>(
    stateChangeAndReset,
    props.defaultValue ?? null
  );

  const resetIfDefaultValueChanged = useCallback(() => {
    setPreviousDefaultValues((state) => {
      if (
        props.defaultValue &&
        state?.getTime() !== props.defaultValue.getTime()
      ) {
        dispatchDate({ type: DataControl.RESET });
        return props.defaultValue;
      } else if (state && !props.defaultValue) {
        dispatchDate({ type: DataControl.CLEAR });
        return null;
      } else {
        return state;
      }
    });
  }, [props.defaultValue]);

  useEffect(() => {
    resetIfDefaultValueChanged();
  }, [resetIfDefaultValueChanged]);

  useEffect(() => {
    dispatchDate({ type: DataControl.RESET });
  }, [props.resetToDefault]);

  const [userInputDateError, setUserInputDateError] = useState<
    DateValidationError | undefined
  >();

  const [hasUserInputDateError, setHasUserInputDateError] = useState(false);

  useEffect(() => {
    const givenDate = DateUtils.formatDateToAPIDateString(
      props.defaultValue,
      "dd.MM.yyyy"
    );

    const userInputDate = DateUtils.formatDateToAPIDateString(
      date,
      "dd.MM.yyyy"
    );
    setHasUserInputDateError(
      userInputDateError !== undefined && givenDate
        ? givenDate !== userInputDate
        : userInputDateError !== undefined
    );
  }, [date, userInputDateError, props.defaultValue, setHasUserInputDateError]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
      <Stack
        className={`epcom__DatePicker ${props.className ?? ""}`}
        columnGap={2}
        display={props.hidden ? "none" : "flex"}
      >
        {props.label && (
          <Typography id={`${props.id}-label`} {...props.TypographyProps}>
            {props.label}
          </Typography>
        )}

        <DesktopDatePicker
          value={date}
          onChange={(newValue) => {
            dispatchDate({ type: DataControl.CHANGE, valueToChange: newValue });
            props.getSelectedValue(newValue);
          }}
          onError={(err, sourced) => {
            setUserInputDateError(err);
          }}
          slotProps={{
            textField: {
              error: hasUserInputDateError,
              inputProps: { readOnly: true },
              size: "small",
            },
            field: {
              id: datePickerTextFieldID,
              className: datePickerTextFieldClassName,
              clearable: true,
              onClear: () => {
                dispatchDate({ type: DataControl.CLEAR });
                props.getSelectedValue(null);
              },
            },
          }}
          {...props.datePickerProperties}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePickerElement;
