import HttpService from "App/service/http.service";
import Config from "Config";
import UserDTO from "./dto/UserDTO";
import EpcomUserGroupAutomat, {
  UserActionType,
} from "./interface/EpcomUserGroupAutomat";

const UserService = {
  processUser: (
    actionType: UserActionType,
    userDto: EpcomUserGroupAutomat,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(Config.dataServiceUrl + "user/" + actionType, userDto)
      .then((response) => {
        callbackOnSuccess(UserDTO.convertUserResponseDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  getAllVisibleGroups: (
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "user/epcom-group/get-all-visible")
      .then((response) => {
        callbackOnSuccess(UserDTO.convertGroupResponseDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default UserService;
