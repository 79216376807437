// Import roboto font
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { KeyCloakService } from "App/service/keycloak.service";
import { configureAutomatDataStore } from "global/hook/datastore/use-automat-datastore";
import { createRoot } from "react-dom/client";
import App from "./App/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

configureAutomatDataStore();

const container = document.getElementById("root");
const root = createRoot(container!);
const renderApp = () => {
  root.render(<App />);
};

KeyCloakService.initKeycloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
