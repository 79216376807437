import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import Access from "service/data-service/access-controller/interface/Access";
import AutomatSteckbrief from "service/data-service/automate-controller/interface/AutomatSteckbrief";
import PdfConfigurationDataItem from "./PdfConfigurationDataItem";
import PdfHeaderItem from "./PdfHeaderItem";
import PdfIndividualComponentsDataItem from "./PdfIndividualComponentsDataItem";
import PdfMarketDataItem from "./PdfMarketDataItem";
import PdfRemarksItem from "./PdfRemarksItem";
import PdfRepairHistoryContractStatusDataItem from "./PdfRepairHistoryAndContractStatusDataItem";
import PdfTechnicalDataItem from "./PdfTechnicalDataItem";
import PdfUsageDataItem from "./PdfUsageDataItem";

export const pageStyles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    padding: 20,
  },
  content: {
    border: "2px solid black",
    borderTop: "0px solid clear",
    fontSize: 9,
  },
  contentRow: {
    flexDirection: "row",
    padding: 2,
    borderTop: "1px dotted black",
  },
  borderlessRow: {
    borderTop: "0px solid clear",
  },
  contentRowHeader: {
    borderTop: "2px solid black",
    fontSize: 10,
  },
  itemTextBold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: 900,
  },
  itemHeader: {
    width: "50%",
  },
  itemInfo: {
    width: "50%",
    borderLeft: "1px dotted black",
    paddingLeft: 4,
  },
  contentNote: {
    fontSize: 7,
  },
  keyContainer30: {
    width: "30%",
  },
  keyContainer20: {
    width: "20%",
  },
  keyContainer10: {
    width: "10%",
  },
  tableContent: {
    border: "1px solid black",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellBorderTop: {
    borderTop: "1px solid black",
  },
  tableCellBorderLeft: {
    borderLeft: "1px solid black",
  },
  tableCellBorderRight: {
    borderRight: "1px solid black",
  },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

export interface SteckbriefProps {
  steckbrief: AutomatSteckbrief;
  access?: Access;
}

const PdfSteckbrief = (props: SteckbriefProps) => {
  const access = props.access ?? ({} as Access);
  const username = `${access.userName ?? ""} ${access.familyName ?? ""}`;
  return (
    <>
      <Page size="A4" style={pageStyles.page}>
        <PdfHeaderItem
          seriennummer={props.steckbrief.serialNumber}
          date={props.steckbrief.date}
          username={username}
          email={access.email ?? ""}
        />
        <View style={pageStyles.content}>
          <PdfMarketDataItem steckbrief={props.steckbrief} />
          <PdfUsageDataItem steckbrief={props.steckbrief} />
          <PdfTechnicalDataItem steckbrief={props.steckbrief} />
          <PdfConfigurationDataItem steckbrief={props.steckbrief} />
        </View>
        <Text style={pageStyles.contentNote}>
          xxxx = ATH liefert diese Information grundsätzliche nicht
        </Text>

        <Text break></Text>

        <View style={pageStyles.content}>
          <PdfIndividualComponentsDataItem steckbrief={props.steckbrief} />
          <PdfRepairHistoryContractStatusDataItem
            steckbrief={props.steckbrief}
          />
          <PdfRemarksItem steckbrief={props.steckbrief} />
        </View>
        <Text style={pageStyles.contentNote}>
          xxxx = ATH liefert diese Information grundsätzliche nicht
        </Text>
        <Text
          style={pageStyles.pageNumbers}
          render={({
            pageNumber,
            totalPages,
            subPageNumber,
            subPageTotalPages,
          }) => `${subPageNumber} / ${subPageTotalPages}`}
          fixed
        />
      </Page>
    </>
  );
};

export default PdfSteckbrief;
