import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import React, { useCallback, useContext, useEffect, useState } from "react";
import EpcomTreeItem, { EpcomTreeItemClickFunction } from "./EpcomTreeItem";
import {
  SideMenuItem,
  SideMenuItemLinkFollowFunction,
  SideMenuItemToggleFunction,
  targetLinkFor,
} from "./SideMenuItem";

import { AccessContext } from "App/components/Access-Control/AccessProvider";
import {
  MENU_ID_ADMIN,
  MENU_ID_ADMIN_EntryAnlageNeuerUser,
  MENU_ID_ADMIN_EntryAnlageRechnungsempfaenger,
  MENU_ID_ADMIN_EntryAnwenderNachrichten,
  MENU_ID_ADMIN_EntryIpBereiche,
  MENU_ID_ADMIN_EntryIpWhiteList,
  MENU_ID_ADMIN_EntryMaintenance,
  MENU_ID_ADMIN_EntryStammdatenabholungClearer,
  MENU_ID_AUTOMATEN,
  MENU_ID_AUTOMATEN_ENTRY_AUTOMATENSUCHE,
  MENU_ID_AUTOMATEN_ENTRY_KAUFANFRAGEN,
  MENU_ID_BERICHTE,
  MENU_ID_BERICHTE_EntryAnsichtFehlerhafteMm,
  MENU_ID_BERICHTE_EntryAnsichtWechselNokAufOk,
  MENU_ID_BERICHTE_EntryAutomatDatenGt30,
  MENU_ID_BERICHTE_EntryDsmdFehlerhaft,
  MENU_ID_BERICHTE_EntryErreichbarkeitenJeKommart,
  MENU_ID_BERICHTE_EntryFlatfile,
  MENU_ID_BERICHTE_EntryVerfuegbarkeit,
  MENU_ID_BERICHTE_EntryZurBearbeitungAnstehend,
  MENU_ID_COCKPIT,
  MENU_ID_DATENPFLEGE,
  MENU_ID_DATENPFLEGE_EntryRns,
  MENU_ID_HILFE,
  MENU_ID_HILFE_EntryBenutzerhandbuch,
  MENU_ID_NOTIZEN,
  MENU_ID_NOTIZEN_EntryNotizenSuche,
  MENU_ID_NOTIZEN_EntryWiedervorlage,
  MENU_ID_RECHNUNGSDATEN_ATH,
  MENU_ID_RECHNUNGSDATEN_ATH_EntryGlnOhneZuordnung,
  MENU_ID_RECHNUNGSDATEN_ATH_EntryPositionen,
  MENU_ID_RECHNUNGSDATEN_ATH_EntryRegionZuordnen,
  MENU_ID_RECHNUNGSDATEN_ATH_EntrySuchePositionen,
  MENU_ID_RECHNUNGSDATEN_ATH_EntryWGRZuordnung,
} from "../../epcomm-treeview-items";
import "./epcomtreeview.scss";

interface props {
  selectedItem?: string;
  expandedItems?: string[];
  onLinkFollow?: SideMenuItemLinkFollowFunction;
  onItemToggle?: SideMenuItemToggleFunction;
  items: SideMenuItem[];
}

export const WIP_MENU_ENTRIES: Array<string> = [
  MENU_ID_ADMIN_EntryStammdatenabholungClearer,
];

const EpcomTreeView = (props: props) => {
  const {
    selectedItem,
    expandedItems,
    onLinkFollow: linkFollowHandler,
    onItemToggle: itemToggleHandler,
    items,
  } = props;

  const [treeItems, setTreeItems] = useState<SideMenuItem[]>([]);

  const accessContext = useContext(AccessContext);

  const toggleNodeHandler = useCallback(
    (event: React.SyntheticEvent, nodes: string[]) => {
      if (itemToggleHandler) {
        itemToggleHandler(nodes);
      }
    }, // eslint-disable-next-line
    []
  );

  const itemClickHandler: EpcomTreeItemClickFunction = useCallback((item) => {
    const link = targetLinkFor(item);
    if (link && linkFollowHandler) {
      linkFollowHandler(link, item.id);
    } // eslint-disable-next-line
  }, []);

  const canShowMenuItem = useCallback(
    (item: SideMenuItem): boolean => {
      const access = accessContext.getAccessContainer();

      if (WIP_MENU_ENTRIES.includes(item.id)) return false;

      switch (item.id) {
        case MENU_ID_AUTOMATEN:
          return access.showMenuAutomaten;
        case MENU_ID_AUTOMATEN_ENTRY_AUTOMATENSUCHE:
          return access.showEntryAutomatensuche;
        case MENU_ID_AUTOMATEN_ENTRY_KAUFANFRAGEN:
          return access.showEntryKaufanfragen;
        case MENU_ID_BERICHTE:
          return access.showMenuBerichte;
        case MENU_ID_BERICHTE_EntryAnsichtFehlerhafteMm:
          return access.showEntryAnsichtFehlerhafteMm;
        case MENU_ID_BERICHTE_EntryAnsichtWechselNokAufOk:
          return access.showEntryAnsichtWechselNokAufOk;
        case MENU_ID_BERICHTE_EntryErreichbarkeitenJeKommart:
          return access.showEntryErreichbarkeitenJeKommart;
        case MENU_ID_BERICHTE_EntryZurBearbeitungAnstehend:
          return access.showEntryZurBearbeitungAnstehend;
        case MENU_ID_BERICHTE_EntryFlatfile:
          return access.showEntryFlatfile;
        case MENU_ID_BERICHTE_EntryDsmdFehlerhaft:
          return access.showEntryDsmdFehlerhaft;
        case MENU_ID_BERICHTE_EntryVerfuegbarkeit:
          return access.showEntryVerfuegbarkeit;
        case MENU_ID_DATENPFLEGE:
          return access.showMenuDatenpflege;
        case MENU_ID_DATENPFLEGE_EntryRns:
          return access.showEntryRns;
        case MENU_ID_NOTIZEN:
          return access.showMenuNotizen;
        case MENU_ID_NOTIZEN_EntryNotizenSuche:
          return access.showEntryNotizenSuche;
        case MENU_ID_NOTIZEN_EntryWiedervorlage:
          return access.showEntryWiedervorlage;
        case MENU_ID_HILFE:
          return access.showMenuHilfe;
        case MENU_ID_HILFE_EntryBenutzerhandbuch:
          return access.showEntryBenutzerhandbuch;
        case MENU_ID_BERICHTE_EntryAutomatDatenGt30:
          return access.showMenuAdministration;
        case MENU_ID_ADMIN:
          return access.showMenuAdministration;
        case MENU_ID_ADMIN_EntryAnwenderNachrichten:
          return access.showEntryAnwenderNachrichten;
        case MENU_ID_ADMIN_EntryAnlageNeuerUser:
          return access.showEntryAnlageNeuerUser;
        case MENU_ID_ADMIN_EntryAnlageRechnungsempfaenger:
          return access.showEntryAnlageRechnungsempfaenger;
        case MENU_ID_ADMIN_EntryStammdatenabholungClearer:
          return access.showEntryStammdatenabholungClearer;
        case MENU_ID_ADMIN_EntryIpBereiche:
          return access.showEntryIpBereiche;
        case MENU_ID_ADMIN_EntryIpWhiteList:
          return access.showEntryIpWhiteList;
        case MENU_ID_RECHNUNGSDATEN_ATH:
          return (
            access.showMenuRechnungsdatenAth ||
            access.showEntryRechnungsdatenAth
          );
        case MENU_ID_RECHNUNGSDATEN_ATH_EntryGlnOhneZuordnung:
          return access.showEntryGlnOhneZuordnung;
        case MENU_ID_RECHNUNGSDATEN_ATH_EntrySuchePositionen:
          return access.showEntrySuchePositionen;
        case MENU_ID_RECHNUNGSDATEN_ATH_EntryPositionen:
          return access.showEntryRechnungsdatenAth;
        case MENU_ID_RECHNUNGSDATEN_ATH_EntryRegionZuordnen:
          return access.showEntryRechnungsdatenAth;
        case MENU_ID_RECHNUNGSDATEN_ATH_EntryWGRZuordnung:
          return access.showEntryRechnungsdatenAth;
        case MENU_ID_ADMIN_EntryMaintenance:
          return access.admin;
        default:
          break;
      }

      return true;
    }, // eslint-disable-next-line
    [accessContext]
  );

  const gatherTreeItems = useCallback(() => {
    const nodes: SideMenuItem[] = [];
    items
      .filter((item) => canShowMenuItem(item))
      .map((item) => {
        if (item.id === MENU_ID_COCKPIT) {
          nodes.push({
            ...item,
            text: `Cockpit (${accessContext.getAccessContainer().rollenTyp})`,
          });
        } else {
          nodes.push(item);
        }
        return item;
      });
    setTreeItems(nodes); // eslint-disable-next-line
  }, [accessContext, canShowMenuItem]);

  useEffect(() => {
    gatherTreeItems(); // eslint-disable-next-line
  }, [accessContext, items]);

  return (
    <TreeView
      aria-label="epcom-tree-view"
      className="treeMenu"
      expanded={expandedItems}
      selected={selectedItem}
      defaultCollapseIcon={<KeyboardDoubleArrowDownIcon />}
      defaultExpandIcon={<KeyboardDoubleArrowRightIcon />}
      onNodeToggle={toggleNodeHandler}
    >
      {treeItems.map((item) => (
        <EpcomTreeItem
          key={item.id}
          item={item}
          onItemClick={itemClickHandler}
          itemFilter={canShowMenuItem}
        />
      ))}
    </TreeView>
  );
};

export default EpcomTreeView;
