import ModalController from "global/hook/modal/interface/ModalController";
import findMachineKeyViaSerialNumber from "global/util/REST/findMachineKey";
import { returnNullIfEmptyString } from "global/util/utils";
import AutomatenblattInformationService from "service/data-service/automate-controller/Automatenblatt.service";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import RnsService from "service/data-service/rns-controller/Rns.service";
import RnsSearchResult, {
  GhWechselInformation,
} from "service/data-service/rns-controller/interface/RnsSearchResult";
import {
  MachineIdentifier,
  OtherMachineToSave,
} from "../interface/otherMachinesToSave";

const GlnChangeController = {
  showDialogWhenOtherGh: function (
    value: RnsSearchResult,
    modalController: ModalController
  ) {
    if (checkIfRegionIsDifferent(value.rows[0].ghWechselInformation))
      modalController.showMessageDialog(
        "Achtung, die angegebene GLN gehört zu einer anderen GH"
      );
  },
  searchRns: function (
    glnToSearch: string,
    oldGln: string,
    callbackOnSuccess: (value: RnsSearchResult) => void,
    modalController: ModalController
  ) {
    if (glnToSearch.length === 13) {
      RnsService.searchRNS(
        { gln: glnToSearch, oldGln: oldGln, callSource: "GLNWECHSEL" },
        callbackOnSuccess
      );
    } else {
      modalController.showMessageDialog(
        "Die angegebene GLN muss 13 Ziffern lang sein."
      );
    }
  },
  searchSerialNumbersForOtherMachinesOfRns: function (
    stringToSplit: string,
    serialOfCurrentMachine: string,
    callbackOnFoundAutomatKey: (result: MachineIdentifier) => void
  ) {
    let otherMachineSerials = splitStringIntoSeparateSerialNumbers(
      stringToSplit,
      serialOfCurrentMachine
    );
    findAutomatKeysForAllSerialNumbersInArray(
      otherMachineSerials,
      callbackOnFoundAutomatKey
    );
  },
  updateMachineWithNewGln: function (
    automatKeysToChange: Array<string>,
    updateValues: AutomatenUpdateRequest,
    callbackOnSuccess: () => void
  ) {
    let isOtherMachinesSelected = automatKeysToChange.length > 0;

    AutomatenblattInformationService.update(
      {
        ...updateValues,
        mode: isOtherMachinesSelected
          ? "UPDATE_AUTOMAT_AND_OTHER_RNS"
          : undefined,
        otherAutomatKeys: isOtherMachinesSelected
          ? automatKeysToChange
          : undefined,
      },
      callbackOnSuccess
    );
  },
  filterMachinesToSave: function (
    otherMachinesToSave: Array<OtherMachineToSave>
  ) {
    return otherMachinesToSave
      .filter((machine) => machine.saveAlso)
      .map((value) => value.automatKey);
  },
};

export default GlnChangeController;

function checkIfRegionIsDifferent(
  ghWechselInformation: GhWechselInformation
): boolean {
  return ghWechselInformation.newRegion !== ghWechselInformation.oldRegion;
}

function splitStringIntoSeparateSerialNumbers(
  stringToSplit: string,
  serialOfCurrentMachine: string
) {
  let allMachineSerials = stringToSplit.split(";");
  let resultingList: Array<string> = [];

  allMachineSerials.forEach((serialNumber) => {
    if (serialNumber !== serialOfCurrentMachine) {
      resultingList.push(serialNumber);
    }
  });
  return resultingList;
}

function findAutomatKeysForAllSerialNumbersInArray(
  serialNumbers: Array<string>,
  callbackOnFoundAutomatKey: (result: MachineIdentifier) => void
) {
  serialNumbers.forEach((serialNumber) => {
    if (returnNullIfEmptyString(serialNumber))
      findMachineKeyViaSerialNumber(serialNumber, (automatKey) => {
        callbackOnFoundAutomatKey({
          serialNumber: serialNumber,
          automatKey: automatKey,
        });
      });
  });
}
