import { Grid } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { FETCH_AUTOMAT_CLEARINGFEHLER } from "global/hook/datastore/automat/automat-clearingfehler-store";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import useModalController from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useCallback, useContext, useEffect, useState } from "react";
import ClearingfehlerResult, {
  Clearingfehler,
} from "service/data-service/clearingfehler-controller/interface/Clearingfehler";
import ClearingfehlerPanelController from "./ClearingfehlerPanelController";
import { ClearingfehlerPanelColumnDefs } from "./grid-column-defs";

interface ClearingfehlerPanelProps {
  automatKey: string;
}
const ClearingfehlerPanel = (props: ClearingfehlerPanelProps) => {
  const [records, setRecords] = useState<Array<Clearingfehler>>();
  const [automatData, dispatchAutomatDataAction] = useAutomatData(
    props.automatKey
  );
  const [updating, setUpdating] = useState<Array<boolean>>([]);
  const modalController = useModalController();

  const windowViewport = useWindowViewport(0, 212);

  const accessContext = useContext(AccessContext);

  const setUpdatingFlags = useCallback(
    (index: number, value: boolean) => {
      setUpdating((prevFlags) => {
        const newFlags = [...prevFlags];
        newFlags[index] = value;
        return newFlags;
      });
    },
    [setUpdating]
  );

  useEffect(() => {
    if (records) {
      const updatingFlags: Array<boolean> = records.map((e) => false);
      setUpdating(updatingFlags);
    }
  }, [records]);

  useEffect(() => {
    const clearingfehlerResult: ClearingfehlerResult =
      automatData.clearingFehler ?? { total: 0, rows: [] };
    setRecords(
      ClearingfehlerPanelController.transmuteToGridData(
        clearingfehlerResult,
        updating,
        setUpdatingFlags,
        dispatchAutomatDataAction,
        modalController,
        accessContext.getAccessContainer()
      )
    ); // eslint-disable-next-line
  }, [automatData]);

  useEffect(() => {
    if (!automatData.clearingFehler) {
      dispatchAutomatDataAction(FETCH_AUTOMAT_CLEARINGFEHLER);
    }
  }, [automatData, dispatchAutomatDataAction]);

  return (
    <Grid container spacing={5} width={"auto"}>
      <Grid item xs={12}>
        <DataGrid
          height={windowViewport.height}
          columnDefs={ClearingfehlerPanelColumnDefs}
          rowsDataDef={{ data: records }}
        />
      </Grid>
    </Grid>
  );
};

export default ClearingfehlerPanel;
