import DateUtils from "global/util/DateUtils";
import JobMonitoringService from "service/data-service/job-controller/JobMonitoring.service";
import JobInformation, {
  JobCategory,
  JobStatus,
} from "service/data-service/job-controller/interface/JobInformation";
import JobMonitoringDTO from "service/data-service/job-controller/interface/JobMonitoringDTO";
import JobMonitoringHistoryDTO from "service/data-service/job-controller/interface/JobMonitoringHistoryDTO";
import JobMonitoringHistoryRequestBody from "service/data-service/job-controller/interface/JobMonitoringHistoryRequestBody";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";

const JobMonitoringController = {
  loadDashboardData(handler: ServiceResponseHandler) {
    JobMonitoringService.retrieveJobMonitorData(
      (result: JobMonitoringDTO) => {
        handler.callbackOnSuccess(JobMonitoringController.combineData(result));
      },
      (error: Error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error);
      }
    );
  },
  loadJobMonitoringHistoryJobs(
    requestBody: JobMonitoringHistoryRequestBody,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) {
    JobMonitoringService.retrieveJobMonitoringHistoryData(
      requestBody,
      (result: JobMonitoringHistoryDTO[]) => {
        const resultWithDatesAndAdjustedSapJobs =
          JobMonitoringController.adjustSapServiceDataToMatchOtherData(
            JobMonitoringController.setUpDate(result)
          );
        callbackOnSuccess(resultWithDatesAndAdjustedSapJobs);
      },
      (error: Error) => {
        if (callbackOnFail) callbackOnFail(error);
      }
    );
  },

  setUpDate(jobs: JobMonitoringHistoryDTO[]): JobMonitoringHistoryDTO[] {
    jobs.map((job) => {
      job.gestartetDate = job.gestartet
        ? new Date(
            job.gestartet[0],
            job.gestartet[1] - 1,
            job.gestartet[2],
            job.gestartet[3],
            job.gestartet[4]
          )
        : undefined;
      job.beendetDate = job.beendet
        ? new Date(
            job.beendet[0],
            job.beendet[1] - 1,
            job.beendet[2],
            job.beendet[3],
            job.beendet[4]
          )
        : undefined;
      job.createdDate = job.created
        ? new Date(
            job.created[0],
            job.created[1] - 1,
            job.created[2],
            job.created[3],
            job.created[4]
          )
        : undefined;
      job.datumDate = job.datum ? DateUtils.arrayToDate(job.datum) : undefined;
      return job;
    });

    jobs.sort((a: JobMonitoringHistoryDTO, b: JobMonitoringHistoryDTO) => {
      return b.gestartetDate!.getTime() - a.gestartetDate!.getTime();
    });

    return jobs;
  },
  adjustSapServiceDataToMatchOtherData(jobs: JobMonitoringHistoryDTO[]) {
    jobs.forEach((job) => {
      if (
        job.jobKey === "saprd_sapimport" ||
        job.jobKey === "saprd_posimport"
      ) {
        job.typ = job.jobKey;
      }

      let jobInfo;
      if (job.info) {
        jobInfo = job.info.split(" MseparatorM ");

        if (jobInfo.length === 2) {
          if (job.jobKey === "saprd_posimport") {
            job.info = `${jobInfo[0]} ${
              jobInfo[1] !== "null" ? " | Bemerkung: " + jobInfo[1] : ""
            }`;
          } else {
            job.info = `${jobInfo[1] !== "null" ? jobInfo[1] : ""}`;
          }
        }
      }
    });
    return jobs;
  },
  combineData(jobMonitoringDTO: JobMonitoringDTO) {
    const jobsCombined: JobInformation[] = [];

    const failedJobs = setCalculatedJobStatus(
      jobMonitoringDTO.failedJobs,
      JobStatus.FAILURE,
      JobCategory.failedJobs
    ).sort(sortByPlannedDate);
    const notAsPlannedRunningJobs = setCalculatedJobStatus(
      jobMonitoringDTO.notAsPlannedRunningJobs,
      JobStatus.FAILURE,
      JobCategory.notAsPlannedRunningJobs
    ).sort(sortByPlannedDate);
    const stillRunningJobs = setCalculatedJobStatus(
      jobMonitoringDTO.stillRunningJobs,
      JobStatus.RUNNING,
      JobCategory.stillRunningJobs
    ).sort(sortByPlannedDate);
    const successfulJobs = setCalculatedJobStatus(
      jobMonitoringDTO.successfulJobs,
      JobStatus.SUCCESS,
      JobCategory.successfulJobs
    ).sort(sortByPlannedDate);
    const notMappedJobs = setCalculatedJobStatus(
      jobMonitoringDTO.notMappedJobs,
      JobStatus.NOT_MAPPED,
      JobCategory.notMappedJobs
    ).sort(sortByPlannedDate);
    const disabledJobs = setCalculatedJobStatus(
      jobMonitoringDTO.disabledJobs,
      JobStatus.DISABLED,
      JobCategory.disabledJobs
    ).sort(sortByPlannedDate);

    jobsCombined.push(
      ...failedJobs,
      ...notAsPlannedRunningJobs,
      ...stillRunningJobs,
      ...successfulJobs
    );
    jobsCombined.sort(sortByCalculatedJobStatus);
    jobsCombined.push(...notMappedJobs, ...disabledJobs);

    function setCalculatedJobStatus(
      jobs: JobInformation[],
      initialJobStatus: JobStatus,
      initialJobCategory: JobCategory
    ) {
      const currentDateNumber = Date.now();

      jobs.forEach((job) => {
        job.calculatedJobStatus = initialJobStatus;
        job.category = initialJobCategory;

        if (
          job.category === JobCategory.disabledJobs ||
          job.category === JobCategory.notMappedJobs
        ) {
          return;
        }

        const jobPlannedDateNumber = Date.parse(job.plannedExecution);
        const jobRealExecDateNumber = Date.parse(job.realExecution);

        if (jobPlannedDateNumber > currentDateNumber) {
          // if in future -> no failure but not yet run
          job.calculatedJobStatus = JobStatus.NOT_YET_RUN;
        }
        if (
          jobPlannedDateNumber < currentDateNumber &&
          (job.realExecution === null || !job.realExecution)
        ) {
          // if job planned date is in past and no execution
          job.calculatedJobStatus = JobStatus.FAILURE;
        }

        if (
          jobRealExecDateNumber < jobPlannedDateNumber - 1800 &&
          jobRealExecDateNumber > jobPlannedDateNumber + 1800
        ) {
          // if real execution is not +- 30 mins around planned date
          job.calculatedJobStatus = JobStatus.FAILURE;
        }
      });
      return jobs;
    }

    function sortByPlannedDate(a: JobInformation, b: JobInformation) {
      if (Date.parse(a.plannedExecution) > Date.parse(b.plannedExecution)) {
        return -1;
      }
      if (Date.parse(a.plannedExecution) < Date.parse(b.plannedExecution)) {
        return 1;
      }
      return 0;
    }

    function sortByCalculatedJobStatus(a: JobInformation, b: JobInformation) {
      if (a.calculatedJobStatus! < b.calculatedJobStatus!) {
        return -1;
      }
      if (a.calculatedJobStatus! > b.calculatedJobStatus!) {
        return 1;
      }
      return 0;
    }

    return jobsCombined;
  },
};

export default JobMonitoringController;
