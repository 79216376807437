import { Stack, Switch, Typography, TypographyProps } from "@mui/material";
import React from "react";

interface RoleSwitchProps {
  label: string;
  on: boolean;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  TypographyProps?: TypographyProps;
}

const RoleSwitch: React.FC<RoleSwitchProps> = (props: RoleSwitchProps) => {
  return (
    <Stack className="epcom__RoleSwitch" direction="row" spacing={2}>
      <Typography
        id={`role-switch-${props.label}-label`}
        {...props.TypographyProps}
      >
        {props.label}
      </Typography>

      <Switch checked={props.on} onChange={props.onChange} size="small" />
    </Stack>
  );
};
export default RoleSwitch;
