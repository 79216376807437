import HttpService from "App/service/http.service";
import Config from "Config";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import IpBereichDTO from "./dto/IpBereichDTO";

const IpBereichService = {
  load: (handler: ServiceResponseHandler) => {
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}automate/erreichbarkeitperiprange`)
      .then((response) => {
        handler.callbackOnSuccess(
          IpBereichDTO.convertIpBereichResponse(response)
        );
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error);
      });
  },
};

export default IpBereichService;
