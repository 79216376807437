import { MAINTENANCETORAGE_KEY } from "global/hook/maintenance/use-maintenance";
import {
  OptionsObject,
  SnackbarKey,
  useSnackbar,
  VariantType,
} from "notistack";
import { MaintenanceInfo } from "service/data-service/access-controller/interface/Access";
import { snackbarCloseAction } from "./component/SnackbarCloseButton";

export type SnackbarFeatures = OptionsObject & {
  /**
   * Error that is the cause for the snackbar. if provided it will be logged on console.
   */
  error?: Error;
  hidesAction?: boolean;
  hasIcon?: boolean;
};

export type SnackbarGeneratorFunction = (
  message: string,
  features?: SnackbarFeatures
) => SnackbarKey;

export interface SnackbarGenerator {
  showError: SnackbarGeneratorFunction;
  showWarn: SnackbarGeneratorFunction;
  showInfo: SnackbarGeneratorFunction;
}

/**
 * Custom hook to access snackbar functionality
 * Usage:
 * const { showError, showInfo } = useSnackbarGenerator();
 * ...
 * showError('Error message');
 */

const useSnackbarGenerator = (): SnackbarGenerator => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Builds and OptionsObject based on given variant and features params.
   * IF not provided in features param, persist and preventDuplicate attributes are set to TRUE.
   * @param variant - variant of the Snackbar: info, error, warn
   * @param features - options for the Snackbar
   * @returns
   */
  function buildOptionsWithDefaults(
    variant: VariantType,
    features?: SnackbarFeatures
  ): OptionsObject {
    const extras: OptionsObject = {
      ...features,
      ...{
        variant: variant,
        persist: features?.persist ?? true,
        preventDuplicate: features?.preventDuplicate ?? true,
        action:
          features?.hidesAction !== true ? snackbarCloseAction : undefined,
        hideIconVariant: features?.hasIcon !== true,
      },
    };
    return extras;
  }

  const getStoredMaintenanceInfo = (): MaintenanceInfo | null => {
    const storedInfo = sessionStorage.getItem(MAINTENANCETORAGE_KEY);
    if (storedInfo !== null) {
      return JSON.parse(storedInfo);
    }
    return null;
  };

  const errorHandler = (message: string, features?: SnackbarFeatures) => {
    const maintenanceInfo = getStoredMaintenanceInfo();
    let sKey: SnackbarKey = "none";
    if (!maintenanceInfo?.active) {
      sKey = enqueueSnackbar(
        message,
        buildOptionsWithDefaults("error", features)
      );
    }
    if (features?.error) {
      console.error(message, features?.error);
    }
    return sKey;
  };
  const warnHandler = (message: string, features?: SnackbarFeatures) => {
    return enqueueSnackbar(
      message,
      buildOptionsWithDefaults("warning", features)
    );
  };
  const infoHandler = (message: string, features?: SnackbarFeatures) => {
    return enqueueSnackbar(message, buildOptionsWithDefaults("info", features));
  };

  return {
    showError: errorHandler,
    showWarn: warnHandler,
    showInfo: infoHandler,
  };
};

export default useSnackbarGenerator;
