export type EnumeratorID = string;
export type EnumeratorItemId = string | number;

export type EnumeratorSyncFunction = (filter?: string) => void;

export interface EnumeratorItem {
  value: string;
  id: EnumeratorItemId;
  source: any | undefined;
}

export type EnumeratorStatus = "PENDING" | "INITIAL" | "SYNC";

interface Enumerator {
  readonly id: EnumeratorID;
  readonly status: EnumeratorStatus;
  readonly items: Array<EnumeratorItem>;
  readonly syncFN: EnumeratorSyncFunction;
}

export function enumeratorItemForId(
  inEnumerator: Enumerator,
  id: EnumeratorItemId
): EnumeratorItem | undefined {
  return inEnumerator.items.find((item) => item.id === id);
}

export function enumeratorItemForValue(
  inEnumerator: Enumerator,
  value: string
): EnumeratorItem | undefined {
  return inEnumerator.items.find((item) => item.value === value);
}

export default Enumerator;
