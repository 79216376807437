import { Card, CardHeader, List } from "@mui/material";
import { useCockpitInfo } from "global/hook/cockpit/use-cockpit-info";
import { HREF_AUTOMATENKAUFANFRAGEN } from "global/util/routes";
import { ColoredListItemTypography } from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";

const Kaufanfragen: React.FC = () => {
  const cockpitInfo = useCockpitInfo()[0];
  // Variablen und Hooks
  const [countOfPurchaseRequest, setCountOfPurchaseRequest] = useState(Number);
  const [purchaseRequestVisible, setPurchaseRequestVisible] = useState(Boolean);

  let navigate = useNavigate();

  useEffect(() => {
    setCountOfPurchaseRequest(
      cockpitInfo.cockpitInformationDTO?.kaufanfragen ?? 0
    );
    setPurchaseRequestVisible(
      cockpitInfo.visibilities?.kaufanfragenShow !== undefined
        ? cockpitInfo.visibilities!.kaufanfragenShow
        : false
    );
  }, [cockpitInfo]);

  // React-Tree
  return (
    <Card hidden={!purchaseRequestVisible}>
      <CardHeader title="Kaufanfragen" />
      <List disablePadding>
        <CockpitListItemButton
          divider
          aria-label="Zu Bearbeiten"
          onClick={() => navigate(HREF_AUTOMATENKAUFANFRAGEN)}
        >
          <CockpitListItemText primary="Zu bearbeiten: " />
          <ColoredListItemTypography
            colorChangeBool={countOfPurchaseRequest <= 0}
            text={`${countOfPurchaseRequest}`}
            id="countOfPurchaseRequest"
          />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Kaufanfragen;
