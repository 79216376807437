import { View } from "@react-pdf/renderer";
import { SteckbriefProps } from "./PdfSteckbriefContent";
import PdfRowItemHeader from "./component/PdfRowItemHeader";
import PdfRowTextItem from "./component/PdfRowTextItem";

const PdfIndividualComponentsDataItem = (props: SteckbriefProps) => {
  const data = props.steckbrief.datenquelle;
  const undefinedValue = props.steckbrief.undefinedValue;
  return (
    <View>
      <PdfRowItemHeader mainInfo="Daten der einzelnen Komponenten mit Nr. und Altersangabe"></PdfRowItemHeader>
      <PdfRowTextItem header="Kompaktor" info={data.kompaktor}></PdfRowTextItem>
      <PdfRowTextItem
        header="Verschleißstand (Anzahl Gebinde durch Kompaktor)"
        info={data.verschleissstand}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Waage"
        info={data.waage ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Transportabschnitt"
        info={data.transportabschnitt ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Flaschenaufsteller"
        info={data.flaschenaufsteller ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Auswerfer"
        info={data.auswerfer ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Sortierstrecke"
        info={data.sortierstrecke ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Drucker"
        info={data.drucker ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Annahmekanal"
        info={data.annahmekanal ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Metallsensor"
        info={data.metallsensor ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Mainboard"
        info={data.mainboard ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Beleuchtung"
        info={data.beleuchtung ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Kamera"
        info={data.kamera ?? undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem header=" " info=" "></PdfRowTextItem>
    </View>
  );
};

export default PdfIndividualComponentsDataItem;
