import { EpcomResponse } from "App/service/http.service";
import AutomatModellResult from "../interface/AutomatModell";
import { KaufanfrageSucheResult } from "../interface/Kaufanfrage";
import KaufanfrageStatusResult from "../interface/KaufanfrageStatus";

const KaufanfragenDTO = {
  convertKaufanfragenResponse: (
    response: EpcomResponse
  ): KaufanfrageSucheResult => {
    if (response.data) {
      return {
        Row: response.data.Row,
        total: response.data.total,
      };
    }
    return { Row: [], total: 0 };
  },
  convertKaufanfragenStatusResponse: (
    response: EpcomResponse
  ): KaufanfrageStatusResult => {
    if (response.data) {
      return {
        Row: response.data.Row,
        total: response.data.total,
      };
    }
    return { Row: [], total: 0 };
  },
  convertModellResponse: (response: EpcomResponse): AutomatModellResult => {
    if (response.data) {
      return {
        Row: response.data.Row,
        total: response.data.total,
      };
    }
    return { Row: [], total: 0 };
  },
};
export default KaufanfragenDTO;
