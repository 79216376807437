import DateUtils from "global/util/DateUtils";
import AutomatStatusService from "service/data-service/automate-controller/AutomatStatus.service";
import { AutomatWithTooManyFiles } from "service/data-service/automate-controller/interface/AutomatWithTooManyFiles";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";

const AutomatenDatenGt30Controller = {
  loadData(pastDaysCount: number, handler: ServiceResponseHandler) {
    AutomatStatusService.getArchiveToManyFilesData(
      (result: Array<AutomatWithTooManyFiles>) => {
        handler.callbackOnSuccess(
          AutomatenDatenGt30Controller.convertAutomatData(result, pastDaysCount)
        );
      },
      (error: Error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error);
      }
    );
  },
  convertAutomatData(
    automatData: Array<AutomatWithTooManyFiles>,
    pastDaysCount: number
  ): Array<AutomatWithTooManyFiles> {
    automatData.forEach((automat: AutomatWithTooManyFiles) => {
      for (let index = 0; index <= pastDaysCount; index++) {
        const date = new Date();
        date.setDate(date.getDate() - index);
        const key =
          DateUtils.formatDateToAPIDateString(date, "yyyy-MM-dd") ?? "";
        const newKey = `PastDay${index}`;
        automat.data[newKey] = automat.data[key] ?? "";
      }
    });
    return automatData;
  },
};

export default AutomatenDatenGt30Controller;
