import { View } from "@react-pdf/renderer";
import { SteckbriefProps, pageStyles } from "./PdfSteckbriefContent";
import PdfRowItemHeader from "./component/PdfRowItemHeader";
import PdfRowMultitextItem from "./component/PdfRowMultitextItem";
import PdfRowTextItem from "./component/PdfRowTextItem";

const PdfMarketDataItem = (props: SteckbriefProps) => {
  const data = props.steckbrief.marktDaten;
  const addressData = [
    data.name ?? "",
    data.strasse ?? "",
    `${data.ort ?? ""} ${data.plz ?? ""}`,
  ];
  return (
    <View>
      <PdfRowItemHeader mainInfo="Marktdaten"></PdfRowItemHeader>
      <PdfRowTextItem
        header="RNS GLN"
        info={props.steckbrief.gln}
      ></PdfRowTextItem>

      <PdfRowMultitextItem
        header="Adresse"
        info={addressData}
        textStyle={pageStyles.itemTextBold}
      ></PdfRowMultitextItem>
      <PdfRowTextItem
        header="Anzahl weitere aktive Automaten im Markt"
        info={data.weitereAktiveAutomaten}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Standort im Markt"
        info={data.standort}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Art der Zählstelle"
        info={data.artDerZahlstelle}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Nutzungsart (gemäß DPG Eintrag)"
        info={data.nutzungsart}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Automatenzustand"
        info={data.automatenZustand}
      ></PdfRowTextItem>
      <PdfRowTextItem
        header="Letzter abgerechneter EW-Kompaktierungszeitpunkt"
        info={data.letzterEWdate ?? props.steckbrief.undefinedValue}
      ></PdfRowTextItem>
      <PdfRowTextItem header=" " info=" "></PdfRowTextItem>
    </View>
  );
};

export default PdfMarketDataItem;
