import { Card, CardHeader, List } from "@mui/material";
import { useCockpitInfo } from "global/hook/cockpit/use-cockpit-info";
import {
  HREF_ADMIN_JOB_MONITORING,
  HREF_BERICHTE_AUTOMATDATEN_GT_30,
} from "global/util/routes";
import { ColoredSmallListItemText } from "page/cockpit/components/ListItemText/ListItemText";
import { ColoredSmallListItemTypography } from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SlimListItemButton } from "../ListItemButton/ListItemButton";

const EpgMonitoring: React.FC = () => {
  // Variablen und Hooks
  const cockpitInfo = useCockpitInfo()[0];
  const [alsLoginDritterAnzahl, setAlsLoginDritterAnzahl] = useState(String);
  const [alsLoginDritterExists, setAlsLoginDritterExists] = useState(Boolean);

  const [erreichbarkeitIpBereicheFailed, setErreichbarkeitIpBereicheFailed] =
    useState(Boolean);
  const [
    erreichbarkeitIpBereicheFailedAnzahl,
    setErreichbarkeitIpBereicheFailedAnzahl,
  ] = useState(Number);

  const [sapRdPossesWithoutEpcomRegions, setSapRdPossesWithoutEpcomRegions] =
    useState(Boolean);
  const [
    sapRdPossesWithoutEpcomRegionsAnzahl,
    setSapRdPossesWithoutEpcomRegionsAnzahl,
  ] = useState(String);

  const [archivTooManyFiles, setArchivTooManyFiles] = useState(Boolean);
  const [archivTooManyFilesThreshold, setArchivTooManyFilesThreshold] =
    useState(String);

  const [pingInvalidAutomatenAnzahl, setPingInvalidAutomatenAnzahl] =
    useState(Number);

  const [archivAuslastungExceeded, setArchivAuslastungExceeded] =
    useState(Boolean);

  const [jobsOk, setJobsOk] = useState(Boolean);

  const [epgCardVisible, setEpgCardVisible] = useState(Boolean);

  let navigate = useNavigate();

  useEffect(() => {
    setAlsLoginDritterAnzahl(
      cockpitInfo.epgCardInformationDTO?.alsLoginDritterAnzahl ?? "0"
    );
    setAlsLoginDritterExists(
      cockpitInfo.epgCardInformationDTO?.alsLoginDritterExists ?? false
    );
    setErreichbarkeitIpBereicheFailed(
      cockpitInfo.epgCardInformationDTO?.erreichbarkeitIpBereicheFailed ?? false
    );
    setErreichbarkeitIpBereicheFailedAnzahl(
      cockpitInfo.epgCardInformationDTO?.erreichbarkeitIpBereicheFailedAnzahl ??
        0
    );
    setSapRdPossesWithoutEpcomRegions(
      cockpitInfo.epgCardInformationDTO?.sapRdPossesWithoutEpcomRegions ?? false
    );
    setSapRdPossesWithoutEpcomRegionsAnzahl(
      cockpitInfo.epgCardInformationDTO?.sapRdPossesWithoutEpcomRegionsAnzahl ??
        "0"
    );
    setArchivTooManyFiles(
      cockpitInfo.epgCardInformationDTO?.archivTooManyFiles ?? false
    );
    setArchivTooManyFilesThreshold(
      cockpitInfo.epgCardInformationDTO?.archivTooManyFilesThreshold ?? "0"
    );
    setPingInvalidAutomatenAnzahl(
      cockpitInfo.epgCardInformationDTO?.pingInvalidAutomatenAnzahl ?? 0
    );
    setArchivAuslastungExceeded(
      cockpitInfo.epgCardInformationDTO?.archivAuslastungExceeded ?? false
    );
    setJobsOk(cockpitInfo.epgCardInformationDTO?.jobsOk ?? false);

    setEpgCardVisible(
      cockpitInfo.visibilities?.epgKachelShow !== undefined
        ? cockpitInfo.visibilities?.epgKachelShow
        : false
    );
  }, [cockpitInfo]);

  // React-Tree
  return (
    <Card hidden={!epgCardVisible}>
      <CardHeader title="EPG-Monitoring" />
      <List disablePadding>
        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="alsLoginDritterExists"
            primary={
              alsLoginDritterExists ? "Login Dritter: " : "Login Dritter"
            }
            colorChangeBool={!alsLoginDritterExists}
          />
          <ColoredSmallListItemTypography
            id="alsLoginDritterAnzahl"
            text={`${alsLoginDritterAnzahl}`}
            colorChangeBool={!alsLoginDritterExists}
            sx={{
              display: alsLoginDritterExists ? "inline" : "none",
            }}
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="erreichbarkeitIpBereicheFailed"
            primary={
              erreichbarkeitIpBereicheFailed
                ? "Erreichbarkeit IP-Bereiche:"
                : "Erreichbarkeit IP-Bereiche"
            }
            colorChangeBool={!erreichbarkeitIpBereicheFailed}
          />
          <ColoredSmallListItemTypography
            id="erreichbarkeitIpBereicheFailedAnzahl"
            text={`${erreichbarkeitIpBereicheFailedAnzahl}`}
            colorChangeBool={!erreichbarkeitIpBereicheFailed}
            sx={{
              display: erreichbarkeitIpBereicheFailed ? "inline" : "none",
            }}
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="sapRdPossesWithoutEpcomRegions"
            primary={
              sapRdPossesWithoutEpcomRegions
                ? "SAP-RD ohne Region:"
                : "SAP-RD ohne Region"
            }
            colorChangeBool={!sapRdPossesWithoutEpcomRegions}
          />
          <ColoredSmallListItemTypography
            id="sapRdPossesWithoutEpcomRegionsAnzahl"
            text={`${sapRdPossesWithoutEpcomRegionsAnzahl}`}
            colorChangeBool={!sapRdPossesWithoutEpcomRegions}
            sx={{
              display: sapRdPossesWithoutEpcomRegions ? "inline" : "none",
            }}
          />
        </SlimListItemButton>

        <SlimListItemButton
          divider
          onClick={() => navigate(HREF_BERICHTE_AUTOMATDATEN_GT_30)}
        >
          <ColoredSmallListItemText
            primary={`Automaten-Dateien im Archiv > ${archivTooManyFilesThreshold}`}
            colorChangeBool={!archivTooManyFiles}
            id="archivTooManyFiles"
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="pingInvalidAutomatenText"
            primary="Ping nicht aktiver Automaten: "
            colorChangeBool={pingInvalidAutomatenAnzahl <= 0}
          />
          <ColoredSmallListItemTypography
            id="pingInvalidAutomatenAnzahl"
            colorChangeBool={pingInvalidAutomatenAnzahl <= 0}
            text={`${pingInvalidAutomatenAnzahl}`}
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            primary={
              archivAuslastungExceeded
                ? "Archiv Plattenplatz knapp"
                : "Archiv Plattenplatz ok"
            }
            colorChangeBool={!archivAuslastungExceeded}
            id="isArchiveHardDriveOk"
          />
        </SlimListItemButton>

        <SlimListItemButton onClick={() => navigate(HREF_ADMIN_JOB_MONITORING)}>
          <ColoredSmallListItemText
            primary={jobsOk ? "Jobs ok" : "Jobs n. ok"}
            id="jobsOk"
            colorChangeBool={jobsOk}
          />
        </SlimListItemButton>
      </List>
    </Card>
  );
};

export default EpgMonitoring;
