import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

const LogfilefehlerPanelColumnDefs: Array<ColDef> = [
  {
    headerName: "Ermittelt am",
    field: "created",
    minWidth: 160,
    maxWidth: 160,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Fehlerbeschreibung",
    field: "fehlerbeschreibung",
    minWidth: 260,
  },
  {
    headerName: "Zusatzinformation",
    field: "additionalInfo",
    minWidth: 160,
  },
  {
    headerName: "Datum im Logfile",
    field: "additionalInfoDatum",
    minWidth: 160,
    maxWidth: 160,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Aktualisiert am",
    field: "updated",
    minWidth: 160,
    maxWidth: 160,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Aktualisiert durch",
    field: "userUpdate",
    minWidth: 160,
  },
  {
    headerName: "Erledigt",
    field: "erledigtNode",
    cellRenderer: CellValueRenderer,
    maxWidth: 90,
    minWidth: 90,
    cellClass: "ag-cell-center-justified-content",
  },
];

export default LogfilefehlerPanelColumnDefs;
