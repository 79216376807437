import { Box, Stack, Typography } from "@mui/material";

import JobInformation from "service/data-service/job-controller/interface/JobInformation";
import JobMonitoringHistoryDTO from "service/data-service/job-controller/interface/JobMonitoringHistoryDTO";
import {
  CronJobsCellValueRenderer,
  DateCellValueRenderer,
  FormatDateTimeCellValueRenderer,
} from "../grid/grid-column-renderer";

export type JobMonitoringDetails = JobMonitoringHistoryDTO | JobInformation;

export interface JobDetailsDialogContentProps {
  entry: JobMonitoringDetails;
}

const JobDetailsDialogContent: React.FC<JobDetailsDialogContentProps> = (
  props: JobDetailsDialogContentProps
) => {
  // const textColor = result.color === "red" ? "error.main" : "success.main";  color={textColor}

  return (
    <Stack rowGap={2}>
      {(props.entry as JobMonitoringHistoryDTO).jobKey && (
        <>
          <Typography fontWeight={500}>
            {(props.entry as JobMonitoringHistoryDTO).typ} -{" "}
            {(props.entry as JobMonitoringHistoryDTO).status}
          </Typography>
          <Stack className={"rowStack"} rowGap={2}>
            <Typography fontWeight={400} width={"6rem"}>
              Gestartet:
            </Typography>
            {FormatDateTimeCellValueRenderer({
              value: (props.entry as JobMonitoringHistoryDTO).gestartetDate,
            })}
          </Stack>
          <Stack className={"rowStack"} rowGap={2}>
            <Typography fontWeight={400} width={"6rem"}>
              Beendet:
            </Typography>
            {FormatDateTimeCellValueRenderer({
              value: (props.entry as JobMonitoringHistoryDTO).beendetDate,
            })}
          </Stack>
        </>
      )}
      {!(props.entry as JobMonitoringHistoryDTO).jobKey && (
        <>
          <Typography fontWeight={500}>
            {(props.entry as JobInformation).cronJobName} -{" "}
            {(props.entry as JobInformation).calculatedJobStatus ?? ""}
          </Typography>
          <Stack className={"rowStack"} rowGap={2}>
            <Typography fontWeight={400} width={"14rem"}>
              Geplante Ausführungszeit:
            </Typography>
            {DateCellValueRenderer({
              value: (props.entry as JobInformation).plannedExecution,
            })}
          </Stack>
          <Stack className={"rowStack"} rowGap={2}>
            <Typography fontWeight={400} width={"14rem"}>
              Tatsächliche Ausführungszeit:
            </Typography>
            {DateCellValueRenderer({
              value: (props.entry as JobInformation).realExecution,
            })}
          </Stack>
          <Stack className={"rowStack"} rowGap={2}>
            <Typography fontWeight={400} width={"14rem"}>
              Tatsächliche Ausführungszeit:
            </Typography>
            {CronJobsCellValueRenderer({
              value: (props.entry as JobInformation).cron,
            })}
          </Stack>
        </>
      )}
      {props.entry.info && (
        <>
          <Typography fontWeight={400}>Information aus Datenbank</Typography>
          <Box className="networkOpResultBox">
            <Typography className="networkOpResult">
              {props.entry.info}
            </Typography>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default JobDetailsDialogContent;
