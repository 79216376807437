import { EpcomResponse } from "App/service/http.service";
import { MaintenanceInfo } from "../interface/Access";

const MaintenanceInfoDTO = {
  convertMaintenanceResponse: (response: EpcomResponse): MaintenanceInfo => {
    if (response.data) {
      return response.data as MaintenanceInfo;
    }
    return {} as MaintenanceInfo;
  },
};
export default MaintenanceInfoDTO;
