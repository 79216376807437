import {
  CssBaseline,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import useModalController, {
  useModalDialog,
} from "global/hook/modal/use-modals";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import AccessProvider from "./components/Access-Control/AccessProvider";
import AppContent from "./components/AppContent/AppContent";
import TitleBar from "./components/Titlebar/Titlebar";
import { loadErrorTypeContainer } from "./script/loadErrorTypes";
import HttpService from "./service/http.service";
import AppTheme from "./theme/AppTheme";

export const App = () => {
  const modal = useModalDialog()[0];
  const dialogController = useModalController();
  const [restInterceptorsReady, setRestInterceptorsReady] = useState(false);

  useEffect(() => {
    ReactSession.setStoreType("localStorage");
    HttpService.registerDialogController(dialogController);
    loadErrorTypeContainer();
    setRestInterceptorsReady(true); // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={AppTheme}>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider>
          <div className="app">
            <CssBaseline />
            <Stack>
              <BrowserRouter>
                <AccessProvider restInterceptorsReady={restInterceptorsReady}>
                  <TitleBar />
                  <Toolbar variant="dense" className="separator" />
                  <AppContent />
                </AccessProvider>
              </BrowserRouter>
            </Stack>
            {modal && <ModalMessageDialog {...modal} />}
          </div>
        </SnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
