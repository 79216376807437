import { Stack, Typography, TypographyProps } from "@mui/material";
import { PropsWithChildren } from "react";

import "./automatgeplanteumstellungbox.scss";

interface AutomatDeploymentElementInfoProps {
  label: string;
  value?: string;
  labelTypographyProps?: TypographyProps;
  valueTypographyProps?: TypographyProps;
}

const AutomatDeploymentElementInfo = <T,>(
  props: PropsWithChildren<AutomatDeploymentElementInfoProps>
) => {
  const labelTypographyProps = {
    ...(props.labelTypographyProps ?? {}),
    className: `${
      props.labelTypographyProps?.className ?? ""
    } deploymentInfo__rowHeader`,
  };

  const valueTypographyProps = {
    ...(props.valueTypographyProps ?? {}),
    className: `${
      props.valueTypographyProps?.className ?? ""
    } deploymentInfo__rowContent`,
  };

  return (
    <Stack direction="row" spacing={1}>
      <Typography
        id={`deploymentinfo-element-label-${props.label}`}
        {...labelTypographyProps}
      >
        {props.label}
      </Typography>
      <Typography
        id={`deploymentinfo-element-value-${props.label}`}
        {...valueTypographyProps}
      >
        {props.value ?? ""}
      </Typography>
    </Stack>
  );
};

export default AutomatDeploymentElementInfo;
