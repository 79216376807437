import { Grid } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { FETCH_AUTOMAT_HISTORY } from "global/hook/datastore/automat/automat-history-store";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useEffect, useState } from "react";
import EditHistory from "service/data-service/history-controller/interface/EditHistory";
import HistoriePanelColumnDefs from "./grid-column-defs";

interface HistoriePanelProps {
  automatKey: string;
}

const HistoriePanel = (props: HistoriePanelProps) => {
  const [automatData, dispatch] = useAutomatData(props.automatKey);
  const [historyRecords, setHistoryRecords] = useState<
    Array<EditHistory> | undefined
  >(automatData.historyRecords);
  const windowViewport = useWindowViewport(0, 212);

  useEffect(() => {
    if (!automatData.historyRecords) {
      dispatch(FETCH_AUTOMAT_HISTORY);
    } else {
      setHistoryRecords(automatData.historyRecords);
    } // eslint-disable-next-line
  }, [automatData.historyRecords]);

  useEffect(() => {
    dispatch(FETCH_AUTOMAT_HISTORY); // eslint-disable-next-line
  }, [automatData.automatenblattInformation]);

  return (
    <Grid container spacing={5} width={"auto"}>
      <Grid item xs={12}>
        <DataGrid
          height={windowViewport.height}
          columnDefs={HistoriePanelColumnDefs}
          rowsDataDef={{ data: historyRecords }}
        />
      </Grid>
    </Grid>
  );
};

export default HistoriePanel;
