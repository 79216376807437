import { Grid } from "@mui/material";
import TextValue from "global/components/UI/TextValue/TextValue";
import { ChangeEvent, useState } from "react";
import IpWhitelist from "service/logfile-service/interface/IpWhitelist";

export interface IpWhitelistAddDialogContentProps {
  onDetailsChange: Function;
  entry?: IpWhitelist;
}

const IpWhitelistAddDialogContent = (
  props: IpWhitelistAddDialogContentProps
) => {
  const [entry, setEntry] = useState<IpWhitelist | undefined>(props.entry);

  const updateEntry = (data: IpWhitelist) => {
    setEntry((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
    props.onDetailsChange(data);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextValue
            label="Host:"
            value={entry?.host ?? ""}
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            TextFieldProps={{ className: "positionDetails__InputStyle" }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateEntry({ host: e.target.value } as IpWhitelist);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="Benutzername:"
            value={entry?.username ?? ""}
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            TextFieldProps={{ className: "positionDetails__InputStyle" }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateEntry({ username: e.target.value } as IpWhitelist);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="Beschreibung:"
            value={entry?.description ?? ""}
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            TextFieldProps={{ className: "positionDetails__InputStyle" }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateEntry({ description: e.target.value } as IpWhitelist);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default IpWhitelistAddDialogContent;
