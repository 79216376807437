import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import Enumerator from "global/components/EnumeratedValues/Enumerator";
import { Optional } from "global/util/interface/MappedTypesTransform";
import React, { useEffect, useState } from "react";
import "../../ui.scss";

interface RemoteSearchSelectBoxProps {
  label: string;
  placeholder?: string;
  enumerator: Enumerator;
  id: string;
  enableClearable?: boolean;
  reset?: boolean;
  required?: boolean;
  selection?: string;
  getSelectedValue?: (value: string | null) => void;
  AutocompleteProps?: Optional<AutocompleteProps<string, false, boolean, true>>;
  TypographyProps?: TypographyProps;
}

const RemoteSearchSelectBox: React.FC<RemoteSearchSelectBoxProps> = (
  props: RemoteSearchSelectBoxProps
) => {
  const { enumerator, reset } = props;

  const [value, setValue] = useState<string | null>(props.selection ?? null);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(filter);
    if (props.getSelectedValue) props.getSelectedValue(filter);
    setLoading(filter.length > 2);
    const identifier = setTimeout(() => doSearch(), 500);
    return () => clearTimeout(identifier); // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setLoading(enumerator.status !== "SYNC");
  }, [enumerator]);

  const doSearch = () => {
    if (filter.length > 2) {
      enumerator.syncFN(filter);
    } else {
      enumerator.syncFN();
    }
  };

  const selectionChangeHandler = (
    event: any,
    newValue: string | null
  ): void => {
    setValue(newValue);
    if (props.getSelectedValue) props.getSelectedValue(newValue);
  };

  useEffect(() => {
    setValue(props.selection ?? null);
  }, [props.selection, reset, setValue]);

  return (
    <Stack
      className="uiElement__Container uiElement__InputWithBox"
      columnGap={2}
    >
      {props.label && (
        <Typography
          className="uiElement__Text uiElement__Label"
          id={`${props.id}-label`}
          {...props.TypographyProps}
        >
          {props.label}
        </Typography>
      )}
      <Autocomplete
        freeSolo
        className="uiElement__TextElement"
        id={`${props.id}-autocomplete`}
        value={value}
        disableClearable={!props.enableClearable}
        onChange={selectionChangeHandler}
        options={enumerator.items.map((e) => e.value)}
        getOptionLabel={(option) => option}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={props.required}
              size={"small"}
              placeholder={props.placeholder}
              InputProps={{
                ...params.InputProps,

                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <Stack mr={0.75}>
                        <CircularProgress color="primary" size={18} />
                      </Stack>
                    ) : null}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
        onInputChange={(
          event: React.SyntheticEvent,
          value: string,
          reason: string
        ) => {
          if (reason === "input") {
            setFilter(value);
          }
        }}
        isOptionEqualToValue={(option, value) => option === value}
        {...props.AutocompleteProps}
      />
    </Stack>
  );
};

export default RemoteSearchSelectBox;
