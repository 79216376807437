import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";

const EpcomUserManual = () => {
  const windowViewport = useWindowViewport(0, 106);
  return (
    <>
      <object
        width="100%"
        height={windowViewport.height}
        data="/help/EPCOMHandbuch_1.8_EPCOM_2.0.pdf"
        type="application/pdf"
      >
        {" "}
      </object>
    </>
  );
};

export default EpcomUserManual;
