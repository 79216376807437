import { Button, Grid, Stack } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import { WIP_MENU_ENTRIES } from "App/components/Titlebar/components/Sidemenu/EpcomTreeView";
import { MENU_ID_RECHNUNGSDATEN_ATH } from "App/components/Titlebar/epcomm-treeview-items";
import { enumeratorItemForId } from "global/components/EnumeratedValues/Enumerator";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  SAP_BON_NUMMER_GUELTIG_PATH,
  SAP_BON_NUMMER_INFO_PATH,
  SAP_BON_NUMMER_PATH,
  TRACK_CHANGE,
  directPathMerger,
} from "global/hook/datastore/automat/change-tracker-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { AutomatTransponderInfo } from "global/hook/transponder/interface/Transponder";
import { useTransponder } from "global/hook/transponder/use-transponder";
import {
  EpcomReducerFunctionOptional,
  EpcomReducerOptional,
} from "global/interface/EpcomReducer";
import DateUtils, { getTomorrow } from "global/util/DateUtils";
import { DataControl } from "global/util/enum/DataControl";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { HREF_RECHNUNGSDATEN_SUCHE } from "global/util/routes";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import AutomatenblattInformation, {
  changeRnsObjectWithRnsGln,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import RnsService from "service/data-service/rns-controller/Rns.service";
import RnsHistory from "service/data-service/rns-controller/interface/RnsHistory";
import { generateDefaultAutomatenblattInformation } from "../../../generator/defaultAutomatenblattInformation";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import "../panels.scss";
import CPChange from "./components/CPChange";
import GlnChange from "./components/GlnChange";
import RNSAktiveAutomaten from "./components/RNSAktiveAutomaten";
import MarktArtEnumerator from "./components/generator/markArtEnumerator";
import { RNSPanelColumnDefs } from "./grid-column-defs";
import "./rnsPanel.scss";

interface RNSPanelProps extends PanelBaseProps {
  automatKey: string;
}

const RNSPanel = (props: RNSPanelProps) => {
  const [automatData, dispatchUpdate, dataWithChanges] = useAutomatData(
    props.automatKey
  );
  const accessContext = useContext(AccessContext);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const transponderCtrl = useTransponder()[1];
  const navigate = useNavigate();

  const stateChangeAndReset: EpcomReducerFunctionOptional<
    AutomatenblattInformation
  > = (state, action) => {
    if (action.type === DataControl.CHANGE && action.valueToChange) {
      return { ...state, ...action.valueToChange };
    } else {
      // RNS will be ignored at discard, except serial number changes
      let rns = state.rns;

      if (
        state.seriennummer !==
        automatData.automatenblattInformation.seriennummer
      ) {
        rns = automatData.automatenblattInformation.rns;
      }

      return {
        ...dataWithChanges,
        rns: rns,
      };
    }
  };

  const [automatenblattInformation, dispatchAutomatenblattInformation] =
    useReducer<EpcomReducerOptional<AutomatenblattInformation>>(
      stateChangeAndReset,
      dataWithChanges ?? generateDefaultAutomatenblattInformation()
    );

  const changeAutomatenblattInformation = (
    value: Optional<AutomatenblattInformation>
  ) => {
    dispatchAutomatenblattInformation({
      type: DataControl.CHANGE,
      valueToChange: value,
    });
  };

  const [history, setHistory] = useState<Array<RnsHistory>>([]);

  const retrieveRNSHistory = useCallback(() => {
    setIsFetchingData(true);
    RnsService.searchRNSHistory(
      automatenblattInformation.automatKey,
      (data: RnsHistory[]) => {
        setIsFetchingData(false);
        setHistory(data);
      },
      () => {
        setIsFetchingData(false);
      }
    );
  }, [automatenblattInformation.automatKey]);

  // Initialising at render and reset
  useEffect(() => {
    dispatchAutomatenblattInformation({ type: DataControl.RESET });
  }, [automatData, props.resetValues]);

  // Get Machine History on Render
  useEffect(() => {
    if (automatenblattInformation.automatKey) retrieveRNSHistory();
  }, [automatenblattInformation.automatKey, retrieveRNSHistory]);

  //Navigate to Rechnungsdaten ATH search positions page
  const navigateToSearchPositions = () => {
    transponderCtrl.setTransponderData({
      seriennr: `${automatenblattInformation.seriennummer}`,
      rnsGln: automatenblattInformation.rns.gln,
    } as AutomatTransponderInfo);
    navigate(HREF_RECHNUNGSDATEN_SUCHE);
  };

  const sapBonNummerGueltigDateForDisplay = () => {
    const aDate = dataWithChanges.sapBonNummerDTO.gueltigVon;

    if (!aDate) return aDate;

    if (typeof aDate === "string") {
      return DateUtils.arrayToDate(DateUtils.apiStringToNumberArray(aDate));
    }
    return DateUtils.arrayToDate(dataWithChanges.sapBonNummerDTO.gueltigVon);
  };

  const registerUserChange = (path: string, value: string) => {
    let refValue: string = "";
    switch (path) {
      case "marktArt":
        refValue = automatData.automatenblattInformation.marktArt;
        break;
      case SAP_BON_NUMMER_PATH:
        refValue =
          automatData.automatenblattInformation.sapBonNummerDTO.bonNummer ?? "";
        break;
      case SAP_BON_NUMMER_GUELTIG_PATH:
        refValue =
          DateUtils.formatDateToAPIDateString(
            DateUtils.arrayToDate(
              automatData.automatenblattInformation.sapBonNummerDTO.gueltigVon
            )
          ) ?? "";
        break;
      case SAP_BON_NUMMER_INFO_PATH:
        refValue =
          automatData.automatenblattInformation.sapBonNummerDTO.info ?? "";
        break;
      case "infofeld1":
        refValue = automatData.automatenblattInformation.infofeld1 ?? "";
        break;
      case "infofeld2":
        refValue = automatData.automatenblattInformation.infofeld2 ?? "";
        break;
      case "infofeld3":
        refValue = automatData.automatenblattInformation.infofeld3 ?? "";
        break;
      case "ruhtVon":
        refValue =
          DateUtils.formatDateToAPIDateString(
            automatData.automatenblattInformation.ruhtVon
          ) ?? "";
        break;
      case "ruhtBis":
        refValue =
          DateUtils.formatDateToAPIDateString(
            automatData.automatenblattInformation.ruhtBis
          ) ?? "";
        break;
      default:
        return;
    }
    dispatchUpdate(TRACK_CHANGE, {
      change: {
        path: path,
        refValue: refValue,
        value: value ?? "",
        merger: directPathMerger,
      },
    } as AutomatActionPayload);
  };

  return (
    <Grid container>
      <Grid className="grid_item_30rem" item xs={12} sm={6}>
        <TextValue
          label="GLN:"
          id="gln"
          value={automatenblattInformation.rns.gln}
          readOnly
          disableInputUnderline={false}
        />
        <GlnChange
          automatenblattInformation={automatenblattInformation}
          onGlnChange={() => {
            retrieveRNSHistory();
          }}
        />
        <TextValue
          label="Name:"
          value={automatenblattInformation.rns.name}
          id="name"
          readOnly
        />
        <TextValue
          label="Straße:"
          value={automatenblattInformation.rns.strasse}
          id="strasse"
          readOnly
        />
        <TextValue
          label="PLZ:"
          value={automatenblattInformation.rns.plz}
          id="plz"
          readOnly
        />
        <TextValue
          label="Ort:"
          value={automatenblattInformation.rns.ort}
          id="ort"
          readOnly
        />
        <TextValue
          label="Datum von:"
          value={DateUtils.optionalTimeArrayToGermanString(
            automatenblattInformation.rns.sapGueltigVon
          )}
          id="sap-gueltig-von"
          readOnly
        />
        <TextValue
          label="Datum bis:"
          value={DateUtils.optionalTimeArrayToGermanString(
            automatenblattInformation.rns.sapGueltigBis
          )}
          id="sap-gueltig-bis"
          readOnly
        />
        <RNSAktiveAutomaten
          automatenblattInformation={automatenblattInformation}
        />
        <SelectBox
          id="markArt"
          label="Markt-Art:"
          enumerator={MarktArtEnumerator}
          selection={enumeratorItemForId(
            MarktArtEnumerator,
            dataWithChanges.marktArt
          )}
          getSelectedValue={(value) =>
            registerUserChange("marktArt", value?.id as string)
          }
          reset={props.resetValues}
        />
        <TextValue
          label="SAP Bon Nr.:"
          id="sap-bon-nummer"
          value={dataWithChanges.sapBonNummerDTO.bonNummer ?? ""}
          onChange={(ev) =>
            registerUserChange(SAP_BON_NUMMER_PATH, ev.target.value ?? "")
          }
        />
        <DatePickerElement
          label="SAP Bon Nr. gültig von:"
          defaultValue={sapBonNummerGueltigDateForDisplay()}
          getSelectedValue={(value) =>
            registerUserChange(
              SAP_BON_NUMMER_GUELTIG_PATH,
              DateUtils.formatDateToAPIDateString(value) ?? ""
            )
          }
          id="sap-bon-nummer-gueltig-von"
          resetToDefault={props.resetValues}
          datePickerProperties={{
            minDate: getTomorrow(),
            disablePast: true,
          }}
        />
        <TextValue
          label="Info ATH SAP Bon Nr.:"
          id="sap-bon-nummer-info-ath"
          value={dataWithChanges.sapBonNummerDTO.info ?? ""}
          onChange={(ev) =>
            registerUserChange(SAP_BON_NUMMER_INFO_PATH, ev.target.value ?? "")
          }
          maxLength={360}
          TextFieldProps={{
            multiline: true,
            rows: 5,
          }}
        />
        <TextValue
          label="Infofeld 1:"
          id="sap-infofeld-1"
          value={dataWithChanges.infofeld1 ?? ""}
          onChange={(ev) =>
            registerUserChange("infofeld1", ev.target.value ?? "")
          }
        />
        <TextValue
          label="Infofeld 2:"
          id="sap-infofeld-2"
          value={dataWithChanges.infofeld2 ?? ""}
          onChange={(ev) =>
            registerUserChange("infofeld2", ev.target.value ?? "")
          }
        />
        <TextValue
          label="Infofeld 3:"
          id="sap-infofeld-3"
          value={dataWithChanges.infofeld3 ?? ""}
          onChange={(ev) =>
            registerUserChange("infofeld3", ev.target.value ?? "")
          }
        />
      </Grid>
      <Grid className="grid_item_30rem" item xs={12} sm={6}>
        <TextValue
          label="Ansprechpartner:"
          value={automatenblattInformation.rns.kontaktName ?? ""}
          id="partner-ansprechpartner"
          readOnly
        />
        <TextValue
          label="Telefon1:"
          value={automatenblattInformation.rns.kontaktTelefon1 ?? ""}
          id="partner-telefon1"
          readOnly
        />
        <TextValue
          label="Telefon 2:"
          value={automatenblattInformation.rns.kontaktTelefon2 ?? ""}
          id="partner-telefon2"
          readOnly
        />
        <TextValue
          label="EMail:"
          value={automatenblattInformation.rns.kontaktEmail ?? ""}
          id="partner-email"
          readOnly
        />
        <TextValue
          label="Bemerkung1:"
          value={automatenblattInformation.rns.bemerkung1 ?? ""}
          id="partner-bemerkung1"
          readOnly
        />
        <TextValue
          label="Bemerkung2:"
          value={automatenblattInformation.rns.bemerkung2 ?? ""}
          id="partner-bemerkung2"
          readOnly
        />
        <TextValue
          label="Bemerkung3:"
          value={automatenblattInformation.rns.bemerkung3 ?? ""}
          id="partner-bemerkung3"
          readOnly
        />
        <TextValue
          label="Organisationseinheit:"
          value={automatenblattInformation.rns.edekaOrganisation.name ?? ""}
          id="partner-organisation"
          multiline
          readOnly
        />
        <Stack className={"rowStack minWidth30"} spacing={1}>
          <CPChange
            automatKey={props.automatKey}
            rnsInfo={automatenblattInformation.rns}
            getChangedRnsGln={(changedValues) => {
              changeAutomatenblattInformation({
                rns: changeRnsObjectWithRnsGln(
                  automatenblattInformation.rns,
                  changedValues
                ),
              });
            }}
            disabled={
              !accessContext.getAccessContainer()
                .showAutomatenTabBtnAnsprechpartnerDaten ||
              automatenblattInformation.readonly
            }
          />
          {accessContext.getAccessContainer()
            .showAutomatenTabBtnRechnungsdatenAth &&
            !WIP_MENU_ENTRIES.includes(MENU_ID_RECHNUNGSDATEN_ATH) && (
              <Button
                className="cpChangeButton rechnungsdaten__ButtonStyle"
                variant="contained"
                onClick={navigateToSearchPositions}
                size="small"
              >
                ATH Rechnungsdaten
              </Button>
            )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          height={500}
          columnDefs={RNSPanelColumnDefs}
          rowsDataDef={{
            data: history,
            isFetchingData: isFetchingData,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RNSPanel;
