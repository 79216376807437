import HttpService, { EpcomResponse } from "App/service/http.service";
import Config from "Config";
import { DialogController } from "global/hook/modal/interface/ModalController";
import Access from "../access-controller/interface/Access";
import AutomatSteckbriefDTO from "./dto/AutomatSteckbriefDTO";

const AutomatSteckbriefService = {
  getSteckbrief: (
    gln: string,
    dialogController: DialogController,
    access: Access,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(Config.btbApiUrl + "info/getAutomatenSteckbrief/" + gln)
      .then((response: EpcomResponse) => {
        callbackOnSuccess(
          AutomatSteckbriefDTO.convertResponseToAutomatSteckbriefs(response),
          dialogController,
          access
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default AutomatSteckbriefService;
