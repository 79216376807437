import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  Input,
  InputAdornment,
  InputProps,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { HTMLInputTypeAttribute } from "react";
import "../../ui.scss";

interface TextValueProps {
  label?: string;
  value: string | number | undefined;
  placeholder?: string;
  id?: string;
  readOnly?: boolean;
  maxLength?: number;
  multiline?: boolean;
  disableInputUnderline?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterKey?: () => void;
  onEscapeKey?: () => void;
  type?: HTMLInputTypeAttribute | undefined;
  TypographyProps?: TypographyProps;
  InputProps?: InputProps;
  TextFieldProps?: TextFieldProps;
  hidden?: boolean;
  inputClassName?: string;
}

const TextValue: React.FC<TextValueProps> = (props: TextValueProps) => {
  let hideUnderline = props.disableInputUnderline ?? props.readOnly ?? false;
  const isReadonly: boolean = props.readOnly ?? false;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => event.preventDefault();

  let { inputProps: givenInputProps = {}, ...otherTextProps } =
    props.TextFieldProps ?? { inputProps: {} };

  return (
    <Stack
      className="epcom__TextValue"
      columnGap={2}
      display={props.hidden ? "none" : "flex"}
    >
      {props.label && (
        <Typography id={`${props.id}-label`} {...props.TypographyProps}>
          {props.label}
        </Typography>
      )}
      {isReadonly && (
        <Input
          id={`${props.id}-input`}
          readOnly
          disableUnderline={hideUnderline}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          multiline={props.multiline}
          style={{ minWidth: props.multiline ? "18rem" : "" }}
          type={props.type}
          {...props.InputProps}
        />
      )}
      {!isReadonly && (
        <TextField
          id={`${props.id}-input`}
          value={props.value}
          size="small"
          placeholder={props.placeholder}
          onChange={props.onChange}
          onKeyUp={(event) => {
            if (event.key === "Enter" && props.onEnterKey) {
              props.onEnterKey();
            }
            if (event.key === "Escape" && props.onEscapeKey) {
              props.onEscapeKey();
            }
          }}
          autoComplete="off"
          multiline={props.multiline}
          style={{ minWidth: props.multiline ? "18rem" : "" }}
          type={
            props.type !== "password"
              ? props.type
              : showPassword
              ? "text"
              : "password"
          }
          inputProps={{
            ...givenInputProps,
            "data-1p-ignore": "true",
            maxLength: props.maxLength,
            className: props.inputClassName,
          }}
          InputProps={{
            endAdornment: props.type === "password" &&
              givenInputProps.readOnly !== true && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
          }}
          {...otherTextProps}
        />
      )}
    </Stack>
  );
};
export default TextValue;
