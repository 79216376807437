import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import AutomatModellResult, {
  AutomatModell,
} from "service/data-service/kaufanfragen-controller/interface/AutomatModell";
import AutomatenblattStammdaten, {
  Stammdaten,
} from "service/data-service/stammdaten-controller/interface/AutomatenblattStammdaten";
import StammdatenService from "service/data-service/stammdaten-controller/Stammdaten.service";
import {
  EnumeratorItemSourceType,
  EnumItemBuilder,
  EnumItemsExtractFn,
} from "../use-enumerator";

const stammdatenBuilder: EnumItemBuilder = (raw: EnumeratorItemSourceType) => {
  const rawItem = raw as Stammdaten;
  return {
    id: rawItem.key,
    value: rawItem.value,
    source: raw,
  } as EnumeratorItem;
};

const automatModellBuilder: EnumItemBuilder = (
  raw: EnumeratorItemSourceType
) => {
  const rawItem = raw as AutomatModell;
  return {
    id: rawItem.key,
    value: rawItem.name,
    source: raw,
  } as EnumeratorItem;
};

export const onStammdatenResult: EnumItemsExtractFn = (
  enumeratorID: string,
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  StammdatenService.retrieveStammdatenInformation(
    enumeratorID,
    (stammdatenInformationDTO: AutomatenblattStammdaten) =>
      onEnumSyncSuccess(stammdatenInformationDTO.row, stammdatenBuilder),
    (err: Error) =>
      onEnumSyncError("Syncing stammdaten failed for " + enumeratorID, err)
  );
};

export const onAutomatModellResult: EnumItemsExtractFn = (
  manufacturer: string,
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  if (manufacturer.length > 0)
    StammdatenService.manufaturerAutomatModels(
      manufacturer,
      (result: AutomatModellResult) =>
        onEnumSyncSuccess(result.Row, automatModellBuilder),
      (err: Error) =>
        onEnumSyncError(
          "Syncing automat models failed for manufacturer " + manufacturer,
          err
        )
    );
};
