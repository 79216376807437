import { Button, Stack } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import Enumerator, {
  enumeratorItemForId,
} from "global/components/EnumeratedValues/Enumerator";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  AUTOMAT_STATUS_GUELTIG_VON_IN_ZUKUNFT_PATH,
  AUTOMAT_STATUS_IN_ZUKUNFT_PATH,
  TRACK_CHANGE,
  directPathMerger,
} from "global/hook/datastore/automat/change-tracker-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  AUTOMATENBLATT_AUTOMATART_ENUM_ID,
  AUTOMATENBLATT_AUTOMATMODELLE_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID,
  AUTOMAT_ALE_VERSION_ENUM_ID,
  AUTOMAT_DPG_KATEGORIE_ENUM_ID,
  AUTOMAT_IT_SICHERHEITSKONZEPT_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useModalDialog } from "global/hook/modal/use-modals";
import DateUtils from "global/util/DateUtils";
import { noop } from "global/util/utils";
import { useContext, useEffect } from "react";
import AutomatSteckbriefService from "service/data-service/automate-controller/AutomatSteckbrief.service";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import "../panels.scss";

import AutomatDeploymentInfo from "./component/AutomatDeploymentInfo";
import "./detailspanel.scss";

interface DetailsPanelProps extends PanelBaseProps {
  automatKey: string;
}

const DetailsRightItem = (props: DetailsPanelProps) => {
  const [automatData, dispatchUpdate, dataWithChanges] = useAutomatData(
    props.automatKey
  );
  const [dialog, dialogController] = useModalDialog("automat-steckbrief-pdf");

  const automatStatusZukunft: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID
  );
  const automatArt: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATART_ENUM_ID
  );
  const automatModel: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATMODELLE_ENUM_ID,
    true
  );

  const dpgAutomatKategorie: Enumerator = useEnumerator(
    AUTOMAT_DPG_KATEGORIE_ENUM_ID
  );
  const dpgJaNein: Enumerator = useEnumerator(
    AUTOMAT_IT_SICHERHEITSKONZEPT_ENUM_ID
  );
  const aleVersion: Enumerator = useEnumerator(AUTOMAT_ALE_VERSION_ENUM_ID);

  const accessContext = useContext(AccessContext);
  const access = accessContext.getAccessContainer();

  const registerUserChange = (path: string, value: string) => {
    let refValue: string = "";
    switch (path) {
      case AUTOMAT_STATUS_IN_ZUKUNFT_PATH:
        refValue =
          automatData.automatenblattInformation.automatStatusInZukunft ?? "";
        break;
      case AUTOMAT_STATUS_GUELTIG_VON_IN_ZUKUNFT_PATH:
        refValue =
          DateUtils.formatDateToAPIDateString(
            automatData.automatenblattInformation
              .automatStatusGueltigVonInZukunft
          ) ?? "";
        break;
      case "artKey":
        refValue = automatData.automatenblattInformation.artKey;
        break;
      case "modellKey":
        refValue = automatData.automatenblattInformation.modellKey;
        break;
      case "ssidAktiv":
        refValue = automatData.automatenblattInformation.ssidAktiv;
        break;
      case "ruhtVon":
        refValue =
          DateUtils.formatDateToAPIDateString(
            automatData.automatenblattInformation.ruhtVon
          ) ?? "";
        break;
      case "ruhtBis":
        refValue =
          DateUtils.formatDateToAPIDateString(
            automatData.automatenblattInformation.ruhtBis
          ) ?? "";
        break;
      default:
        return;
    }
    dispatchUpdate(TRACK_CHANGE, {
      change: {
        path: path,
        refValue: refValue,
        value: value ?? "",
        merger: directPathMerger,
      },
    } as AutomatActionPayload);
  };

  const dateForDisplay = (aDate: Date | string | undefined | null) => {
    if (!aDate) return undefined;

    if (typeof aDate === "string") {
      return DateUtils.arrayToDate(DateUtils.apiStringToNumberArray(aDate));
    }
    return aDate as Date;
  };

  const getAutomatSteckbrief = () => {
    if (process.env.NODE_ENV !== "test") {
      /*
      workaround for -- ReferenceError: TextEncoder is not defined -- while running tests
      */
      import(
        /* webpackChunkName: "pdf-generator" */
        /* webpackMode: "eager" */
        "./pdf_entrypoint"
      ).then((pdf) => {
        AutomatSteckbriefService.getSteckbrief(
          automatData.automatenblattInformation.rns.gln,
          dialogController,
          access,
          pdf.default
        );
      });
    } else {
      AutomatSteckbriefService.getSteckbrief(
        automatData.automatenblattInformation.rns.gln,
        dialogController,
        access,
        noop
      );
    }
  };

  // Initialising at render and reset
  useEffect(() => {
    automatModel.syncFN(
      automatData.automatenblattInformation.herstellerKuerzel
    ); // eslint-disable-next-line
  }, [automatData.automatenblattInformation]);

  return (
    <Stack spacing={0.5}>
      <SelectBox
        id="automatStatusInZukunft"
        label="Zust. in Zukunft:"
        enableClearable
        enumerator={automatStatusZukunft}
        selection={enumeratorItemForId(
          automatStatusZukunft,
          dataWithChanges.automatStatusInZukunft
        )}
        getSelectedValue={(value) =>
          registerUserChange(
            AUTOMAT_STATUS_IN_ZUKUNFT_PATH,
            value?.id as string
          )
        }
        reset={props.resetValues}
      />
      <DatePickerElement
        label="gültig ab:"
        defaultValue={dateForDisplay(
          dataWithChanges.automatStatusGueltigVonInZukunft
        )}
        getSelectedValue={(value) =>
          registerUserChange(
            AUTOMAT_STATUS_GUELTIG_VON_IN_ZUKUNFT_PATH,
            DateUtils.formatDateToAPIDateString(value) ?? ""
          )
        }
        resetToDefault={props.resetValues}
        id="automatStatusGueltigVonInZukunft"
        datePickerProperties={{
          disablePast: true,
        }}
      />
      <SelectBox
        id="artKey"
        label="Automaten Art:"
        enumerator={automatArt}
        selection={enumeratorItemForId(automatArt, dataWithChanges.artKey)}
        getSelectedValue={(value) =>
          registerUserChange("artKey", value?.id as string)
        }
        reset={props.resetValues}
      />
      <SelectBox
        id="modelKey"
        label="Automaten Modell:"
        enumerator={automatModel}
        selection={enumeratorItemForId(automatModel, dataWithChanges.modellKey)}
        getSelectedValue={(value) =>
          registerUserChange("modellKey", value?.id as string)
        }
        reset={props.resetValues}
      />
      <TextValue
        id="ssidAktiv"
        label="Aktive SSID:"
        value={dataWithChanges.ssidAktiv}
        onChange={(event) =>
          registerUserChange("ssidAktiv", event.target.value)
        }
      />
      <DatePickerElement
        label="ruht von:"
        defaultValue={dateForDisplay(dataWithChanges.ruhtVon)}
        getSelectedValue={(value) =>
          registerUserChange(
            "ruhtVon",
            DateUtils.formatDateToAPIDateString(value) ?? ""
          )
        }
        resetToDefault={props.resetValues}
        id="ruhtVon"
      />
      <DatePickerElement
        label="ruht bis:"
        defaultValue={dateForDisplay(dataWithChanges.ruhtBis)}
        getSelectedValue={(value) =>
          registerUserChange(
            "ruhtBis",
            DateUtils.formatDateToAPIDateString(value) ?? ""
          )
        }
        resetToDefault={props.resetValues}
        id="ruhtBis"
      />
      <Stack className="epcomSectionGap" direction="row" spacing={1}></Stack>

      <AutomatDeploymentInfo
        label="DPG-Kategorie"
        type="DPG"
        auspragungEnumerator={dpgAutomatKategorie}
        referenceData={automatData}
        automatenblattInformation={dataWithChanges}
        dispatchAutomatDataChange={dispatchUpdate}
      />
      <AutomatDeploymentInfo
        label="IT-Sicherheitskonzept"
        type="SICHERHEIT"
        auspragungEnumerator={dpgJaNein}
        referenceData={automatData}
        automatenblattInformation={dataWithChanges}
        dispatchAutomatDataChange={dispatchUpdate}
      />
      <AutomatDeploymentInfo
        label="ALE"
        type="VERSION"
        auspragungEnumerator={aleVersion}
        referenceData={automatData}
        automatenblattInformation={dataWithChanges}
        dispatchAutomatDataChange={dispatchUpdate}
      />

      <Stack className="epcomSectionGap" direction="row" spacing={1}></Stack>
      {access.rollenTyp !== "ATH" && (
        <Stack direction="row" spacing={1}>
          <Button
            className="cpChangeButton"
            variant="contained"
            onClick={getAutomatSteckbrief}
            size="small"
          >
            Automatensteckbrief
          </Button>
        </Stack>
      )}
      {dialog && <ModalMessageDialog {...dialog} />}
    </Stack>
  );
};

export default DetailsRightItem;
