import Enumerator, {
  EnumeratorItem,
  EnumeratorItemId,
} from "global/components/EnumeratedValues/Enumerator";
import { JobStatus } from "service/data-service/job-controller/interface/JobInformation";

const StatusFilterItems = {
  items: [
    {
      id: 1,
      value: "Fehlerhaft",
      source: null,
    },
    {
      id: 2,
      value: "Bereit",
      source: null,
    },
    {
      id: 3,
      value: "Gerade am Laufen",
      source: null,
    },
    {
      id: 4,
      value: "Erfolgreich",
      source: null,
    },
  ],
} as Enumerator;

const mapIdToFilter = (id: EnumeratorItemId): JobStatus | undefined => {
  switch (id) {
    case 1:
      return JobStatus.FAILURE;
    case 2:
      return JobStatus.READY;
    case 3:
      return JobStatus.RUNNING;
    case 4:
      return JobStatus.SUCCESS;
    default:
      return undefined;
  }
};

export const getStatusFilterListFromIds = (
  list: EnumeratorItem[]
): string[] => {
  const stringList: string[] = [];
  list.forEach((id) => {
    const stringFromId = mapIdToFilter(id.id);

    if (stringFromId !== undefined) {
      stringList.push(stringFromId);
    }
  });

  return stringList;
};

export default StatusFilterItems;
