import HttpService from "App/service/http.service";
import Config from "Config";
import useModalController from "global/hook/modal/use-modals";
import DateUtils from "global/util/DateUtils";
import fileDownload from "js-file-download";

const downloading = "Wird heruntergeladen....";

const FlatFileService = {
  exportToJson: (
    rnsGln: string,
    serienNr: string,
    aktiveAutomaten: string,
    callbackOnFail?: Function
  ) => {
    LoadDialog(true);
    HttpService.api
      .sendGet(`${Config.dataServiceUrl}reporting/flatfile/export`, {
        params: {
          rnsGln: rnsGln,
          serienNr: serienNr,
          aktiveAutomaten: aktiveAutomaten,
        },
      })
      .then((response) => {
        fileDownload(
          JSON.stringify(response.data),
          "PFD_Flatfile_" +
            DateUtils.formatDateToAPIDateString(new Date(), "yyyy_MM_dd") +
            ".json",
          "application/json"
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      })
      .finally(() => {
        LoadDialog(false);
      });
  },
};

const LoadDialog = (show: boolean) => {
  const dialogController = useModalController();
  if (show) {
    dialogController.showLoadingDialog(downloading);
  } else {
    dialogController.closeModal();
  }
};

export default FlatFileService;
