import { Button, Grid, Stack, Typography } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import useSnackbarGenerator from "App/hook/use-snackbars";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UICheckbox from "global/components/UI/UICheckbox/UICheckbox";
import {
  AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID,
  AUTOMATENBLATT_NOTIZTYP_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import DateUtils, { getTomorrow } from "global/util/DateUtils";
import { useCallback, useContext, useEffect, useState } from "react";
import NoteService from "service/data-service/notizen-controller/Note.service";
import NoteRequestDTO from "service/data-service/notizen-controller/interface/NoteRequest";
import { Note } from "service/data-service/notizen-controller/interface/NoteSearchResult";
import { generateDefaultNoteRequest } from "service/data-service/notizen-controller/mapping/generator/defaultNoteDTOGenerator";
import "../panels.scss";
import NoteDialog from "./components/NoteDialog";
import { generateDefaultNote } from "./generator/defaultNote";
import "./notes.scss";
import NotizenController from "./script/NotizenController";
import { NotizenPanelColumnDefs } from "./script/columnDefinition";

interface NotizenPanelProps {
  automatKey: string;
  disabled?: boolean;
}
const NotizenPanel = (props: NotizenPanelProps) => {
  const snackbarGenerator = useSnackbarGenerator();
  const notizTypEnumerator = useEnumerator(AUTOMATENBLATT_NOTIZTYP_ENUM_ID);
  const notizStatusEnumerator = useEnumerator(
    AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID
  );

  const [noteRequest, setNoteRequest] = useState<NoteRequestDTO>({
    ...generateDefaultNoteRequest(),
    automatKey: props.automatKey,
  });
  const [readableAth, setReadableAth] = useState<boolean>(false);
  const [notesToGrid, setNotesToGrid] = useState<Array<Note>>();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false);
  const [noteToDialog, setNoteToDialog] = useState<Note>(generateDefaultNote());
  const [resetFieldsOnToggle, setResetFieldsOnToggle] = useState(false);
  const accessContext = useContext(AccessContext);

  const windowViewport = useWindowViewport(0, 415);

  function clearTextFields() {
    setReadableAth(false);
    setNoteRequest(generateDefaultNoteRequest());
    setResetFieldsOnToggle((state) => !state);
  }

  function handleNewNoteClick(noteRequest: NoteRequestDTO) {
    noteRequest.notizKopfText = noteRequest.notizKopfText.trim();
    setNoteRequest(noteRequest);

    if (NotizenController.isValidNewNoteInput(noteRequest, snackbarGenerator)) {
      NoteService.noteSaveOrUpdate(noteRequest, (data: any) => {
        setNotesToGrid(
          (existingNotes) => [data, ...(existingNotes ?? [])] as Array<Note>
        );
        clearTextFields();
      });
    }
  }

  const retrieveNotes = useCallback(() => {
    setIsFetchingData(true);
    NoteService.noteSearch(
      "automatKey",
      props.automatKey,
      (searchResult) => {
        setIsFetchingData(false);
        setNotesToGrid(searchResult.rows);
      },
      () => {
        setIsFetchingData(false);
      }
    );
  }, [props.automatKey]);

  // Load all Notes on page render and new note push
  useEffect(() => {
    retrieveNotes();
  }, [retrieveNotes]);

  return (
    <Stack spacing={2}>
      <Grid container rowSpacing={1} columnSpacing={2} width={"auto"}>
        <Grid item xs={12} lg={4} xl={3}>
          <SelectBox
            label="Notiz Typ:"
            enumerator={notizTypEnumerator}
            id="notiz-typ"
            getSelectedValue={(item) => {
              setNoteRequest({
                ...noteRequest,
                type: item?.id.toString() ?? "",
              });
            }}
            reset={resetFieldsOnToggle}
            AutocompleteProps={{ className: "inputTextFieldForNotes" }}
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <SelectBox
            label="Notiz Status:"
            enumerator={notizStatusEnumerator}
            id="notiz-status"
            getSelectedValue={(item) => {
              setNoteRequest({
                ...noteRequest,
                status: item?.id.toString() ?? "",
              });
            }}
            reset={resetFieldsOnToggle}
            AutocompleteProps={{ className: "inputTextFieldForNotes" }}
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <DatePickerElement
            className="datePickerForNotes"
            label="Wiedervorlage:"
            getSelectedValue={(value) => {
              setNoteRequest({
                ...noteRequest,
                wiedervorlage:
                  DateUtils.formatDateToAPIDateString(value ?? undefined) ?? "",
              });
            }}
            resetToDefault={resetFieldsOnToggle}
            id="wiedervorlage"
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
            TextFieldProps={{ className: "inputTextFieldForNotes" }}
            datePickerProperties={{
              minDate: getTomorrow(),
              disablePast: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={3}>
          <UICheckbox
            disabled={
              !accessContext.getAccessContainer()
                .showAutomatenTabCbNotizAthLeseberechtigung
            }
            label="Leseberechtigung:"
            value={readableAth}
            id="leseberechtigung"
            onChange={(event) => {
              setReadableAth(event.target.checked);
              setNoteRequest({
                ...noteRequest,
                notizReadRoles: event.target.checked ? "ATH" : null,
              });
            }}
            TypographyProps={{ className: "fontForNotes" }}
          >
            <Typography className="fontForNotes">ATH</Typography>
          </UICheckbox>
        </Grid>
      </Grid>

      <TextValue
        label="Notiz:"
        value={noteRequest.notizKopfText}
        id={"notiz-text"}
        onChange={(event) => {
          setNoteRequest({
            ...noteRequest,
            notizKopfText: event.target.value,
          });
        }}
        TypographyProps={{ className: "labelForNotes fontForNotes" }}
        TextFieldProps={{
          className: "fontForNotes noteTextInput",
          multiline: true,
        }}
      />
      <Button
        variant="contained"
        className="createNewNoteButton"
        disabled={
          !accessContext.getAccessContainer().showAutomatenTabBtnNotizAnlegen ||
          props.disabled
        }
        onClick={() => {
          handleNewNoteClick({
            ...noteRequest,
            notizAktion: "SAVENEWNOTIZ",
          });
        }}
      >
        Neue Notiz anlegen
      </Button>

      <DataGrid
        height={windowViewport.height}
        columnDefs={NotizenPanelColumnDefs}
        rowsDataDef={{
          data: notesToGrid,
          isFetchingData: isFetchingData,
        }}
        onRowClicked={(row) => {
          setShowNoteDialog(true);
          setNoteToDialog(row);
        }}
      />
      <NoteDialog
        note={noteToDialog}
        open={showNoteDialog}
        refreshNotes={retrieveNotes}
        onClose={() => setShowNoteDialog(false)}
      />
    </Stack>
  );
};

export default NotizenPanel;
