import AutomatPanelRoutes from "page/Automatensuche/components/Automatenblatt/components/AutomatDetailPanelContainer/routes";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const UPDATE_VIEW_PANEL_INFO = "UPDATE_VIEW_PANEL_INFO";

const configureViewInfoStore = () => {
  const updateViewPanelInfo: AutomatAction = {
    identifier: UPDATE_VIEW_PANEL_INFO,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      const panelIndex = payload.viewPanelIndex;
      callback({
        viewInfo: {
          ...currentState.viewInfo,
          infoPanel: Object.values(AutomatPanelRoutes)[panelIndex],
          infoPanelIndex: panelIndex,
        },
      } as AutomatDataRecord);
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([updateViewPanelInfo]);
};

export default configureViewInfoStore;
