import { AuslastungVerfuegbarkeitAutomat } from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { TableRowProps } from "../../TableContainer/TableRow";

const MaterialDistributionController = {
  createTable(
    verfuegbarkeit: AuslastungVerfuegbarkeitAutomat | null
  ): Array<TableRowProps> {
    const elements: Array<TableRowProps> = [];
    if (verfuegbarkeit !== null) {
      elements[0] = {
        row: [
          {
            value: verfuegbarkeit.petRateAvg4Weeks,
            props: { align: "center" },
          },
          {
            value: verfuegbarkeit.metalRateAvg4Weeks,
            props: { align: "center" },
          },
          {
            value: verfuegbarkeit.glasRateAvg4Weeks,
            props: { align: "center" },
          },
          {
            value: verfuegbarkeit.otherRateAvg4Weeks,
            props: { align: "center" },
          },
        ],
      };
    }
    return elements;
  },
};

export default MaterialDistributionController;
