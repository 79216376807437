import { Button, Grid, Stack } from "@mui/material";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import DateUtils, { getDateWithShift } from "global/util/DateUtils";
import { useState } from "react";
import ExportService from "service/report-service/export/Export.service";

import "./positionen.scss";

const RechnungsdatenPositionen = () => {
  const shift = -(90 + getDateWithShift(-90).getDate() - 1);

  const [lieferVonDate, setLieferVonDate] = useState<Date>(
    getDateWithShift(shift)
  );
  const [lieferBisDate, setLieferBisDate] = useState<Date>(getDateWithShift(0));

  const handleExport = () => {
    const dateFrom = DateUtils.formatDateToAPIDateString(lieferVonDate);
    const dateTo = DateUtils.formatDateToAPIDateString(lieferBisDate);
    if (dateFrom && dateTo)
      ExportService.downloadSapPositions(dateFrom, dateTo, "", "", "", "", "");
  };

  return (
    <Grid container spacing={2} width={"auto"}>
      <Grid item xs={12} mt={5}>
        <Stack className="rowStack" columnGap={10}>
          <DatePickerElement
            label="Lieferdatum von:"
            defaultValue={lieferVonDate}
            getSelectedValue={(value) => {
              setLieferVonDate(value ?? getDateWithShift(shift));
            }}
            id="liefer-von-date"
            TypographyProps={{
              className: "lieferDateLabelStyle dialogTextStyle",
            }}
            TextFieldProps={{ className: "dialogTextStyle lieferDateInput" }}
          />
          <DatePickerElement
            label="Lieferdatum bis:"
            defaultValue={lieferBisDate}
            getSelectedValue={(value) => {
              setLieferBisDate(value ?? getDateWithShift(0));
            }}
            id="liefer-bis-date"
            TypographyProps={{
              className: "lieferDateLabelStyle dialogTextStyle",
            }}
            TextFieldProps={{ className: "dialogTextStyle lieferDateInput" }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} mt={5} mb={5}>
        <Button variant="contained" onClick={handleExport}>
          Export
        </Button>
      </Grid>
    </Grid>
  );
};

export default RechnungsdatenPositionen;
