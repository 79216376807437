import { Document, PDFViewer } from "@react-pdf/renderer";
import { DialogController } from "global/hook/modal/interface/ModalController";
import PdfSteckbrief from "global/pdf/PdfSteckbriefContent";
import Access from "service/data-service/access-controller/interface/Access";
import AutomatSteckbrief from "service/data-service/automate-controller/interface/AutomatSteckbrief";

const showAutomatSteckbrief: Function = (
  data: Array<AutomatSteckbrief>,
  dialogController: DialogController,
  access: Access
) => {
  const title = `Steckbrief - ${data[0].gln}`;
  dialogController.showDialog({
    message: "",
    title: title,
    maxWidth: "xl",
    onAbortClick: () => {},
    abortCaption: "OK",
    content: (
      <PDFViewer width={1024} height={968} showToolbar>
        <Document title={title}>
          {data.map((steckbrief) => (
            <PdfSteckbrief
              key={steckbrief.gln}
              steckbrief={steckbrief}
              access={access}
            />
          ))}
        </Document>
      </PDFViewer>
    ),
  });
};

export default showAutomatSteckbrief;
