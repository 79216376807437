import HttpService, { EpcomResponse } from "App/service/http.service";
import Config from "Config";
import SapDTO from "./dto/SapDTO";
import SapInputParam from "./interface/SapInputParam";
import { PositionDTO } from "./interface/SapOutputParam";

const basePath = "wgr/";

const WarengruppenService = {
  getUnterwarengruppen: (
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.sapServiceUrl}${basePath}getUnterwarengruppen`)
      .then((response: EpcomResponse) => {
        callbackOnSuccess(SapDTO.convertUnterWarengruppeItemsDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  getWarengruppen: (callbackOnSuccess: Function, callbackOnFail?: Function) => {
    HttpService.api
      .sendGet(`${Config.sapServiceUrl}${basePath}getWarengruppen`)
      .then((response: EpcomResponse) => {
        callbackOnSuccess(SapDTO.convertWarengruppeResponseDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  saveMatching: (
    currentUnterwarrengrup: number,
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(
        `${Config.sapServiceUrl}${basePath}saveMatching/${currentUnterwarrengrup}`,
        filter
      )
      .then((response: EpcomResponse) => {
        callbackOnSuccess(SapDTO.convertUnterWarengruppeResponseDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  getUnterwarengruppenByUwgrOfKurzText: (
    position: PositionDTO,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(
        `${Config.sapServiceUrl}${basePath}getUnterwarengruppenByUwgrOfKurzText`,
        {
          unterWarengruppeId: position.unterWarengruppeId,
        } as SapInputParam
      )
      .then((response: EpcomResponse) => {
        callbackOnSuccess(SapDTO.convertUnterWarengruppeItemsDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default WarengruppenService;
