import { Grid, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { FETCH_SAPRDVERTRAG_RECORDS } from "global/hook/datastore/automat/sap-rd-vertragsdaten-store";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useEffect, useState } from "react";
import VertragePanelColumnDefs from "./grid-column-defs";

interface VertragePanelProps {
  automatKey: string;
}
const VertragePanel = (props: VertragePanelProps) => {
  const [automatData, dispatchFetchCommand] = useAutomatData(props.automatKey);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const windowViewport = useWindowViewport(0, 287);

  useEffect(() => {
    if (!automatData.sapVertragOutput) {
      dispatchFetchCommand(
        FETCH_SAPRDVERTRAG_RECORDS,
        undefined,
        undefined,
        setIsFetchingData
      );
    } // eslint-disable-next-line
  }, [automatData.sapVertragOutput]);

  return (
    <Grid container spacing={5} width={"auto"}>
      <Grid item xs={12}>
        <Typography variant={"h4"}>Vertragsdaten ATH</Typography>
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          height={windowViewport.height}
          columnDefs={VertragePanelColumnDefs}
          rowsDataDef={{
            data: automatData.sapVertragOutput?.positionEdits ?? [],
            isFetchingData: isFetchingData,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default VertragePanel;
