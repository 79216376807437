import { ColDef } from "ag-grid-community";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

export const StatusPanelColumnDefs: Array<ColDef> = [
  {
    field: "datumString",
    headerName: "Datum",
    maxWidth: 120,
  },
  {
    field: "mengenMeldungCellNode",
    headerName: "Mengenmeldung",
    maxWidth: 160,
    cellRenderer: CellValueRenderer,
  },
  {
    field: "artikelstammCellNode",
    headerName: "Artikelstamm",
    maxWidth: 140,
    cellRenderer: CellValueRenderer,
  },
  {
    field: "quittierungCellNode",
    headerName: "Quittierung",
    maxWidth: 120,
    cellRenderer: CellValueRenderer,
  },

  {
    field: "logfileCellNode",
    headerName: "Logfile",
    maxWidth: 120,
    cellRenderer: CellValueRenderer,
  },
  {
    field: "aktstatusDPGCellValue",
    headerName: "DPG-BTB",
    maxWidth: 120,
    cellRenderer: CellValueRenderer,
  },
  {
    field: "aktstatusRNCellValue",
    headerName: "RN-BTB",
    cellRenderer: CellValueRenderer,
  },
];

export const StatusLogColumnDefs: Array<ColDef> = [
  {
    headerName: "Datum Uhrzeit",
    field: "datumEndeString",
    minWidth: 160,
  },
  {
    headerName: "Fehler-Nr.",
    field: "fehlerNummer",
    minWidth: 120,
  },
  {
    headerName: "Fehler-Text",
    field: "fehlerMeldung",
  },
  {
    headerName: "Dateiname",
    field: "fileName",
  },
  {
    headerName: "Abholgröße",
    field: "anzahlBytes",
    minWidth: 120,
  },
];
