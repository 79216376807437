import HttpService from "App/service/http.service";
import Config from "Config";
import AutomatStatusDTO from "./dto/AutomatStatusDTO";
import AutomatStatusLogDTO from "./dto/AutomatStatusLogDTO";
import AutomatStatus from "./interface/AutomatStatus";
import AxiosResponseToAutomatStatusDTOMapper from "./mapper/AxiosResponseToAutomatStatusDTOMapper";

export enum AutomatStatusTyps {
  AT_MM = "AT_MM",
  AT_LOG = "AT_LOG",
  AT_DPGBTB = "AT_DPGBTB",
  AT_EDKBTB = "AT_EDKBTB",
  CD_STD = "CD_STD",
  CD_MMQUIT = "CD_MMQUIT",
}

const AutomatStatusService = {
  retrieveAutomatenStatus: (
    automatkey: string,
    lastDays: number,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    HttpService.api
      .sendGet(
        Config.dataServiceUrl + "automate/status/" + automatkey + "/" + lastDays
      )
      .then((response) => {
        const ResponseDTO: AutomatStatus =
          AxiosResponseToAutomatStatusDTOMapper.map(response);
        callbackOnSuccess(ResponseDTO);
      })
      .catch((error) => {
        callbackOnFail(error);
      });
  },
  retrieveAutomatenStatusLog: (
    automatkey: string,
    day: string,
    type: AutomatStatusTyps,
    callbackOnSuccess: Function
  ) => {
    HttpService.api
      .sendGet(
        Config.dataServiceUrl +
          "automate/status/search/log/" +
          automatkey +
          "/" +
          day +
          "/" +
          type
      )
      .then((response) => {
        callbackOnSuccess(
          AutomatStatusLogDTO.convertResponseToAutomatStatusLogDTO(response)
        );
      })
      .catch((error) => {
        console.warn(error);
        // @TODO fire error message
      });
  },
  failedAutomatMM: (
    callbackOnSuccess: Function,
    automatkey?: string | null, // null for all
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(
        Config.dataServiceUrl + "automate/status/failedAutomat",
        automatkey
          ? {
              params: {
                automatKey: automatkey,
              },
            }
          : undefined
      )
      .then((response) => {
        callbackOnSuccess(
          AutomatStatusDTO.convertFailedAutomatResponse(response)
        );
      })
      .catch(() => {
        if (callbackOnFail) callbackOnFail();
      });
  },
  getNOKZuOK: (callbackOnSuccess: Function, callbackOnFail?: Function) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "automate/status/failedAutomatYesterday")
      .then((response) => {
        callbackOnSuccess(
          AutomatStatusDTO.convertFailedAutomatResponse(response)
        );
      })
      .catch(() => {
        if (callbackOnFail) callbackOnFail();
      });
  },
  getArchiveToManyFilesData: (
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "archive/getArchiveToManyFilesData")
      .then((response) => {
        callbackOnSuccess(response.data ?? []);
      })
      .catch(() => {
        if (callbackOnFail) callbackOnFail();
      });
  },
};

export default AutomatStatusService;
