import { Checkbox, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { ChangeEvent } from "react";


import "global/components/ui.scss";
import { LOGFILEFEHLER_ERLEDIGT_CHANGE } from "global/hook/datastore/automat/automat-logfilefehler-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { DispatchAutomatDataFunction } from "global/hook/datastore/use-automat-datastore";
import ModalController from "global/hook/modal/interface/ModalController";
import Access from "service/data-service/access-controller/interface/Access";
import Logfilefehler from "service/logfile-service/interface/Logfilefehler";
import LogfilefehlerService from "service/logfile-service/Logfilefehler.service";

const LogilefehlerPanelController = {
    transmuteToGridData(
        logData: Array<Logfilefehler>,
        updatingState: Array<boolean>,
        updatingStateSetter: (index: number, value: boolean) => void,
        dispatchAutomatDataAction: DispatchAutomatDataFunction,
        modalController: ModalController,
        access: Access,
    ): Array<Logfilefehler> {
        const onRecordChangeTrigger = updateErledigtTrigger.bind(
            null,
            updatingStateSetter,
            dispatchAutomatDataAction,
            modalController);

        let logElements: Array<Logfilefehler> = [];
        logData.filter((e: Logfilefehler) => !e.erledigt).forEach((element, index) => {

            const onRecordErledigtChange = onRecordChangeTrigger.bind(null, element, index);

            const record = {
                ...element,
                erledigtNode: this.buildCheckboxNode(
                    element,
                    updatingState[index],
                    onRecordErledigtChange,
                    access
                ),
            };
            logElements.push(record);
        });
        return logElements;
    },
    buildCheckboxNode(
        record: Logfilefehler,
        isUpdating: boolean,
        erledigtChangeHandler: (value: boolean) => void,
        access: Access,
    ): React.ReactNode {
        let disabled = access.rollenTyp === 'ATH' || access.rollenTyp === 'CLD'

        const hasErledigtValue = typeof record.erledigt === 'boolean';

        const checkboxChangeHandler = (_: ChangeEvent, value: boolean) => {
            erledigtChangeHandler(value);
        };

        return <Box className="box-with-centered-content">
            {hasErledigtValue && <Checkbox
                checked={record.erledigt === true}
                disabled={disabled || isUpdating}
                color="success"
                onChange={checkboxChangeHandler}
            />}
            {hasErledigtValue && isUpdating && (
                <CircularProgress className="checkboxActionProgress" size={16} />
            )}
        </Box>
    }
}

export default LogilefehlerPanelController;

function updateErledigtTrigger(
    updatingStateSetter: (index: number, value: boolean) => void,
    dispatchAutomatDataAction: DispatchAutomatDataFunction,
    modalController: ModalController,
    record: Logfilefehler,
    recordIndex: number,
    newErledigtValue: boolean,
) {
    updatingStateSetter(recordIndex, true);
    dispatchAutomatDataAction(LOGFILEFEHLER_ERLEDIGT_CHANGE, { logfilefehler: record } as AutomatActionPayload)

    LogfilefehlerService.update(
        {
            ...record,
            erledigt: newErledigtValue
        },
        (newRecord: Logfilefehler) => {
            updatingStateSetter(recordIndex, false);
            dispatchAutomatDataAction(LOGFILEFEHLER_ERLEDIGT_CHANGE, { logfilefehler: newRecord } as AutomatActionPayload)
        },
        (error: Error) => {
            updatingStateSetter(recordIndex, false);
            dispatchAutomatDataAction(LOGFILEFEHLER_ERLEDIGT_CHANGE, { logfilefehler: record } as AutomatActionPayload)
        },
        modalController,
    );
};
