import { ColDef } from "ag-grid-community";

export const DPGStammdatenPanelColumnDefs: Array<ColDef> = [
  {
    headerName: "Schlüssel-ID",
    field: "schluesselId",
    minWidth: 200,
  },
  {
    headerName: "Gültig von",
    field: "gueltigVon",
    minWidth: 90,
    maxWidth: 110,
  },
  {
    headerName: "Gültig bis",
    field: "gueltigBis",
    minWidth: 90,
    maxWidth: 110,
  },
  {
    headerName: "RN-GLN",
    field: "edkOrgGln",
    minWidth: 120,
  },
  {
    headerName: "Rücknehmer",
    field: "edkOrgName",
    minWidth: 160,
  },
  {
    headerName: "Nutzungsart",
    field: "nutzungsartBezeichnung",
    minWidth: 160,
  },
  {
    headerName: "Zählstelle",
    field: "zaehlstelleBezeichnung",
    minWidth: 110,
  },
  {
    headerName: "Sperrkennzeichen",
    field: "sperrkennzeichenBezeichnung",
    minWidth: 170,
  },
  {
    headerName: "Letzte Änderung",
    field: "letztesAenderungsdatum",
    minWidth: 150,
  },
];
