import { Button, Grid, Stack } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import TextValue from "global/components/UI/TextValue/TextValue";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { encryptStringToBase64String } from "global/util/encrypt";
import { useEffect, useState } from "react";
import NoteService from "service/data-service/notizen-controller/Note.service";
import NoteSearchResultDTO, {
  Note,
} from "service/data-service/notizen-controller/interface/NoteSearchResult";
import ExportService, {
  NotizenSuchoption,
} from "service/report-service/export/Export.service";
import NotizenSucheColumnDefs from "./grid-column-defs";

import { useTabs } from "global/hook/tabs/use-tabs";
import "./notizensuche.scss";

const NotizenSuche: React.FC = () => {
  const [, , tabsController] = useTabs();

  const [noteText, setNoteText] = useState<string>();
  const [notesSearchFilter, setNotesSearchFilter] = useState<string>("");
  const [records, setRecords] = useState<Array<Note>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const windowViewport = useWindowViewport(0, 234);

  const changeNotesSearchFilter = (noteStatus?: string) => {
    const filter = {
      notizText: noteText ?? "",
      notizStatus: noteStatus ?? "",
    };
    const filterValue = JSON.stringify(filter);
    setNotesSearchFilter(encryptStringToBase64String(filterValue));
  };

  useEffect(() => {
    if (notesSearchFilter === "") return;
    setIsLoading(true);
    NoteService.noteSearch(
      NotizenSuchoption.notizSuchmaske,
      notesSearchFilter,
      (response: NoteSearchResultDTO) => {
        setIsLoading(false);
        setRecords(response.rows);
      },
      (error: Error) => {
        setIsLoading(false);
      }
    );
  }, [notesSearchFilter]);

  const exportNotes = () => {
    ExportService.downloadNotizen(
      NotizenSuchoption.notizSuchmaske,
      notesSearchFilter
    );
  };

  return (
    <Grid container spacing={2} width={"auto"} mt={2}>
      <Grid item xs={12} lg={4}>
        <Stack direction="row" spacing={1}>
          <TextValue
            label="Notiztext:"
            id="notiz-suche-text"
            value={noteText ?? ""}
            onChange={(event) => setNoteText(event.target.value)}
            TypographyProps={{ className: "notizenSearch__Label" }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
          mt={0.5}
        >
          <Button
            size="small"
            variant="contained"
            className="notizenSearch__Button"
            onClick={() => {
              changeNotesSearchFilter();
            }}
          >
            alle Notizen
          </Button>
          <Button
            size="small"
            variant="contained"
            className="notizenSearch__Button"
            onClick={() => {
              changeNotesSearchFilter("OFFEN,INARBEIT");
            }}
          >
            offene Notizen
          </Button>
          <Button
            size="small"
            variant="contained"
            className="notizenSearch__Button"
            onClick={() => {
              changeNotesSearchFilter("ERLEDIGT");
            }}
          >
            geschlossene Notizen
          </Button>
          <Button
            size="small"
            variant="contained"
            className="notizenSearch__Button"
            onClick={() => {
              changeNotesSearchFilter("NICHT_RELEVANT");
            }}
          >
            nicht relevante Notizen
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          height={windowViewport.height}
          columnDefs={NotizenSucheColumnDefs}
          onRowClicked={(note: Note) => {
            tabsController.open(note.serienNr, note.automatKey);
          }}
          exportDef={{
            disabled: isLoading || notesSearchFilter.length === 0,
            action: exportNotes,
          }}
          rowsDataDef={{
            data: records,
            isFetchingData: isLoading,
          }}
        />
      </Grid>
    </Grid>
  );
};
export default NotizenSuche;
