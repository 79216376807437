import { Stack, Tab, Tabs, useTheme } from "@mui/material";
import { UPDATE_VIEW_PANEL_INFO } from "global/hook/datastore/automat/view-info-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import useModalController from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";

import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AbrufPanel from "../panel/Abruf/AbrufPanel";
import ArtikelsuchePanel from "../panel/ArtikelSuche/ArtikelsuchePanel";
import ClearingfehlerPanel from "../panel/Clearingfehler/ClearingfehlerPanel";
import DPGStammdatenPanel from "../panel/DPGStammdaten/DPGStammdatenPanel";
import DetailsPanel from "../panel/Details/DetailsPanelContainer";
import HistoriePanel from "../panel/Historie/HistoriePanel";
import LogfilePanel from "../panel/Logfile/LogfilePanel";
import LogfilefehlerPanel from "../panel/Logfilefehler/LogfilefehlerPanel";
import NotizenPanel from "../panel/Notizen/NotizenPanel";
import RNSPanel from "../panel/RNS/RNSPanel";
import StatusPanel from "../panel/Status/StatusPanel";
import TKPartnerPanel from "../panel/TKPartner/TKPartnerPanel";
import TkPartnerController from "../panel/TKPartner/script/TKPartnerController";
import VertragePanel from "../panel/Vertrag/VertragePanel";
import AutomatDetailPanelController from "./AutomatDetailPanelController";
import AutomatPanelRoutes from "./routes";

import "./tabs.scss";

export interface PanelBaseProps {
  resetValues: boolean;
}

interface AutomatDetailPanelContainerProps extends PanelBaseProps {
  automatKey: string;
  onSave: Function;
}

const AutomatDetailPanelContainer = (
  props: AutomatDetailPanelContainerProps
) => {
  const { resetValues } = props;
  const modal = useModalController();
  const [automatData, dispatch] = useAutomatData(props.automatKey);
  const [selectedTab, setSelectedTab] = useState<number>(
    automatData.viewInfo.infoPanelIndex
  );
  const [markTkPartnerWithColor, setMarkTkPartnerWithColor] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  function navigateToTab(tabIndex: number) {
    setSelectedTab(tabIndex);
    navigate(Object.values(AutomatPanelRoutes)[tabIndex]);
    dispatch(UPDATE_VIEW_PANEL_INFO, {
      viewPanelIndex: tabIndex,
    } as AutomatActionPayload);
  }

  /**
   *
   * @returns true if allowed to navigate
   * @returns false if user wants to stay on page
   */
  function openDialogOnUnsavedChanges(): Promise<boolean> {
    if ((automatData.changes ?? []).length > 0) {
      return AutomatDetailPanelController.TabChangeHandler(modal, props.onSave);
    } else {
      return Promise.resolve(true);
    }
  }

  useEffect(() => {
    setSelectedTab(automatData.viewInfo.infoPanelIndex);
  }, [automatData.viewInfo.infoPanelIndex]);

  useEffect(() => {
    if (automatData.automatenblattInformation.seriennummer !== 0) {
      TkPartnerController.isTkPartnerIncomplete(
        automatData.automatenblattInformation,
        setMarkTkPartnerWithColor
      );
    }
  }, [automatData]);

  const rnsPanel = (
    <RNSPanel automatKey={props.automatKey} resetValues={resetValues} />
  );
  const detailsPanel = (
    <DetailsPanel automatKey={props.automatKey} resetValues={resetValues} />
  );
  const tkPartnerPanel = (
    <TKPartnerPanel
      automatKey={props.automatKey}
      resetValues={resetValues}
      onOneElementMarked={(marked) => {
        setMarkTkPartnerWithColor(marked);
      }}
    />
  );
  const statusPanel = <StatusPanel automatKey={props.automatKey} />;

  const abrufPanel = <AbrufPanel automatKey={props.automatKey} />;
  const dpgStammdatenPanel = (
    <DPGStammdatenPanel automatKey={props.automatKey} />
  );
  const clearingfehlerPanel = (
    <ClearingfehlerPanel automatKey={props.automatKey} />
  );
  const notizenPanel = (
    <NotizenPanel
      automatKey={props.automatKey}
      disabled={automatData.automatenblattInformation.readonly}
    />
  );
  const vertragePanel = <VertragePanel automatKey={props.automatKey} />;

  const artikelSuchePanel = <ArtikelsuchePanel automatKey={props.automatKey} />;
  const logfilePanel = <LogfilePanel automatKey={props.automatKey} />;
  const logfehlerPanel = <LogfilefehlerPanel automatKey={props.automatKey} />;
  const historiePanel = <HistoriePanel automatKey={props.automatKey} />;

  const windowViewport = useWindowViewport(436);

  return (
    <Stack>
      <Tabs
        variant="scrollable"
        value={selectedTab}
        onChange={(event, tabIndex) =>
          openDialogOnUnsavedChanges().then((shouldNavigate) => {
            if (shouldNavigate) navigateToTab(tabIndex);
          })
        }
        scrollButtons="auto"
        className="automatenblatt-tabs"
        sx={{
          backgroundColor: "secondary.main",
          width: windowViewport.width,
        }}
      >
        <Tab label="RNS" />
        <Tab label="Details" />
        <Tab label="Status" />
        <Tab
          label={
            <p
              style={{
                color: markTkPartnerWithColor
                  ? theme.palette.error.main
                  : undefined,
              }}
            >
              {markTkPartnerWithColor ? "*TK Partner*" : "TK Partner"}
            </p>
          }
        />
        <Tab label="Abruf" />
        <Tab label="DPG-Stammdaten" />
        <Tab label="Clearingfehler" />
        <Tab label="Notizen" />
        <Tab label="Verträge" />
        <Tab label="Artikelsuche" />
        <Tab label="Logfile" />
        <Tab label="Logfilefehler" />
        <Tab label="Historie" />
      </Tabs>
      <Routes>
        <Route path={AutomatPanelRoutes.RNS_PATH} element={rnsPanel} />
        <Route path={AutomatPanelRoutes.DETAILS_PATH} element={detailsPanel} />
        <Route path={AutomatPanelRoutes.STATUS_PATH} element={statusPanel} />
        <Route
          path={AutomatPanelRoutes.TK_PARTNER_PATH}
          element={tkPartnerPanel}
        />
        <Route path={AutomatPanelRoutes.ABRUF_PATH} element={abrufPanel} />
        <Route
          path={AutomatPanelRoutes.DPG_STAMMDATEN_PATH}
          element={dpgStammdatenPanel}
        />
        <Route
          path={AutomatPanelRoutes.CLEARINGFEHLER_PATH}
          element={clearingfehlerPanel}
        />
        <Route path={AutomatPanelRoutes.NOTIZEN_PATH} element={notizenPanel} />
        <Route
          path={AutomatPanelRoutes.VERTRAGE_PATH}
          element={vertragePanel}
        />
        <Route
          path={AutomatPanelRoutes.ARTIKELSUCHE_PATH}
          element={artikelSuchePanel}
        />
        <Route path={AutomatPanelRoutes.LOGFILE_PATH} element={logfilePanel} />
        <Route
          path={AutomatPanelRoutes.LOGFILEFEHLER_PATH}
          element={logfehlerPanel}
        />
        <Route
          path={AutomatPanelRoutes.HISTORIE_PATH}
          element={historiePanel}
        />
      </Routes>
    </Stack>
  );
};

export default AutomatDetailPanelContainer;
