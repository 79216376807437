import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import UnterWarengruppe from "service/sap-service/interface/UnterWarengruppe";
import Warengruppe from "service/sap-service/interface/Warengruppe";
import WarengruppenService from "service/sap-service/Warengruppen.service";
import {
  EnumeratorItemSourceType,
  EnumItemBuilder,
  EnumItemsExtractFn,
} from "../use-enumerator";

const warengruppeBuilder: EnumItemBuilder = (raw: EnumeratorItemSourceType) => {
  const rawItem = raw as Warengruppe;
  return {
    id: rawItem.key,
    value: rawItem.wgrName,
    source: raw,
  } as EnumeratorItem;
};

export const onWarengruppeResult: EnumItemsExtractFn = (
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  WarengruppenService.getWarengruppen(
    (groups: Array<Warengruppe>) =>
      onEnumSyncSuccess(
        groups.filter((e) => e.wgrNr < 770807),
        warengruppeBuilder
      ), // hardwire the wgrNr here...there is nothing else I can use to filter out
    (err: Error) =>
      onEnumSyncError("Syncing Rechnungdaten Warrengruppen failed", err)
  );
};

const unterWarengruppeBuilder: EnumItemBuilder = (
  raw: EnumeratorItemSourceType
) => {
  const rawItem = raw as UnterWarengruppe;
  return {
    id: rawItem.key,
    value: rawItem.name,
    source: raw,
  } as EnumeratorItem;
};

export const onUnterWarengruppeResult: EnumItemsExtractFn = (
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  WarengruppenService.getUnterwarengruppen(
    (groups: Array<UnterWarengruppe>) =>
      onEnumSyncSuccess(groups, unterWarengruppeBuilder),
    (err: Error) =>
      onEnumSyncError("Syncing Rechnungdaten Unterwarrengruppen failed", err)
  );
};
