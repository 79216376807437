import { Card, CardHeader, List } from "@mui/material";
import { useCockpitInfo } from "global/hook/cockpit/use-cockpit-info";
import { HREF_NOTIZEN_WIEDERVORLAGE } from "global/util/routes";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";
import { ListItemTypography } from "../ListItemTypography/ListItemTypography";

const Notizen: React.FC = () => {
  const cockpitInfo = useCockpitInfo()[0];
  // Variablen und Hooks
  const [countOfNotes, setCountOfNotes] = useState(Number);
  const [notesVisible, setNotesVisible] = useState(Boolean);

  let navigate = useNavigate();

  useEffect(() => {
    setCountOfNotes(
      cockpitInfo.cockpitInformationDTO?.notizenWiedervorlage ?? 0
    );
    setNotesVisible(
      cockpitInfo.visibilities?.notizenWiedervorlageShow !== undefined
        ? cockpitInfo.visibilities?.notizenWiedervorlageShow
        : false
    );
  }, [cockpitInfo]);

  // React-Tree
  return (
    <Card hidden={!notesVisible}>
      <CardHeader title="Notizen" />
      <List disablePadding>
        <CockpitListItemButton
          divider
          aria-label="Notizen"
          onClick={() => navigate(HREF_NOTIZEN_WIEDERVORLAGE)}
        >
          <CockpitListItemText primary="Notizen Wiedervorlage: " />
          <ListItemTypography text={`${countOfNotes}`} id="countOfNotes" />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Notizen;
