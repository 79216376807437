import { Button, Stack } from "@mui/material";
import { PositionDTO } from "service/sap-service/interface/SapOutputParam";

const RegionListController = {
    convertPositionsData(positions: Array<PositionDTO>, onRegionSelected: Function): Array<PositionDTO> {
        let records: Array<PositionDTO> = [];
        positions.forEach((position: PositionDTO) => {
            records.push({
                ...position,
                regionBtn: this.convertToButton(position.region, () => {
                    onRegionSelected(position.region)
                })
            } as PositionDTO)
        });
        return records;
    },
    convertToButton(
        caption: string | null,
        action: Function,
    ): React.ReactNode {
        if (!caption) {
            return null;
        }
        return (
            <Stack direction="row" justifyContent="center" width={"100%"} >
                <Button
                    className="fillAvailable dialogButton plzSuche__gridCellButtonWidth"
                    variant="contained"
                    onClick={() => {
                        action()
                    }}>

                    {caption}
                </Button>
            </Stack>
        );
    }
};

export default RegionListController;
