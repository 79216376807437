import HttpService, { EpcomResponse } from "App/service/http.service";
import Config from "Config";
import { getFilenameOutOfContentDisposition } from "global/util/REST/responseUtils";
import { decodeBase64 } from "global/util/files/fileToBase64";
import NoteRequestDTO from "./interface/NoteRequest";
import NoteSearchResultDTO from "./interface/NoteSearchResult";
import NoteDTO from "./mapping/NoteMapper";

const NoteService = {
  noteSaveOrUpdate: (notiz: NoteRequestDTO, callbackOnSuccess: Function) => {
    HttpService.api
      .sendJsonDataPost(Config.dataServiceUrl + "notizen/save", notiz)
      .then((response) => {
        callbackOnSuccess(NoteDTO.mapResponseToNoteDTO(response));
      })
      .catch((error) => {
        console.warn(error);
        // @TODO fire error message
      });
  },
  noteSearch: (
    // in theory we need 'automatKey'
    option:
      | "automatKey"
      | "notizkopfKey"
      | "notizZeileKey"
      | "wiedervorlage"
      | "notizAlle"
      | "notizSuchmaske",
    value: string,
    callbackOnSuccess: (result: NoteSearchResultDTO) => void,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(Config.dataServiceUrl + "notizen/search/" + option + "/" + value)
      .then((response: EpcomResponse) => {
        callbackOnSuccess(NoteDTO.mapResponseToNoteSearchResultDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
        // @TODO fire error message
      });
  },
  downloadNote: (
    notizTyp: string,
    automatKey: string,
    datumErfasst: string,
    dateiKey: string
  ) => {
    HttpService.api
      .sendGet(
        `${Config.dataServiceUrl}notizen/download/${notizTyp}/${automatKey}/${datumErfasst}/${dateiKey}`,
        {
          responseType: "blob", //im backend steht hier application/octet-stream
        }
      )
      .then((response: EpcomResponse) => {
        const link = document.createElement("a");
        let filename = decodeBase64(
          getFilenameOutOfContentDisposition(
            response.headers["content-disposition"]
          )
        );
        link.href = URL.createObjectURL(response.data);
        link.setAttribute("download", filename);
        link.hidden = true;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.warn(error);
        // @TODO fire error message
      });
  },
};
export default NoteService;
