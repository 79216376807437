import Config from "Config";
import Keycloak, {KeycloakTokenParsed} from "keycloak-js";

let disableKeycloak = false; // DEBUG manuell setzen, falls Keycloak mal nicht funktioniert

const keycloakConfig = {
  url: Config.keycloakUrl,
  realm: Config.keycloakRealm,
  clientId: Config.keycloakClientId,
};

// the keycloak instance object
const keycloak: Keycloak.KeycloakInstance = Keycloak(keycloakConfig);

export const KeyCloakService = {
  /**
   * Initializes keycloak.
   * It will check if the current keycloak object is authenticated and otherwise it will
   * redirect you to the given keycloak login page to execute a login.
   * After successful login a tokenRefresh execution is scheduled, when the initial accessToken expires
   * @param callback a function which is called after a successful login
   */
  initKeycloak(callback: () => void) {
    if (!keycloak.authenticated && !disableKeycloak) {
      keycloak
        .init({onLoad: "login-required"}).then((authenticated) => {
        if (authenticated) {
          console.log("Authentication succeeded");
          // Execute a tokenRefresh when the expiration date has been reached
          let expiresIn: number = this.calculateTimeToTokenExpiration(keycloak.tokenParsed!);
          console.log("Token expires in %s ms", expiresIn);
          this.tokenRefresh();
        } else {
          console.log("Authentication not successful")
        }
      })
        .catch(function () {
          alert("failed to authenticate by keycloak");
        })
        .finally(() => {
          callback();
        });
    } else {
      callback();
    }
  },

  /**
   * Calculates the remaining lifetime of the Token in milliseconds
   * @returns time in milliseconds when the token turns invalid
   */
  calculateTimeToTokenExpiration(tokenParsed: KeycloakTokenParsed): number {
    return tokenParsed.exp! * 1000 - new Date().getTime();
  },

  tokenRefresh() {
    setInterval(() => {
      keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          console.info('Token refreshed ' + refreshed); // remove this log, when the refresh works as expected. it's just for debug reasons right now
        }
      }).catch(() => {
        console.error('Failed to refresh token');
        alert("Der Token konnte nicht aktualisiert werden. Bitte laden Sie die Seite neu.");
      });
    }, 10000);
  },

  updateToken() {
    return keycloak.updateToken(30);
  },

  doLogin() {
    keycloak.login();
  },

  doLogout() {
    keycloak.logout();
    sessionStorage.clear();
  },

  getToken(): string | undefined {
    return keycloak.token;
  },

  isLoggedIn(): boolean {
    return !!keycloak.token;
  },

  getUsername(): string {
    return keycloak.tokenParsed?.preferred_username;
  },

  getFullname(): string {
    return (
      keycloak.tokenParsed?.given_name + " " + keycloak.tokenParsed?.family_name
    );
  },
};
