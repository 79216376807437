import { Button, Grid, Stack, Typography } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useContext, useEffect, useState } from "react";
import FlatFileService from "service/data-service/reporting-controller/FlatFile.service";
import ExportService from "service/report-service/export/Export.service";

import "./berichteflatfile.scss";

const BerichteFlatfile = () => {
  const automatStatusEnum: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID
  );

  const [seriennummer, setSeriennummer] = useState<string>("");
  const [glnDerRns, setGlnDerRns] = useState<string>("");
  const [status, setStatus] = useState<Array<EnumeratorItem>>([]);
  const [isSWUser, setIsSWUser] = useState(false);
  const accessContext = useContext(AccessContext);
  const [reset, setReset] = useState(false);

  const resetFilter = () => {
    setSeriennummer("");
    setGlnDerRns("");
    setStatus([]);
    setReset((state) => !state);
  };

  useEffect(() => {
    setIsSWUser(accessContext.getAccessContainer().auspraegung === "SW");
  }, [accessContext]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={3}>
        <Typography variant={"h1"}>Flatfile</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"flatfile-seriennummer"}
          label="Seriennummer:"
          value={seriennummer ?? ""}
          onChange={(event) => setSeriennummer(event.target.value ?? "")}
          TypographyProps={{
            className: "berichteflatfile__LabelStyle",
          }}
          TextFieldProps={{ className: "berichteflatfile__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"flatfile-glnderrns"}
          label="GLN der RNS:"
          value={glnDerRns ?? ""}
          onChange={(event) => setGlnDerRns(event.target.value ?? "")}
          TypographyProps={{
            className: "berichteflatfile__LabelStyle",
          }}
          TextFieldProps={{ className: "berichteflatfile__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <MultipleSelectCheckbox
          label={"Status:"}
          limitTags={4}
          options={automatStatusEnum.items}
          id="flatfile-status"
          onChange={(values) => setStatus(values)}
          resetSelectedValues={reset}
          TypographyProps={{
            className: "berichteflatfile__LabelStyle",
          }}
          AutocompleteProps={{ className: "berichteflatfile__InputStyle" }}
          StackProps={{ columnGap: 2 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Button size="small" variant="secondary" onClick={resetFilter}>
            Felder zurücksetzen
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              ExportService.downloadFlatFile(
                glnDerRns,
                seriennummer,
                status?.map((e) => e.id).join(",") ?? ""
              );
            }}
          >
            Excel Export
          </Button>
          {isSWUser && (
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                FlatFileService.exportToJson(
                  glnDerRns,
                  seriennummer,
                  status?.map((e) => e.id).join(",") ?? ""
                );
              }}
            >
              JSON Export
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
export default BerichteFlatfile;
