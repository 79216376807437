import { View } from "@react-pdf/renderer";
import { SteckbriefProps } from "./PdfSteckbriefContent";
import PdfRowItemHeader from "./component/PdfRowItemHeader";
import PdfRowTextItem from "./component/PdfRowTextItem";

const PdfRemarksItem = (props: SteckbriefProps) => {
  const data = props.steckbrief.bemerkungen;
  return (
    <View>
      <PdfRowItemHeader mainInfo="Bemerkungen"></PdfRowItemHeader>
      {data.map((info, index) => (
        <PdfRowTextItem
          itemKey={`${props.steckbrief.serialNumber} - ${index}`}
          header={info.key}
          info={info.value}
        ></PdfRowTextItem>
      ))}
    </View>
  );
};

export default PdfRemarksItem;
