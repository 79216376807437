import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import RnsService from "service/data-service/rns-controller/Rns.service";
import {
  EnumeratorItemSourceType,
  EnumItemBuilder,
  EnumItemsExtractFn,
} from "../use-enumerator";

const rnsGlnItemBuilder: EnumItemBuilder = (raw: EnumeratorItemSourceType) => {
  const rawItem = raw as string;
  return {
    id: rawItem,
    value: rawItem,
    source: raw,
  } as EnumeratorItem;
};

const RNS_GLN_LIST_LIMIT = 50;

export const onRnsGlnListResult: EnumItemsExtractFn = (
  filter: string,
  previousFilter: string,
  currentItems: EnumeratorItem[],
  onEnumSyncSuccess: Function,
  onEnumSyncError: Function
) => {
  if (filter.length > 0) {
    if (
      currentItems.length > 0 &&
      currentItems.length < RNS_GLN_LIST_LIMIT &&
      previousFilter.length > 0 &&
      previousFilter.length < filter.length &&
      filter.startsWith(previousFilter)
    ) {
      onEnumSyncSuccess(
        currentItems
          .map((item) => item.value)
          .filter((value) => value.startsWith(filter)),
        rnsGlnItemBuilder
      );
    } else {
      RnsService.rnsMatches(
        filter,
        (items: Array<string>) => onEnumSyncSuccess(items, rnsGlnItemBuilder),
        (err: Error) =>
          onEnumSyncError("Retrieving RNS GLN matches failed", err),
        RNS_GLN_LIST_LIMIT
      );
    }
  } else {
    onEnumSyncSuccess([], rnsGlnItemBuilder);
  }
};
