import { Card } from "@mui/material";
import {
  HREF_ADMIN_ANLAGENEUEUSER,
  HREF_ADMIN_ANLAGERECHNUNGSNEMER,
  HREF_ADMIN_ANWENDERNACHRICHTEN,
  HREF_ADMIN_IPBEREICH,
  HREF_ADMIN_IPWHITELIST,
  HREF_ADMIN_JOB_MONITORING,
  HREF_ADMIN_MAINTENANCE,
  HREF_AUTOMATENKAUFANFRAGEN,
  HREF_AUTOMATENSUCHE,
  HREF_BERICHTE_ANSICHTFEHLER,
  HREF_BERICHTE_ANSICHTWECHSEL,
  HREF_BERICHTE_AUTOMATDATEN_GT_30,
  HREF_BERICHTE_DSMD_FEHLERHAFT,
  HREF_BERICHTE_ERREICHBARKEITEN,
  HREF_BERICHTE_FLATFILE,
  HREF_BERICHTE_VERFUGBARKEISANALYSE,
  HREF_BERICHTE_ZURBEARBEITUNG,
  HREF_DATENPFLEGE_RNS,
  HREF_HILFE_BENUTZERHANDBUCH,
  HREF_HOME,
  HREF_NOTIZEN_SUCHE,
  HREF_NOTIZEN_WIEDERVORLAGE,
  HREF_RECHNUNGSDATENATH_POSITIONEN,
  HREF_RECHNUNGSDATENATH_REGION_ZUORDNEN,
  HREF_RECHNUNGSDATENATH_WGR_ZUORDNUNG,
  HREF_RECHNUNGSDATEN_GLNS_OHNE_ZUORDNUNG,
  HREF_RECHNUNGSDATEN_SUCHE,
} from "global/util/routes";
import Automatensuche from "page/Automatensuche/Automatensuche";
import Anwendernachrichten from "page/administration/anwendernachrichten/Anwendernachrichten";
import IpRange from "page/administration/ip-range/IpRange";
import Ansichtfehler from "page/berichte/ansichtfehler/Ansichtfehler";
import ZurBearbeitung from "page/berichte/bearbeitung/ZurBearbeitung";
import DsmdFehler from "page/berichte/dsmd/Dsmd";
import BerichteFlatfile from "page/berichte/flatfile/BerichteFlatfile";
import KommArt from "page/berichte/kommArt/KommArt";
import NotZuOk from "page/berichte/notZuOk/NotZuOk";
import Cockpit from "page/cockpit/Cockpit";
import DatenpflegeRns from "page/datenpflegerns/DatenpflegeRns";
import Kaufanfragen from "page/kaufanfragen/Kaufanfragen";
import NotizenSuche from "page/notizen/suche/NotizenSuche";
import NotizenWiedervorlage from "page/notizen/wiedervorlage/NotizenWiedervorlage";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AccessContext } from "../Access-Control/AccessProvider";

import HttpService from "App/service/http.service";
import { useMaintenanceInfo } from "global/hook/maintenance/use-maintenance";
import { isFalsyOrEmptyString } from "global/util/utils";
import AnlageNeuerUser from "page/administration/anlage-neuer-user/AnlageNeuerUser";
import AnlageRechnungsnehmer from "page/administration/anlage-rechnungsnehmer/AnlageRechnungsnehmer";
import IpWhitelistPage from "page/administration/ip-whitelist/IpWhitelist";
import JobPage from "page/administration/job-monitoring/components/JobPage";
import MaintenanceGUI from "page/administration/maintenance/Maintenance";
import AuslastungVerfuegbarkeit from "page/berichte/auslastungverfuegbarkeit/AuslastungVerfuegbarkeit";
import AutomatenDatenGt30 from "page/berichte/automatendaten-gt30/AutomatendatenGt30";
import EpcomUserManual from "page/help/EpcomUserManual";
import GLNOhneZuordnung from "page/rechnungATH/glnOhneZuordnung/GLNOhneZuordnung";
import RechnungsdatenPositionen from "page/rechnungATH/positionen/RechnungsdatenPositionen";
import RegionenZuordnen from "page/rechnungATH/regionenZuordnen/RegionenZuordnen";
import RechnungsdatenSuchePositionen from "page/rechnungATH/suchePositionen/SuchePositionen";
import WGRZuordnung from "page/rechnungATH/wgrZuordnung/WGRZuordnung";
import "../../App.scss";
import AccessController from "../Access-Control/AccessController";

const AppContent = () => {
  const accessContext = useContext(AccessContext);
  const access = accessContext.getAccessContainer();

  const maintenanceController = useMaintenanceInfo()[1];

  useEffect(() => {
    HttpService.registerMaintenanceController(maintenanceController);
    AccessController.getMaintenanceInfo(maintenanceController); // eslint-disable-next-line
  }, []);

  return (
    <>
      {isFalsyOrEmptyString(accessContext.getAccessContainer().userName) && (
        <Card className="content">
          <Routes>
            <Route path={HREF_HOME} element={<Cockpit />} />
          </Routes>
        </Card>
      )}
      ,
      {!isFalsyOrEmptyString(accessContext.getAccessContainer().userName) && (
        <Card className="content">
          <Routes>
            <Route path={HREF_HOME} element={<Cockpit />} />
            <Route
              path={HREF_AUTOMATENSUCHE + "/*"}
              element={
                access.showEntryAutomatensuche ? (
                  <Automatensuche />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_AUTOMATENKAUFANFRAGEN + "/*"}
              element={
                access.showEntryKaufanfragen ? (
                  <Kaufanfragen />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_DATENPFLEGE_RNS}
              element={
                access.showEntryRns ? (
                  <DatenpflegeRns />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_ADMIN_JOB_MONITORING}
              element={
                access.showMenuAdministration ? (
                  <JobPage />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_ANSICHTFEHLER}
              element={
                access.showEntryAnsichtFehlerhafteMm ? (
                  <Ansichtfehler />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_ANSICHTWECHSEL}
              element={
                access.showEntryAnsichtWechselNokAufOk ? (
                  <NotZuOk />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_ERREICHBARKEITEN}
              element={
                access.showEntryErreichbarkeitenJeKommart ? (
                  <KommArt />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_ZURBEARBEITUNG}
              element={
                access.showEntryZurBearbeitungAnstehend ? (
                  <ZurBearbeitung />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_FLATFILE}
              element={
                access.showEntryFlatfile ? (
                  <BerichteFlatfile />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_DSMD_FEHLERHAFT}
              element={
                access.showEntryDsmdFehlerhaft ? (
                  <DsmdFehler />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_VERFUGBARKEISANALYSE}
              element={
                access.showEntryVerfuegbarkeit ? (
                  <AuslastungVerfuegbarkeit />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_BERICHTE_AUTOMATDATEN_GT_30}
              element={
                access.showMenuAdministration ? (
                  <AutomatenDatenGt30 />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_NOTIZEN_SUCHE}
              element={
                access.showEntryNotizenSuche ? (
                  <NotizenSuche />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_NOTIZEN_WIEDERVORLAGE}
              element={
                access.showEntryWiedervorlage ? (
                  <NotizenWiedervorlage />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_HILFE_BENUTZERHANDBUCH}
              element={
                access.showEntryBenutzerhandbuch ? (
                  <EpcomUserManual />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_ADMIN_ANWENDERNACHRICHTEN}
              element={<Anwendernachrichten />}
            />
            <Route
              path={HREF_ADMIN_ANLAGENEUEUSER}
              element={
                access.showEntryAnlageNeuerUser ? (
                  <AnlageNeuerUser />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_ADMIN_ANLAGERECHNUNGSNEMER}
              element={
                access.showEntryAnlageRechnungsempfaenger ? (
                  <AnlageRechnungsnehmer />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_ADMIN_IPBEREICH}
              element={
                access.showEntryIpBereiche ? (
                  <IpRange />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_ADMIN_IPWHITELIST}
              element={
                access.showEntryIpWhiteList ? (
                  <IpWhitelistPage />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route path={HREF_ADMIN_MAINTENANCE} element={<MaintenanceGUI />} />
            <Route
              path={HREF_RECHNUNGSDATENATH_WGR_ZUORDNUNG}
              element={
                access.showEntryRechnungsdatenAth ? (
                  <WGRZuordnung />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_RECHNUNGSDATENATH_POSITIONEN}
              element={
                access.showEntryRechnungsdatenAth ? (
                  <RechnungsdatenPositionen />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_RECHNUNGSDATENATH_REGION_ZUORDNEN}
              element={
                access.showEntryRechnungsdatenAth ? (
                  <RegionenZuordnen />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_RECHNUNGSDATEN_SUCHE}
              element={
                access.showEntrySuchePositionen ? (
                  <RechnungsdatenSuchePositionen />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
            <Route
              path={HREF_RECHNUNGSDATEN_GLNS_OHNE_ZUORDNUNG}
              element={
                access.showEntryGlnOhneZuordnung ? (
                  <GLNOhneZuordnung />
                ) : (
                  <Navigate to={HREF_HOME} />
                )
              }
            />
          </Routes>
        </Card>
      )}
    </>
  );
};

export default AppContent;
