import { EpcomResponse } from "App/service/http.service";
import Access from "../interface/Access";

const AccessDTO = {
  convertAccessResponse: (response: EpcomResponse): Access => {
    if (response.data) {
      return {
        ...response.data,
      };
    }
    return {} as Access;
  },
};
export default AccessDTO;
