import LogfilefehlerService from "service/logfile-service/Logfilefehler.service";
import Logfilefehler from "service/logfile-service/interface/Logfilefehler";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_AUTOMAT_LOGFILEFEHLER = "FETCH_AUTOMAT_LOGFILEFEHLER";
export const LOGFILEFEHLER_ERLEDIGT_CHANGE = "LOGFILEFEHLER_ERLEDIGT_CHANGE";

const configureLogfilefehlerStore = () => {
  const fetchClearingfehler: AutomatAction = {
    identifier: FETCH_AUTOMAT_LOGFILEFEHLER,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      LogfilefehlerService.loadByAutomatSerienNr(
        `${currentState.automatenblattInformation.seriennummer}`,
        (data: Array<Logfilefehler>) =>
          callback({
            logfilefehlerRecords: data,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  const onErledigtChange: AutomatAction = {
    identifier: LOGFILEFEHLER_ERLEDIGT_CHANGE,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      const record: Logfilefehler = payload.logfilefehler;
      const indexOfRecord = currentState.logfilefehlerRecords!.findIndex(
        (e) => e.fehlerKey === record.fehlerKey
      );

      const updatedRecords = [...currentState.logfilefehlerRecords!];
      updatedRecords[indexOfRecord] = record;

      callback({
        logfilefehlerRecords: updatedRecords,
      } as AutomatDataRecord);
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([
    fetchClearingfehler,
    onErledigtChange,
  ]);
};

export default configureLogfilefehlerStore;
