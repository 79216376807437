import HttpService from "App/service/http.service";
import Config from "Config";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import IpWhitelistDTO from "./dto/IpWhitelistDTO";
import IpWhitelist from "./interface/IpWhitelist";

const IpWhitelistService = {
  load: (handler: ServiceResponseHandler) => {
    HttpService.api
      .sendGet(`${Config.logfileServiceUrl}whitelist/load`)
      .then((response) => {
        handler.callbackOnSuccess(
          IpWhitelistDTO.convertListWhitelistResponse(response)
        );
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error);
      });
  },
  save: (
    whitelist: IpWhitelist,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendJsonDataPost(`${Config.logfileServiceUrl}whitelist/save`, whitelist)
      .then((response) => {
        callbackOnSuccess(IpWhitelistDTO.convertWhitelistResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  delete: (
    id: number,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendDelete(`${Config.logfileServiceUrl}whitelist/delete/${id}`)
      .then((response) => {
        callbackOnSuccess(IpWhitelistDTO.convertWhitelistResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default IpWhitelistService;
