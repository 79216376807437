import {
  Button,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useState } from "react";
import "../../ui.scss";
import "./fileSelector.scss";
interface FileSelectorProps {
  getFile: (file: File | null) => void;
  TypographyProps?: TypographyProps;
  TextFieldProps?: TextFieldProps;
}
const FileSelector = (props: FileSelectorProps) => {
  const [selectedFileName, setSelectedFileName] = useState("");

  return (
    <Stack className="epcom_FileSelect" columnGap={2}>
      <Typography {...props.TypographyProps}>Dateiname:</Typography>
      <TextField
        value={selectedFileName}
        size="small"
        InputProps={{ readOnly: true }}
        {...props.TextFieldProps}
      />

      <Button
        className="fileSelectButton"
        variant="secondary"
        size="small"
        component="label"
      >
        Datei auswählen...
        <input
          style={{ display: "none" }}
          id="note-file-selector"
          type="file"
          accept=".pdf"
          onChange={(event) => {
            setSelectedFileName(event.target.files?.item(0)?.name ?? "");
            props.getFile(event.target.files?.item(0) ?? null);
          }}
          hidden
        />
      </Button>
    </Stack>
  );
};

export default FileSelector;
