import {
  AUTOMAT_STATUS_GUELTIG_VON_IN_ZUKUNFT_PATH,
  AUTOMAT_STATUS_IN_ZUKUNFT_PATH,
  SAP_BON_NUMMER_GUELTIG_PATH,
  SAP_BON_NUMMER_PATH,
} from "global/hook/datastore/automat/change-tracker-store";
import AutomatChangeInfo from "global/hook/datastore/interface/AutomatChangeInfo";
import AutomatDataRecord from "global/hook/datastore/interface/AutomatDataRecord";
import { DialogController } from "global/hook/modal/interface/ModalController";
import DateUtils from "global/util/DateUtils";
import { noop, returnUndefinedIfOnlyWhitespace } from "global/util/utils";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import { generateDefaultUpdateValues } from "../../../script/clearUpdateValues";

function checkValuePair(valueOne: any | undefined, valueTwo: any | undefined) {
  return (
    (valueOne === undefined && valueTwo === undefined) ||
    (valueOne !== undefined && valueTwo !== undefined)
  );
}

function getChangeFor(
  path: string,
  automatData: AutomatDataRecord
): AutomatChangeInfo | undefined {
  const changes = automatData.changes ?? [];
  const index = changes?.findIndex((ch) => ch.path === path);
  if (index !== -1) {
    return changes![index];
  }
  return undefined;
}

function rnsBonNumberEnteredIsValid(automatData: AutomatDataRecord) {
  const nrChange = getChangeFor(SAP_BON_NUMMER_PATH, automatData);
  const vonChange = getChangeFor(SAP_BON_NUMMER_GUELTIG_PATH, automatData);
  if (!nrChange && !vonChange) return true;

  return checkValuePair(
    returnUndefinedIfOnlyWhitespace(
      nrChange
        ? nrChange.value
        : automatData.automatenblattInformation.sapBonNummerDTO.bonNummer ?? ""
    ),
    returnUndefinedIfOnlyWhitespace(
      vonChange
        ? vonChange.value
        : DateUtils.arrayToDateString(
            automatData.automatenblattInformation.sapBonNummerDTO.gueltigVon
          )
    )
  );
}

function switchNumberEnteredIsValid(automatData: AutomatDataRecord) {
  const nrChange = getChangeFor("tkDaten.switchNr", automatData);
  const vonChange = getChangeFor("tkDaten.switchNrVon", automatData);
  if (!nrChange && !vonChange) return true;

  return checkValuePair(
    returnUndefinedIfOnlyWhitespace(
      nrChange?.value ?? automatData.automatenblattInformation.tkDaten.switchNr
    ),
    returnUndefinedIfOnlyWhitespace(
      vonChange?.value ??
        automatData.automatenblattInformation.tkDaten.switchNrVon
    )
  );
}

function portNumberEnteredIsValid(automatData: AutomatDataRecord) {
  const nrChange = getChangeFor("tkDaten.portNr", automatData);
  const vonChange = getChangeFor("tkDaten.portNrVon", automatData);
  if (!nrChange && !vonChange) return true;

  return checkValuePair(
    returnUndefinedIfOnlyWhitespace(
      nrChange?.value ?? automatData.automatenblattInformation.tkDaten.portNr
    ),
    returnUndefinedIfOnlyWhitespace(
      vonChange?.value ??
        automatData.automatenblattInformation.tkDaten.portNrVon
    )
  );
}

export function validateUserEnteredValues(
  automatData: AutomatDataRecord,
  modals: DialogController
) {
  if (!rnsBonNumberEnteredIsValid(automatData)) {
    modals.showDialog({
      message:
        "Das Datum fehlt oder liegt nicht in Zukunft oder die SAP Bon Nr. ist nicht ausgefüllt bzw. nicht zwischen 5 und 7 Zeichen lang.",
      title: "SAP Bon Nr.",
      onOkClick: noop,
    });
    return false;
  }

  if (!switchNumberEnteredIsValid(automatData)) {
    modals.showDialog({
      message:
        "Das Datum fehlt oder liegt nicht in Zukunft oder die Switch Nr. ist nicht ausgefüllt.",
      title: "Switch Nr.",
      onOkClick: noop,
    });
    return false;
  }

  if (!portNumberEnteredIsValid(automatData)) {
    modals.showDialog({
      message:
        "Das Datum fehlt oder liegt nicht in Zukunft oder die Port Nr. ist nicht ausgefüllt.",
      title: "Port Nr.",
      onOkClick: noop,
    });
    return false;
  }

  return true;
}

function ensureAutomatStatusInZukunftPair(
  changes: AutomatChangeInfo[],
  automatData: AutomatDataRecord
) {
  let updates: AutomatenUpdateRequest = {} as AutomatenUpdateRequest;
  const hasStatusInZukunftChange = changes.find(
    (e) => e.path === AUTOMAT_STATUS_IN_ZUKUNFT_PATH
  );
  const hasStatusInZukunftDateChange = changes.find(
    (e) => e.path === AUTOMAT_STATUS_GUELTIG_VON_IN_ZUKUNFT_PATH
  );

  if (hasStatusInZukunftChange && !hasStatusInZukunftDateChange) {
    updates.automatStatusGueltigVonInZukunft =
      DateUtils.formatDateToAPIDateString(
        automatData.automatenblattInformation.automatStatusGueltigVonInZukunft
      ) ?? "";
  }

  if (hasStatusInZukunftDateChange && !hasStatusInZukunftChange) {
    updates.automatStatusInZukunft =
      automatData.automatenblattInformation.automatStatusInZukunft ?? "";
  }
  return updates;
}

export function wrapUpdateValuesForRequest(automatData: AutomatDataRecord) {
  let updates: AutomatenUpdateRequest = generateDefaultUpdateValues(
    automatData.automatenblattInformation
  );

  const changes = automatData.changes ?? [];
  if (changes.length === 0) {
    return updates;
  }

  for (const c of changes) {
    updates = c.merger(updates, c, true) as AutomatenUpdateRequest;
  }

  if (updates.rnsBonNummer) {
    updates.rnsBonNummerGueltigVon =
      updates.rnsBonNummerGueltigVon ??
      DateUtils.formatDateToAPIDateString(
        DateUtils.arrayToDate(
          automatData.automatenblattInformation.sapBonNummerDTO.gueltigVon
        )
      ) ??
      "";
  } else if (
    updates.rnsBonNummer === "" &&
    updates.rnsBonNummerGueltigVon === ""
  ) {
    delete updates.rnsBonNummerGueltigVon;
  }

  updates = {
    ...updates,
    ...ensureAutomatStatusInZukunftPair(changes, automatData),
  };

  return updates;
}
