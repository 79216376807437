import { ColDef } from "ag-grid-community";

const DsmdColumnDefs: Array<ColDef> = [
  {
    headerName: "Region",
    field: "region",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    headerName: "Seriennummer",
    field: "serienNr",
    minWidth: 140,
  },
  {
    headerName: "RNS",
    field: "rnsName",
    minWidth: 240,
  },
  {
    headerName: "Ort",
    field: "rnsOrt",
    minWidth: 230,
  },
  {
    headerName: "RNS-GLN",
    field: "rnsGln",
    minWidth: 160,
  },
  {
    headerName: "RN",
    field: "rnName",
    minWidth: 400,
  },
  {
    headerName: "RN-GLN",
    field: "rnGln",
    minWidth: 160,
  },
  {
    headerName: "Hersteller",
    field: "herstellerKuerzel",
    minWidth: 110,
  },
  {
    headerName: "Letzter erfolgr.Zugriff",
    field: "dsmdLastSuccess",
    minWidth: 200,
  },
  {
    headerName: "Notiz vorh.seit",
    field: "notizVerbingungsStoerungDsmdDatum",
    minWidth: 180,
  },
  {
    headerName: "Notiz",
    field: "notizVerbingungsStoerungDsmd",
    minWidth: 180,
  },
];

export default DsmdColumnDefs;
