import { Stack, Typography, TypographyProps } from "@mui/material";
import React, { ReactNode } from "react";
import "../../ui.scss";

interface SwitchProps {
  label: string;
  value: boolean;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  TypographyProps?: TypographyProps;
  disabled?: boolean;
}

const UICheckbox: React.FC<SwitchProps> = (props: SwitchProps) => {
  return (
    <Stack className="epcom__CheckBox" columnGap={1}>
      {props.label && (
        <Typography id={`${props.id}-label`} {...props.TypographyProps}>
          {props.label}
        </Typography>
      )}
      <input
        type="checkbox"
        id={`${props.id}-checkbox`}
        checked={props.value}
        className="uiCheckbox"
        onChange={props.onChange}
        disabled={props.disabled}
      />
      {props.children}
    </Stack>
  );
};
export default UICheckbox;
