import { EpcomResponse } from "App/service/http.service";
import EpcomUserGroupAutomat, {
  EpcomGroup,
} from "../interface/EpcomUserGroupAutomat";

const UserDTO = {
  convertUserResponseDTO: (response: EpcomResponse): EpcomUserGroupAutomat => {
    if (response.data) {
      return {
        ...response.data,
      };
    }
    return {} as EpcomUserGroupAutomat;
  },
  convertGroupResponseDTO: (response: EpcomResponse): Array<EpcomGroup> => {
    if (response.data) {
      return [...response.data.Row];
    }
    return [];
  },
};
export default UserDTO;
