import HistroryService from "service/data-service/history-controller/History.service";
import EditHistory from "service/data-service/history-controller/interface/EditHistory";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_AUTOMAT_HISTORY = "FETCH_AUTOMAT_HISTORY";

const configureAutomatHistoryStore = () => {
  const fetchInfo: AutomatAction = {
    identifier: FETCH_AUTOMAT_HISTORY,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      HistroryService.loadByAutomatkey(
        payload.automatKey,
        (data: Array<EditHistory>) =>
          callback({
            historyRecords: data,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([fetchInfo]);
};

export default configureAutomatHistoryStore;
