import { EpcomResponse } from "App/service/http.service";
import DateUtils from "global/util/DateUtils";
import AutomatSteckbrief from "../interface/AutomatSteckbrief";

const AutomatSteckbriefDTO = {
  convertResponseToAutomatSteckbriefs: (
    response: EpcomResponse
  ): Array<AutomatSteckbrief> => {
    const data = response.data ?? [];

    return data.map((info: any) => {
      return {
        ...info,
        date: DateUtils.formatDate(new Date()),
        zurAuslatung: {
          ...info.zurAuslatung,
          bonSumme: (info.zurAuslatung.bonSumme ?? []).map((item: any) => {
            return {
              key: item.type,
              value: item.value,
            };
          }, []),
          anteile: (info.zurAuslatung.anteile ?? []).map((item: any) => {
            return {
              key: item.type,
              value: item.value,
            };
          }, []),
        },
        technicheDaten: {
          ...info.technicheDaten,
          betriebSeit: DateUtils.arrayToDateString(
            info.technicheDaten.betriebSeit
          ),
        },
        bemerkungen: info.bemerkungen.map((item: any) => {
          return {
            key: DateUtils.arrayToDateString(item.date),
            value: item.message,
          };
        }, []),
      };
    });
  },
};
export default AutomatSteckbriefDTO;
