import HttpService from "App/service/http.service";
import Config from "Config";
import LogfileDTO from "./dto/LogfileDTO";

const LogfileService = {
  loadByFilters: (
    automatSerienNr: string,
    datumVon: string, // format dd.MM.YYYY
    datumBis: string, // format dd.MM.YYYY
    kategorien: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    HttpService.api
      .sendGet(`${Config.logfileServiceUrl}logfile/load`, {
        params: {
          automatSerienNr: automatSerienNr,
          datumVon: datumVon,
          datumBis: datumBis,
          kategorien: kategorien,
        },
      })
      .then((response) => {
        callbackOnSuccess(LogfileDTO.convertLogfileResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default LogfileService;
