import { format } from "date-fns";
import deLocale from "date-fns/locale/de";

export const epcomDateTimeFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export const epcomDisplayDateFormat = "dd.MM.yyyy";
export const epcomAPIDateFormat = "yyyy-MM-dd";

const DateUtils = {
  arrayToDate(list: Array<number>, includesTime = false): Date | undefined {
    if (list === null || list.length < 3) {
      return undefined;
    }
    let year: number = list[0];
    let month: number = list[1] - 1; // month between 0 - 11
    let day: number = list[2];

    if (!includesTime || list.length < 5) return new Date(year, month, day);

    return new Date(
      year,
      month,
      day,
      list[3],
      list[4],
      list.length > 5 ? list[5] : 0
    );
  },

  dateToArray(
    date: Date | undefined | null,
    includesTime = false
  ): Array<number> | undefined {
    if (!date) {
      return undefined;
    }
    return includesTime
      ? [
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
        ]
      : [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  },

  arrayToDateString(list: Array<number>): string | undefined {
    return this.formatDate(this.arrayToDate(list));
  },

  /**
   *
   * @param list Array of numbers that represent a Date
   * @param dateFormat format for the date string, defaults to "dd.MM.yyyy"
   * @returns A string version of the given date using the given dateFormat
   * @example "[2022,5,12] converts to 12.05.2022"
   */
  arrayToString(
    list: Array<number> | undefined | null,
    dateFormat?: string
  ): string {
    return this.dateToString(this.arrayToDate(list ?? []), dateFormat);
  },

  /**
   *
   * @param date a Date object
   * @param dateFormat format for the date string, defaults to "dd.MM.yyyy"
   * @returns A string version of the given date using the given dateFormat
   * @example "[2022,5,12] converts to 12.05.2022"
   */
  dateToString(date: Date | undefined | null, dateFormat?: string): string {
    if (date) return format(date, dateFormat ?? epcomDisplayDateFormat);
    return "";
  },

  /**
   *
   * @param list Array of numbers that represent a Date
   * @returns A string version of the given date using the epcom display date format
   * @example "[2022,5,12] converts to 12.05.2022"
   */
  arrayToDisplayString(list: Array<number> | undefined | null): string {
    return this.dateToDisplayString(this.arrayToDate(list ?? []));
  },

  /**
   *
   * @param date a Date object
   * @returns A string version of the given date using the epcom display date format
   * @example "[2022,5,12] converts to 12.05.2022"
   */
  dateToDisplayString(date: Date | undefined | null): string {
    return this.dateToString(date, epcomDisplayDateFormat);
  },

  /**
   *
   * @param list Array of numbers that represent a Date
   * @returns A string version of the given date using the epcom API date format
   * @example "[2022,5,12] converts to 2022-05-12"
   */
  arrayToAPIString(list: Array<number> | undefined | null): string {
    return this.dateToAPIString(this.arrayToDate(list ?? []));
  },

  /**
   * @param date a Date object
   * @returns A string version of the given date using the epcom API date format
   * @example "[2022,5,12] converts to 2022-05-12"
   */
  dateToAPIString(date: Date | undefined | null): string {
    return this.dateToString(date, epcomAPIDateFormat);
  },

  /**
   *
   * @param apiDate a string in the API date format
   * @returns A string version of the given date using the epcom display date format
   * @example "2022-05-12" converts to 12.05.2022
   */
  convertApiDateToDisplayDate(apiDate: string | undefined): string {
    return this.dateToDisplayString(this.parseIsoDateString(apiDate));
  },

  formatDate(
    date: Date | undefined,
    format?: Intl.DateTimeFormatOptions,
    locales?: Locale
  ): string | undefined {
    if (!date) return undefined;
    return date.toLocaleDateString(
      locales ? locales?.code : deLocale.code,
      format
        ? format
        : {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
    );
  },

  formatDateToAPIDateString(
    date: Date | null | undefined,
    dateFormat?: string
  ): string | null {
    return date ? format(date, dateFormat ?? "yyyy-MM-dd") : null;
  },

  /**
   *
   * @param toFormat
   * @returns String Date like "dd.mm.yyyy"
   * @example "12.05.2022"
   */
  optionalTimeArrayToGermanString(toFormat: Array<number> | undefined): string {
    let result: string = "";
    if (toFormat && typeof toFormat === "object" && toFormat.length > 2) {
      let day = String(toFormat[2]).padStart(2, "0");
      let month = String(toFormat[1]).padStart(2, "0");
      if (toFormat.length < 5) {
        result = `${day}.${month}.${toFormat[0]}`;
      } else {
        let hours = String(toFormat[3]).padStart(2, "0");
        let mins = String(toFormat[4]).padStart(2, "0");
        let secs =
          toFormat.length > 5 ? String(toFormat[5]).padStart(2, "0") : "00";

        result = `${day}.${month}.${toFormat[0]} ${hours}:${mins}:${secs}`;
      }
    }
    return result;
  },

  optionalDateTimeStringToGermanString(toFormat: string | undefined): string {
    return (
      this.formatDate(
        this.parseIsoDateString(toFormat) as Date,
        epcomDateTimeFormat
      ) ?? ""
    );
  },

  /**
   *
   * @param toFormat Array to format
   * @returns String in Iso-Time yyyy-MM-dd
   */
  optionalTimeArrayToISOString(toFormat: Array<number> | undefined): string {
    let result: string = "";
    if (toFormat && typeof toFormat === "object" && toFormat.length === 3) {
      let day = String(toFormat[2]).padStart(2, "0");
      let month = String(toFormat[1]).padStart(2, "0");

      result = `${toFormat[0]}-${month}-${day}`;
    }
    return result;
  },

  oldestDateString(
    dateString1: string | undefined | null,
    dateString2: string | undefined | null
  ): string | null {
    const date1 = this.parseIsoDateString(dateString1);
    const date2 = this.parseIsoDateString(dateString2);
    if (date1 == null && date2 == null) {
      return null;
    }
    if (date1 == null) {
      return dateString2!;
    }
    if (date2 == null) {
      return dateString1!;
    }

    if (date1.getMilliseconds() > date2.getMilliseconds()) {
      return dateString2!;
    }
    return dateString1!;
  },

  apiStringToNumberArray(stringToParse: string): Array<number> {
    return stringToParse.split("-").map((value) => {
      return parseInt(value);
    });
  },
  parseIsoDateString(dateString: string | undefined | null): Date | null {
    var patternForDateOnly = /(\d{2})\.(\d{2})\.(\d{4})/;
    var patternForDateTime =
      /(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2})(\.(\d+))?)?/;

    if (patternForDateTime.test(dateString ?? "")) {
      var dateArray = patternForDateTime.exec(dateString as string)!;
      if (dateArray[4]) {
        return new Date(
          +dateArray[1],
          +dateArray[2] - 1, // month starts at 0!
          +dateArray[3],
          +dateArray[5],
          +dateArray[6],
          +dateArray[7]
        );
      } else {
        return new Date(
          +dateArray[1],
          +dateArray[2] - 1, // month starts at 0!
          +dateArray[3]
        );
      }
    } else if (patternForDateOnly.test(dateString ?? "")) {
      var dateArray = patternForDateOnly.exec(dateString as string)!; // eslint-disable-line
      return new Date(
        +dateArray[3],
        +dateArray[2] - 1, // month starts at 0!
        +dateArray[1]
      );
    }
    return null;
  },
  isDateOlderThanDays(datum: Date | null, olderThanDays: number): boolean {
    if (datum === null) {
      return false;
    }
    let d = new Date();
    d.setDate(d.getDate() - olderThanDays);
    return d > datum;
  },
  dateForDisplay(aDate: Date | string | undefined | null): Date | undefined {
    if (!aDate) return undefined;

    if (typeof aDate === "string") {
      return DateUtils.arrayToDate(DateUtils.apiStringToNumberArray(aDate));
    }
    return aDate as Date;
  },
};

export function getTomorrow(): Date {
  return getDateWithShift(1);
}

export function getDateWithShift(days: number): Date {
  let shiftedDate = new Date();
  shiftedDate.setDate(shiftedDate.getDate() + days);
  return shiftedDate;
}

export default DateUtils;
