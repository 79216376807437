import { TableRowProps } from "../../TableContainer/TableRow";

export const MaterialDistributionTableHeader: Array<TableRowProps> = [
  {
    row: [
      { value: "PET", props: { align: "center" } },
      { value: "Metall", props: { align: "center" } },
      { value: "Glas", props: { align: "center" } },
      { value: "Sonstige", props: { align: "center" } },
    ],
  },
];
