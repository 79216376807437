import { Grid, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import SapInputParam from "service/sap-service/interface/SapInputParam";
import SapOutputParam from "service/sap-service/interface/SapOutputParam";
import PositionService from "service/sap-service/Position.service";
import GLNListController from "./GLNListDialogController";
import RegionSelectionColumnDefs from "./grid-column-defs";

export interface GLNListDialogContentProps {
  zipcode: string;
  onGLNSelected: Function;
}

const GLNListDialogContent = (props: GLNListDialogContentProps) => {
  const gridDataFetchCall = (handler: ServiceResponseHandler) => {
    PositionService.getRnsDataEpcomByPlz(
      {
        rnsPlz: props.zipcode,
      } as SapInputParam,
      (response: SapOutputParam) => {
        handler.callbackOnSuccess(
          GLNListController.convertPositionsData(
            response.positionEdits,
            props.onGLNSelected
          )
        );
      },
      handler.callbackOnFail
    );
  };
  return (
    <>
      <Grid container spacing={5} width={1200}>
        <Grid item xs={12}>
          <DataGrid
            height={300}
            rowHeight={44}
            columnDefs={RegionSelectionColumnDefs}
            gridActions={<Typography variant={"h1"}>PLZ Suche</Typography>}
            rowsDataDef={{
              uncontrolledDataFetchCall: gridDataFetchCall,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GLNListDialogContent;
